import React, { PureComponent } from 'react'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import MUIDataTable from 'mui-datatables'
import { IconButton, Paper, Grid } from '@material-ui/core'
import { LinearProgress, Typography, Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    h2: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    }
})
const clientsPath = { pathname: `${ApplicationPaths.Clients}` }
const usersPath = { pathname: `${ApplicationPaths.Users}` }

class UserClients extends PureComponent {
    static displayName = UserClients.name

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            clients: [],
            selectedClients: [],
            deleteArrayIndexes: [],
            userId: props.match.params.userId
                ? props.match.params.userId
                : null,
            user: null,
            dialogOpen: false,
            notificationAlert: {
                open: false,
                message: 'test',
                severity: 'success'
            },
            theme: props.theme,
            canImportClients: false
        }
        this.showDialog = this.showDialog.bind(this)
        this.populateClientsData = this.populateClientsData.bind(this)
    }

    async componentDidMount() {
        this.populateClientsData()
    }

    populateDataToDisplay(dataset) {
        if (dataset) {
            var ClientArray = []
            for (let i = 0; i < dataset.length; i++) {
                let clientArray = []
                var id = dataset[i].id
                var customerId = dataset[i].customerId
                var contactName =
                    dataset[i].contactFirstName +
                    ' ' +
                    dataset[i].contactLastName
                var contactEmail = dataset[i].contactEmail
                var contactPhoneNumber = dataset[i].contactPhoneNumber
                var clientName = dataset[i].name
                var lastCrawlerResult =
                    dataset[i].lastCrawlerResult !== null
                        ? new Date(
                              dataset[i].lastCrawlerResult.substring(0, 24)
                          ).toLocaleString() +
                          dataset[i].lastCrawlerResult.substring(24)
                        : '-'
                var clientView = dataset[i].clientView
                var clientUserCount = dataset[i].clientUserCount

                clientArray.push(id)
                clientArray.push('')
                clientArray.push(customerId)
                clientArray.push(clientName)
                clientArray.push(clientUserCount)
                clientArray.push(lastCrawlerResult)
                clientArray.push(clientView)
                clientArray.push(contactName)
                clientArray.push(contactEmail)
                clientArray.push(contactPhoneNumber)

                ClientArray.push(clientArray)
            }
            return ClientArray
        }
    }

    showDialog() {
        this.setState({ dialogOpen: true })
    }

    hideRowNotify() {
        this.setState(prevState => {
            let client = Object.assign({}, prevState.client)
            client.notifyError = false
            return { client }
        })
    }

    render() {
        const { isLoading, clients, user } = this.state
        const { classes } = this.props

        const data = this.populateDataToDisplay(clients)
        const options = {
            filter: false,
            viewColumns: false,
            print: false,
            download: false,
            selectableRowsOnClick: true,
            selectableRows: 'multiple',
            caseSensitive: false,
            rowsPerPageOptions: [10, 15, 25, 50, 100],
            filterType: 'dropdown',
            responsive: 'stacked',
            rowsPerPage: 25,
            searchOpen: false,
            customToolbarSelect: selectedRows => {
                return (
                    <IconButton
                        size="medium"
                        color="primary"
                        onClick={() => {
                            this.setState(
                                {
                                    selectedClients: selectedRows.data.map(
                                        row => clients[row.dataIndex]
                                    )
                                },
                                () => {
                                    this.showDialog()
                                }
                            )
                        }}
                    >
                        <RemoveCircleIcon /> Remove Client User Link(s)
                    </IconButton>
                )
            }
        }

        const headCells = [
            {
                id: 'id',
                label: 'Id',
                options: { filter: false, sort: false, display: 'none' }
            },
            {
                id: 'manageUser',
                label: '',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var clientId = tableMeta.rowData[0]
                        var userName = user.firstName + ' ' + user.lastName
                        var userId = user.id
                        return (
                            <NavLink
                                className={classes.link}
                                to={
                                    usersPath.pathname +
                                    '/' +
                                    clientId +
                                    '/' +
                                    userId
                                }
                            >
                                <Button color="primary" variant="outlined">
                                    Manage Client User
                                </Button>
                            </NavLink>
                        )
                    }
                }
            },
            {
                id: 'customerId',
                label: 'Client Id',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var id = tableMeta.rowData[0]
                        var customerId = tableMeta.rowData[2]
                        var path = clientsPath.pathname + '/' + id
                        return (
                            <NavLink className={classes.link} to={path}>
                                {customerId}
                            </NavLink>
                        )
                    }
                }
            },
            { id: 'name', label: 'Client Name' },
            {
                id: 'clientUserCount',
                label: 'Users',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var clientId = tableMeta.rowData[0]
                        var userCount = tableMeta.rowData[4]
                        return (
                            <NavLink
                                className={classes.link}
                                to={usersPath.pathname + '/' + clientId}
                            >
                                {userCount}
                            </NavLink>
                        )
                    }
                }
            }
        ]

        return this.state.isLoading ? (
            <LinearProgress color="primary" />
        ) : (
            <>
                {this.state.notificationAlert.open ? (
                    <Alert
                        className="mb-3"
                        variant="filled"
                        severity={this.state.notificationAlert.severity}
                        onClose={() => {
                            this.setState({
                                notificationAlert: {
                                    open: false,
                                    message: '',
                                    severity: 'info'
                                }
                            })
                        }}
                        open={this.state.notificationAlert.open}
                    >
                        {this.state.notificationAlert.message}
                    </Alert>
                ) : null}
                <Grid container>
                    <Grid item xs={12}>
                        <Paper elevation={0}>
                            <MUIDataTable
                                title={
                                    <Typography
                                        variant="h2"
                                        className="p-0 m-0"
                                    >
                                        {' '}
                                        {user.firstName + ' ' + user.lastName}
                                    </Typography>
                                }
                                columns={headCells}
                                options={options}
                                data={data}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                >
                    <DialogTitle>Remove Client User Links?</DialogTitle>
                    <DialogContent>
                        {this.state.selectedClients.map(client => (
                            <div>
                                <label>{client.name}</label>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            startIcon={<CancelIcon />}
                            onClick={() => {
                                this.setState({ dialogOpen: false })
                            }}
                        >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            color="primary"
                            style={{ padding: '10px' }}
                            startIcon={<AddCircleIcon />}
                            onClick={async () => {
                                await this.removeClientUserLinks()
                                this.setState({ dialogOpen: false })
                            }}
                        >
                            Remove Client User Links
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    async populateClientsData() {
        const token = await authService.getAccessToken()
        const clientsResponse = await fetch(
            'api/clients?userId=' + this.state.userId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (clientsResponse.ok) {
            const data = await clientsResponse.json()
            this.setState({ clients: data })
        }

        const userResponse = await fetch('api/users/' + this.state.userId, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (userResponse.ok) {
            const data = await userResponse.json()
            this.setState({ user: data })
        }

        this.setState({ isLoading: false })
    }

    async removeClientUserLinks() {
        if (this.state.selectedClients.length === this.state.clients.length) {
            if (
                !window.confirm(
                    'Are you sure you want to remove all client user links?' +
                        '\n' +
                        'This will also remove the client user.'
                )
            ) {
                return
            }
        }

        this.setState({ isLoading: true })

        const token = await authService.getAccessToken()
        const options = {
            method: 'DELETE',
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
            'Content-Type': 'application/json'
        }

        this.state.selectedClients.forEach(client => {
            fetch(
                'api/users/DeleteUser/' + client.id + '/' + this.state.userId,
                options
            )
                .then(res => {
                    if (res.ok) {
                        this.populateClientsData()
                        this.setState({
                            notificationAlert: {
                                open: true,
                                message:
                                    'Client User Link Successfully Removed.',
                                severity: 'success'
                            },
                            isLoading: false
                        })
                        return Promise.resolve()
                    } else {
                        this.setState({
                            notificationAlert: {
                                open: true,
                                message:
                                    'Failed To Remove Client User Links - Please Contact Support.',
                                severity: 'error'
                            },
                            isLoading: false
                        })

                        return Promise.reject(
                            'An error occurred (' + res.status + ').'
                        )
                    }
                })
                .catch(err => {
                    this.setState({
                        notificationAlert: {
                            open: true,
                            message:
                                'Failed To Remove Client User Links - Please Contact Support.',
                            severity: 'error'
                        },
                        isLoading: false
                    })
                    console.log(err)
                })
        })
    }
}

export default withStyles(styles)(UserClients)
