import React, { PureComponent, forwardRef } from 'react'
import authService from './api-authorization/AuthorizeService'
import { TextField, Paper, Grid } from '@material-ui/core'
import { LinearProgress, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { Button, IconButton } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import CancelIcon from '@material-ui/icons/Cancel'
import { Checkbox } from '@material-ui/core'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { ThemeProvider, createTheme } from '@mui/material'
import { alignClientUserModuleAccess } from '../../src/api/api'
import { CardContent } from '@material-ui/core'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    },
    clientSelectDialogButton: {
        width: '50px'
    }
})

const clientUserPath = { pathname: `${ApplicationPaths.Users}` }

class OrgUsers extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            users: [],
            user: null,
            selectedUsers: [],
            clients: [],
            userClients: [],
            filteredClients: [],
            notificationAlert: {
                open: false,
                message: 'test',
                severity: 'success'
            },
            organization: null,
            dialogOpen: false,
            theme: props.theme,
            canImportUsers: false,
            isSuperAdmin: false
        }
        this.showDeleteDialog = this.showDeleteDialog.bind(this)
        this.sendEmailConfirmEmail = this.sendEmailConfirmEmail.bind(this)
        this.populateOrgUsersData = this.populateOrgUsersData.bind(this)
    }

    async componentDidMount() {
        this.populateOrgUsersData()
        this.populateConfig()
        const isSuperAdmin = await authService.isSuperAdmin()
        this.setState({ isSuperAdmin: isSuperAdmin })
    }

    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({
            organization: data
        })
    }

    dataToDisplay(dataset) {
        if (dataset) {
            dataset = dataset.map(function (user) {
                var lastLoginDateString = user.lastLoginAsString
                var lastLogin =
                    lastLoginDateString === ''
                        ? 'n/a'
                        : new Date(lastLoginDateString).toLocaleString()
                var lastActivityDateString = user.lastActivityAsString
                var lastActivity =
                    lastActivityDateString === ''
                        ? 'n/a'
                        : new Date(lastActivityDateString).toLocaleString()
                return {
                    ...user,
                    emailConfirmed: user.emailConfirmed ? 'Yes' : 'No',
                    lastLogin: lastLogin,
                    lastActivity: lastActivity
                }
            })
            return dataset
        }
    }

    showDeleteDialog(_user) {
        this.setState({ dialogOpen: true })
    }

    async sendEmailConfirmEmail(userid) {
        this.setState({ isLoading: true })

        const token = await authService.getAccessToken()
        const options = {
            method: 'POST',
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
        fetch('api/users/SendUserEmailConfirmation/' + userid, options)
            .then(res => {
                this.setState({ isLoading: false })
                if (res.ok) {
                    this.setState({
                        notificationAlert: {
                            open: true,
                            message: 'Email Confirmation Message Sent.',
                            severity: 'success'
                        }
                    })
                } else {
                }
            })
            .catch(err => {
                this.setState({
                    notificationAlert: {
                        open: true,
                        message: 'Email Confirmation Message Failed To Send.',
                        severity: 'error'
                    }
                })
                console.log(err)
            })
    }

    async saveClientUserDetails() {
        const { selectedUsers } = this.state
        const selectedClients = this.state.clients.filter(
            client => client.checked
        )
        let newClientCount = selectedClients.length

        if (selectedUsers.length === 1) {
            const userClientIds = selectedUsers[0].clientIds
            newClientCount = selectedClients.length - userClientIds.length
        }

        if (
            !window.confirm(
                'Are you sure you want to add ' +
                    selectedUsers.length +
                    ' user(s) to ' +
                    newClientCount +
                    ' client(s)?'
            )
        ) {
            return
        }

        this.setState({ isLoading: true })

        const token = await authService.getAccessToken()
        const data = []

        selectedClients.forEach(client => {
            selectedUsers.forEach(user => {
                data.push({
                    clientId: client.id,
                    userId: user.id
                })
            })
        })

        fetch('api/users/AddClientUsers', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, *',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        notificationAlert: {
                            open: true,
                            message: 'User(s) Successfully Added To Client(s).',
                            severity: 'success'
                        },
                        isLoading: false
                    })
                    this.populateOrgUsersData()
                } else {
                    this.setState({
                        notificationAlert: {
                            open: true,
                            message:
                                'An Error Occurred Adding User(s) To Client(s) - Please Contact Support.',
                            severity: 'error'
                        },
                        isLoading: false
                    })
                    console.log(response.text())
                }
            })
            .catch(error => {
                this.setState({
                    notificationAlert: {
                        open: true,
                        message:
                            'An Error Occurred Adding User(s) To Client(s) - Please Contact Support.',
                        severity: 'error'
                    },
                    isLoading: false
                })
                console.log(error)
            })
    }

    async populateUserClientsData() {
        this.setState({ isLoading: true })

        const token = await authService.getAccessToken()
        const clientsResponse = await fetch(
            'api/clients?userId=' + this.state.userId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (clientsResponse.ok) {
            const data = await clientsResponse.json()
            this.setState({ userClients: data })
        }

        const userResponse = await fetch('api/users/' + this.state.userId, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (userResponse.ok) {
            const data = await userResponse.json()
            this.setState({ user: data })
        }

        this.setState({ isLoading: false })
    }

    handleClientSelectChange = event => {
        const id = event.target.id
        const checked = event.target.checked
        this.setState(prevState => {
            let clients = [...prevState.clients]
            clients.forEach(client => {
                if (client.id === id) {
                    client.checked = checked
                }
            })
            return { clients }
        })
    }

    setClientsCheckedValue(isChecked) {
        this.setState(prevState => {
            let clients = [...prevState.clients]
            clients.forEach(client => {
                client.checked = isChecked
            })
            return { clients }
        })
    }

    setAllClientsUnchecked = () => {
        this.setClientsCheckedValue(false)
    }

    setAllClientsChecked = () => {
        this.setClientsCheckedValue(true)
    }

    downloadCSV = async rowData => {
        var selectedUserIds = rowData.map(user => user.id)

        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetClientAndDownloadCSV',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(selectedUserIds)
            }
        )

        if (response.ok) {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'data.csv'
            link.click()
        } else {
            console.error('Error downloading CSV file. Please try again later.')
        }
    }

    render() {
        const { organization, clients, selectedUsers, filteredClients } =
            this.state
        const { classes } = this.props
        const tableTheme = createTheme()
        const data = this.dataToDisplay(this.state.users)

        const backgroundColor =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#ffffff'
        const textColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'
        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'

        let headCells = [
            { field: 'id', title: 'Id', hidden: true },
            { field: 'firstName', title: 'First Name' },
            { field: 'lastName', title: 'Last Name' },
            {
                field: 'email',
                title: 'Email'
            },
            {
                field: 'clientCount',
                title: 'Clients'
            },
            {
                field: 'emailConfirmed',
                title: 'Email Confirmed'
            },
            { field: 'lastLogin', title: 'Last Login' },
            { field: 'lastActivity', title: 'Last Activity' }
        ]

        headCells = headCells.map(headCell => {
            return {
                ...headCell,
                render: params => {
                    if (headCell.field === 'clientCount') {
                        return (
                            <NavLink
                                className={classes.link}
                                to={'/userclients/' + params.id}
                            >
                                {params.clientCount}
                            </NavLink>
                        )
                    } else if (headCell.field === 'emailConfirmed') {
                        return (
                            <>
                                {params.emailConfirmed !== 'Yes' ? (
                                    <>
                                        {params.emailConfirmed}{' '}
                                        <Tooltip
                                            title={
                                                <h6>
                                                    Resend Email Confirmation
                                                    Message
                                                </h6>
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disableElevation
                                                color="secondary"
                                                onClick={() =>
                                                    this.sendEmailConfirmEmail(
                                                        params.id
                                                    )
                                                }
                                            >
                                                Resend
                                            </Button>
                                        </Tooltip>
                                    </>
                                ) : (
                                    params.emailConfirmed
                                )}
                            </>
                        )
                    } else {
                        return params[headCell.field]
                    }
                }
            }
        })

        let tableActions = [
            {
                icon: () => {
                    return (
                        <IconButton color="primary" size="medium">
                            <DeleteIcon /> Delete
                        </IconButton>
                    )
                },
                onClick: (event, rowData) => {
                    if (
                        window.confirm(
                            'Are you sure you want to delete ' +
                                rowData.length +
                                ' client user(s)? This will remove them from all clients.'
                        )
                    ) {
                        this.deleteClientUsers(rowData)
                    }
                },
                tooltip: 'Delete User(s)'
            },
            {
                icon: () => {
                    return (
                        <IconButton color="primary" size="medium">
                            <AddCircleIcon /> Add Clients
                        </IconButton>
                    )
                },
                onClick: (event, rowData) => {
                    if (rowData.length === 1) {
                        const userClientIds = rowData[0].clientIds
                        if (userClientIds.length !== 0) {
                            this.setState(prevState => {
                                let clients = [...prevState.clients]
                                clients.forEach(client => {
                                    if (userClientIds.includes(client.id)) {
                                        client.disabled = true
                                        client.checked = true
                                    } else {
                                        client.disabled = false
                                        client.checked = false
                                    }
                                })
                                return { clients }
                            })
                        }
                    }
                    this.setState(
                        {
                            selectedUsers: rowData
                        },
                        () => {
                            this.setState({ dialogOpen: true })
                        }
                    )
                },
                tooltip: 'Add Client(s) to User(s)'
            },
            {
                icon: () => {
                    return (
                        <IconButton color="primary" size="medium">
                            <ArrowUpwardIcon /> Export To CSV
                        </IconButton>
                    )
                },
                onClick: (e, rowData) => {
                    this.downloadCSV(rowData)
                },
                tooltip: 'Export Client User Data To CSV'
            }
        ]

        if (this.state.isSuperAdmin) {
            tableActions.push({
                icon: () => {
                    return (
                        <IconButton color="primary" size="medium">
                            <AutorenewIcon />
                        </IconButton>
                    )
                },
                onClick: (e, rowData) => {
                    alignClientUserModuleAccess(
                        results => {
                            this.setState({
                                notificationAlert: {
                                    open: true,
                                    message:
                                        'Module Access Aligned Successfully.',
                                    severity: 'success'
                                }
                            })
                            console.log(results)
                        },
                        () => {
                            this.setState({
                                notificationAlert: {
                                    open: true,
                                    message:
                                        'Error Aligning Module Access - Please Contact Support.',
                                    severity: 'error'
                                }
                            })
                        }
                    )
                },
                tooltip: 'Align Client User Module Access',
                isFreeAction: true
            })
        }

        return (
            <CardContent className="p-0">
                {this.state.notificationAlert.open ? (
                    <Alert
                        className="mb-3"
                        variant="filled"
                        severity={this.state.notificationAlert.severity}
                        onClose={() => {
                            this.setState({
                                notificationAlert: {
                                    open: false,
                                    message: '',
                                    severity: 'success'
                                }
                            })
                        }}
                        open={this.state.notificationAlert.open}
                    >
                        {this.state.notificationAlert.message}
                    </Alert>
                ) : null}
                <Paper className="paper page-cover-height-200px">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={0} className="w-100 px-3">
                                <div className="psa-shipment-table custom-table-styles w-100">
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mb-4 w-100"
                                    >
                                        <Grid item xs={12}>
                                            <div class="first-row-filter-hidden">
                                                <ThemeProvider
                                                    theme={tableTheme}
                                                >
                                                    <MaterialTable
                                                        title="Client Users"
                                                        columns={headCells}
                                                        data={data}
                                                        isLoading={
                                                            this.state.isLoading
                                                        }
                                                        icons={{
                                                            SortArrow:
                                                                forwardRef(
                                                                    (
                                                                        props,
                                                                        ref
                                                                    ) => (
                                                                        <ArrowDownward
                                                                            {...props}
                                                                            ref={
                                                                                ref
                                                                            }
                                                                        />
                                                                    )
                                                                )
                                                        }}
                                                        actions={tableActions}
                                                        options={{
                                                            headerStyle: {
                                                                backgroundColor:
                                                                    backgroundColor,
                                                                color: textColor
                                                            },
                                                            filtering: false,
                                                            maxBodyHeight:
                                                                'calc(100vh - 348px)',
                                                            pageSizeOptions: [
                                                                25, 50, 75
                                                            ],
                                                            sorting: true,
                                                            pageSize: 25,
                                                            showTitle: false,
                                                            search: true,
                                                            columnsButton: false,
                                                            doubleHorizontalScroll: true,
                                                            draggable: false,
                                                            showFirstLastPageButtons: true,
                                                            toolbar: true,
                                                            padding: 'dense',
                                                            selection: true,
                                                            pagination: {
                                                                rowsPerPage:
                                                                    'Rows per page:',
                                                                displayRows:
                                                                    'off'
                                                            },
                                                            exportButton: {
                                                                csv: true
                                                            },
                                                            exportFileName:
                                                                'ExportClientUsers_' +
                                                                Date.now(),
                                                            exportAllData: true
                                                        }}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                >
                    <DialogTitle>Select Client(s)</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddCircleIcon />}
                                        onClick={this.setAllClientsChecked}
                                    >
                                        Select All
                                    </Button>
                                    &nbsp;
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<RemoveCircleIcon />}
                                        onClick={this.setAllClientsUnchecked}
                                    >
                                        Unselect All
                                    </Button>
                                </>
                            }
                        </DialogContentText>
                        <div>
                            <br />
                            <TextField
                                style={{ width: '80%' }}
                                onChange={e => {
                                    // need to filter the addresses based on the input
                                    const value = e.target.value
                                    const filteredClients = clients.filter(
                                        client => {
                                            return client.name
                                                .toLowerCase()
                                                .includes(value.toLowerCase())
                                        }
                                    )
                                    this.setState({ filteredClients })
                                }}
                                placeholder={'Enter client name..'}
                            />
                            <br />
                            {filteredClients.map(client => (
                                <div
                                    style={{
                                        backgroundColor: client.disabled
                                            ? '#D4EDF9'
                                            : null
                                    }}
                                >
                                    <Checkbox
                                        key={client.id}
                                        id={client.id}
                                        value={client.id}
                                        checked={client.checked}
                                        disabled={client.disabled}
                                        onChange={this.handleClientSelectChange}
                                    />{' '}
                                    <label>{client.name}</label>
                                </div>
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            startIcon={<CancelIcon />}
                            onClick={() => {
                                this.setState({ dialogOpen: false })
                                this.setAllClientsUnchecked()
                            }}
                        >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            color="primary"
                            startIcon={<AddCircleIcon />}
                            onClick={async () => {
                                await this.saveClientUserDetails()
                                this.setState({ dialogOpen: false })
                            }}
                        >
                            Add {selectedUsers.length > 1 ? 'Users' : 'User'} to{' '}
                            {clients.filter(client => client.checked).length > 1
                                ? 'Clients'
                                : 'Client'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
        )
    }

    async populateOrgUsersData() {
        this.setState({ isLoading: true })
        const token = await authService.getAccessToken()
        const clientUsersResponse = await fetch(
            'api/users/GetClientUsersForOrg',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (clientUsersResponse.ok) {
            const data = await clientUsersResponse.json()
            this.setState({ users: data })
        } else {
            this.setState({
                notificationAlert: {
                    open: true,
                    message:
                        'Error While Retrieving Client Users - Please Contact Support.',
                    severity: 'error'
                }
            })
        }

        const clientsResponse = await fetch(
            'api/clients/getclientslist?module=orgusers',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )

        if (clientsResponse.ok) {
            const data = await clientsResponse.json()
            // need to extend the client object to include a 'checked' and 'disabled' property
            data.forEach(client => {
                client.checked = false
                client.disabled = false
            })
            this.setState({
                clients: data,
                filteredClients: data
            })
        } else {
            this.setState({
                notificationAlert: {
                    open: true,
                    message:
                        'Error While Retrieving Available Clients - Please Contact Support.',
                    severity: 'error'
                }
            })
        }

        this.setState({ isLoading: false })
    }

    async deleteClientUsers(clientUsers) {
        this.setState({ isLoading: true })

        const token = await authService.getAccessToken()

        const userIds = clientUsers.map(user => user.id)

        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userIds)
        }

        fetch('api/users/DeleteClientUsers', options)
            .then(res => {
                if (res.ok) {
                    this.setState({
                        notificationAlert: {
                            open: true,
                            message: 'Client Users Successfully Deleted.',
                            severity: 'success'
                        },
                        isLoading: false
                    })

                    this.populateOrgUsersData()

                    return Promise.resolve('Client Users Deleted.')
                } else {
                    this.setState({
                        notificationAlert: {
                            open: true,
                            message:
                                'Failed To Delete Client Users - Please Contact Support.',
                            severity: 'error'
                        },
                        isLoading: false
                    })

                    return Promise.reject(
                        'An error occurred (' + res.status + ').'
                    )
                }
            })
            .catch(err => {
                this.setState({
                    notificationAlert: {
                        open: true,
                        message:
                            'Failed To Delete Client Users - Please Contact Support.',
                        severity: 'error'
                    },
                    isLoading: false
                })
                console.log(err)
            })
    }
}
export default withStyles(styles)(OrgUsers)
