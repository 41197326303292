import React, { useState, useEffect } from 'react'
import ContainerTable from './ContainerTable'
import { canSeeContainers } from '../utils/permissions'
import { getAppInfo } from '../api/api'
import { LinearProgress } from '@material-ui/core'
import UnauthorizedMessage from './shared/UnauthorizedMessage'

export default function ContainerManagement() {
    const [loading, setIsLoading] = useState(true)
    const [showSearchContainer, setShowSearchContainer] = useState(false)

    useEffect(() => {
        getAppInfo(
            null,
            async data => {
                setIsLoading(false)
                setShowSearchContainer(await canSeeContainers(data))
            },
            () => {
                setIsLoading(false)
            }
        )
    }, [])

    return (
        <div>
            {loading ? (
                <LinearProgress />
            ) : showSearchContainer ? (
                <ContainerTable searchItem="Container" itemKey="container" />
            ) : (
                UnauthorizedMessage({ moduleName: 'Containers' })
            )}
        </div>
    )
}
