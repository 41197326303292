import React, { useEffect, useState } from 'react'
import CardTitle from './CardTitle'
import { renderTable } from './TableHelper'
import { makeStyles } from '@material-ui/core/styles'
import { retrieveView } from './Views'

export default function ShipmentRoutingCard(props) {
    const [routingColumns, setRoutingColumns] = useState([])
    const { shipment, clientId } = props

    useEffect(() => {
        getRoutingColumns()
    }, [])

    const useStyles = makeStyles(theme => ({
        title: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        textFieldForm: {
            width: theme.textFieldForm.width
        }
    }))

    const classes = useStyles()

    const getRoutingColumns = async () => {
        const view = await retrieveView(clientId, 'ShipmentRoutingDetails')
        let routingColumnData = []

        if (!view) {
            routingColumnData = [
                { key: 'transportMode', label: 'Transport Mode', icon: false },
                { key: 'legNumber', label: 'Leg #', icon: false },
                { key: 'legType', label: 'Leg Type', icon: false },
                { key: 'vesselName', label: 'Vessel Name', icon: false },
                { key: 'voyageFlight', label: 'Voyage Flight', icon: false },
                { key: 'loadPort', label: 'Load Port', icon: false },
                { key: 'dischargePort', label: 'Discharge Port', icon: false },
                { key: 'etd', label: 'ETD', icon: false },
                { key: 'atd', label: 'ATD', icon: false },
                { key: 'eta', label: 'ETA', icon: false },
                { key: 'ata', label: 'ATA', icon: false },
                { key: 'consolMode', label: 'Consol Mode', icon: false },
                { key: 'masterBillNumber', label: 'Master Bill', icon: false },
                { key: 'vesselLloydsIMO', label: 'IMO Number', icon: false }
            ]
        } else {
            // need to map value to key and title to label and icon = false
            routingColumnData = view.columnDefinitionsArray.map((item) => {
                return {
                    key: item.value,
                    label: item.title,
                    icon: false
                }})
            }

        setRoutingColumns(routingColumnData)
    }

    return (
        <CardTitle title="Routing" checkOn={false} disablePadding={true}>
            {renderTable(
                shipment?.routingInfo ?? [],
                routingColumns,
                'routing',
                classes,
                null,
                {
                    maxHeight: '200px'
                }
            )}
        </CardTitle>
    )
}
