import authService from '../components/api-authorization/AuthorizeService'

export async function getOrgViewCodeFromOrganization() {
    const token = await authService.getAccessToken()
    const response = await fetch('api/Views/GetOrgViewCode', {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
    if (response.ok) {
        const data = await response.json()
        if (!data) {
            return null
        } else {
            return data
        }
    }
}
