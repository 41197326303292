import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import {
    FormControl,
    Grid,
    Card,
    Typography,
    CardContent,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    InputLabel
} from '@material-ui/core'
import { CollapseIcon } from '@mui-treasury/layout'
import { TextField, InputAdornment, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import Alert from '@material-ui/lab/Alert'
import CardTitle from './shared/CardTitle'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { NavLink } from 'react-router-dom'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
})

const classes = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    }
}))
const passwordPath = { pathname: `${ApplicationPaths.Password}` }

class AccountDetails extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPosting: false,
            user: [],
            organization: null,
            isValidForm: true,
            isValidUserFirstName: true,
            errorTextUserFirstName: '',
            isValidUserLastName: true,
            errorTextUserLastName: '',
            companyCode: 'Default',
            demoMode: false,
            companyList: [],
            alert: {
                open: false,
                success: false,
                message: ''
            },
            searchShipment: true,
            theme: props.theme,
            expanded: 'panel1'
        }

        this.onSearch = this.onSearch.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.getUserAccountData()
        this.populateConfig()
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel()
        }
    }

    updateUserField = (value, key) => {
        this.setState(prevState => {
            let user = Object.assign({}, prevState.user)
            user[key] = value
            return { user }
        })
    }

    onSearch(e) {
        this.handleChange(e, 'searchShipment', false)
    }

    handleChangeValue = (event, elem) => {
        let value = null

        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            return { alert }
        })

        switch (elem) {
            case 'companyCode':
                value = event ? event.target.value : event
                this.setState({
                    companyCode: value
                })
                this.updateUserField(value, 'companyCode')
                break

            default:
                break
        }
    }

    handleCheckboxChange = (key, value) => {
        switch (key) {
            case 'demoMode':
                this.updateUserField(value, 'demoMode')
                break

            default:
                break
        }
    }

    handleChange(event, field, isRequired) {
        this.setState(prevState => {
            let alerts = Object.assign({}, prevState.alerts)
            alerts.showSuccess = false
            alerts.showFail = false
            return { alerts }
        })
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            return { alert }
        })

        const { isValidUserFirstName, isValidUserLastName } = this.state

        let value = event.target.value
        let isValidField = true
        let isValidForm = false
        let msgError = ''

        if (value.length === 0 && isRequired) {
            isValidField = false
            isValidForm = false
            msgError = 'cannot be empty.'
        }

        switch (field) {
            case 'account-first-name':
                this.setState({
                    errorTextUserFirstName: !isValidField
                        ? 'First Name ' + msgError
                        : '',
                    isValidUserFirstName: isValidField
                })

                this.updateUserField(value, 'firstName')

                if (isValidUserLastName && isValidField) {
                    isValidForm = true
                }
                break

            case 'account-last-name':
                this.setState({
                    errorTextUserLastName: !isValidField
                        ? 'Last Name ' + msgError
                        : '',
                    isValidUserLastName: isValidField
                })

                this.updateUserField(value, 'lastName')
                if (isValidUserFirstName && isValidField) {
                    isValidForm = true
                }
                break

            case 'searchShipment':
                const searchShipment = this.state.searchShipment
                this.setState({ searchShipment: !searchShipment })
                break

            default:
                break
        }

        this.setState({ isValidForm: isValidForm })
    }
    handleACCChange = panel => (event, expanded) => {
        this.setState({ expanded: expanded ? panel : false })
    }
    handleSubmit = async event => {
        const { user, isValidForm } = this.state
        event.preventDefault()

        if (user.id && isValidForm) {
            this.updateUserAccount(user.id, user)
        }
    }

    handleShowChangePassword(event) {
        this.setState({ showChangePassword: !this.state.showChangePassword })
    }
    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({
            organization: data,
            companyList: data.companyCodeArray
        })
    }
    handleCloseAlert() {
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            alert.success = false
            alert.message = ''
            return { alert }
        })
    }

    renderAlert = () => {
        const alert = this.state.alert
        return alert.open ? (
            <div className="w-100">
                <Alert
                    variant="filled"
                    severity={alert.success ? 'success' : 'error'}
                    onClose={() => {
                        this.handleCloseAlert()
                    }}
                >
                    {alert.message}
                </Alert>
            </div>
        ) : null
    }

    renderInput = (
        id,
        label,
        placeholder,
        orgValue,
        required,
        disabled,
        adornment,
        valid = null,
        errorText = null
    ) => {
        const { user } = this.state
        const { classes } = this.props

        var params = {}
        if (valid !== null && errorText !== null) {
            params['helperText'] = !this.state[valid]
                ? this.state[errorText]
                : ''
            params['error'] = !this.state[valid]
        }

        return (
            <Grid item xs={12} className="d-flex flex-shrink-1">
                <FormControl>
                    <TextField
                        className={classes.textFieldForm}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        required={required}
                        disabled={disabled}
                        value={user[orgValue] || ''}
                        onChange={e => this.handleChange(e, id, required)}
                        {...params}
                        InputProps={
                            adornment
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <RecentActorsIcon />
                                          </InputAdornment>
                                      )
                                  }
                                : {}
                        }
                    />
                </FormControl>
            </Grid>
        )
    }

    render() {
        const {
            isLoading,
            isPosting,
            isAdmin,
            user,
            expanded,
            organization,
            companyCode,
            demoMode,
            companyList
        } = this.state
        const backgroundColor =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#ffffff'
        const textColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'
        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'
        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'
        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#000000'

        return (
            <div>
                <CardContent className="p-0">
                    {this.renderAlert()}
                    <Paper className={classes.paper}>
                        <div className="custom-card-title">
                            <Grid container spacing={1} direction="column">
                                <Grid
                                    item
                                    xs={12}
                                    className="mb-4 w-100 d-flex mobile-mode-no-flex"
                                >
                                    <Grid item lg={3} sm={12} xs={12}>
                                        <div className="inner border-right-separator p-34 position-relative">
                                            <h6 className="xs-text-center text-sliversweetpea font-12 font-weight-400">
                                                ACCOUNT SETTINGS
                                            </h6>
                                            <div className="custom-psa-ul-li-list">
                                                <ul>
                                                    <li
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            color: primarycolor
                                                        }}
                                                    >
                                                        {' '}
                                                        <Icon className="mr-2 font-16">
                                                            person
                                                        </Icon>{' '}
                                                        My Account
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <Icon className="mr-2 font-16">
                                                            lock
                                                        </Icon>{' '}
                                                        <NavLink
                                                            key={
                                                                passwordPath.pathname
                                                            }
                                                            disabled={
                                                                isPosting ||
                                                                isLoading
                                                            }
                                                            style={{
                                                                color: backgroundColor
                                                            }}
                                                            to={
                                                                passwordPath.pathname
                                                            }
                                                        >
                                                            Change Password
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="separator-arrow">
                                                <CollapseIcon />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid lg={9} sm={12} item xs={12}>
                                        <div className="p-34 account-details">
                                            <CardTitle
                                                className="xs-text-center p-0"
                                                title=""
                                                checkOn={false}
                                            >
                                                <h6 className="font-18 mb-3 text-black">
                                                    {' '}
                                                    My Account Details
                                                </h6>

                                                <Grid
                                                    container
                                                    direction="column"
                                                    spacing={4}
                                                >
                                                    <Grid item xs={12}>
                                                        <form
                                                            onSubmit={
                                                                this
                                                                    .handleSubmit
                                                            }
                                                            noValidate
                                                            autoComplete="off"
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={4}
                                                                className="mt-2"
                                                            >
                                                                <Grid
                                                                    item
                                                                    lg={3}
                                                                    sm={3}
                                                                    xs={12}
                                                                    className="mb-2"
                                                                >
                                                                    {this.renderInput(
                                                                        'account-email',
                                                                        'Email',
                                                                        'Email',
                                                                        'email',
                                                                        false,
                                                                        true,
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    lg={3}
                                                                    sm={3}
                                                                    xs={12}
                                                                    className="mb-2"
                                                                >
                                                                    <div className="user-icon-alignment">
                                                                        {this.renderInput(
                                                                            'account-first-name',
                                                                            'First Name',
                                                                            'First Name',
                                                                            'firstName',
                                                                            true,
                                                                            false,
                                                                            true,
                                                                            'isValidUserFirstName',
                                                                            'errorTextUserFirstName'
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    lg={3}
                                                                    sm={3}
                                                                    xs={12}
                                                                    className="mb-2"
                                                                >
                                                                    {this.renderInput(
                                                                        'account-last-name',
                                                                        'Last Name',
                                                                        'Last Name',
                                                                        'lastName',
                                                                        true,
                                                                        false,
                                                                        false,
                                                                        'isValidUserLastName',
                                                                        'errorTextUserLastName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    lg={3}
                                                                    sm={3}
                                                                    xs={12}
                                                                    className="mb-2"
                                                                >
                                                                    <FormControl className="mr-auto">
                                                                        <div>
                                                                            <InputLabel
                                                                                id="select-label-companycode"
                                                                                className="my-auto"
                                                                            >
                                                                                Company
                                                                            </InputLabel>
                                                                            <Select
                                                                                labelId="select-label-companycode"
                                                                                id="companyCode"
                                                                                value={
                                                                                    user.companyCode
                                                                                        ? user.companyCode
                                                                                        : companyCode
                                                                                }
                                                                                onChange={e =>
                                                                                    this.handleChangeValue(
                                                                                        e,
                                                                                        'companyCode'
                                                                                    )
                                                                                }
                                                                            >
                                                                                <MenuItem
                                                                                    key={
                                                                                        99
                                                                                    }
                                                                                    value={
                                                                                        'Default'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        'Default'
                                                                                    }
                                                                                </MenuItem>
                                                                                {companyList
                                                                                    ? companyList.map(
                                                                                          function (
                                                                                              company,
                                                                                              i
                                                                                          ) {
                                                                                              return (
                                                                                                  <MenuItem
                                                                                                      key={
                                                                                                          i
                                                                                                      }
                                                                                                      value={
                                                                                                          company
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          company
                                                                                                      }
                                                                                                  </MenuItem>
                                                                                              )
                                                                                          }
                                                                                      )
                                                                                    : null}
                                                                            </Select>
                                                                        </div>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    className="xxl-2"
                                                                >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={event => {
                                                                                    this.handleCheckboxChange(
                                                                                        'demoMode',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                    )
                                                                                }}
                                                                                color="default"
                                                                                name=""
                                                                                checked={
                                                                                    user.demoMode
                                                                                        ? user.demoMode
                                                                                        : demoMode
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            'Demo Mode'
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                    </Grid>
                                                </Grid>
                                                <div className="d-flex justify-content-end xs-text-left my-3">
                                                    <Button
                                                        style={{
                                                            background:
                                                                primarycolor,
                                                            color: textColor
                                                        }}
                                                        type="submit"
                                                        size="medium"
                                                        variant="contained"
                                                        onClick={e =>
                                                            this.handleSubmit(e)
                                                        }
                                                        disabled={
                                                            !this.state
                                                                .isValidForm ||
                                                            this.state.isPosting
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                            </CardTitle>
                                            <div
                                                className="separator mb-20"
                                                style={{
                                                    background: primarycolor
                                                }}
                                            ></div>

                                            <CardTitle
                                                title=""
                                                checkOn={false}
                                                className="p-0"
                                            >
                                                <h6 className="font-16 mb-3 text-black ">
                                                    {' '}
                                                    User Role
                                                </h6>
                                                <Typography
                                                    gutterBottom
                                                    variant="p"
                                                    className="text-sliversweetpea font-14"
                                                >
                                                    {isAdmin
                                                        ? 'Administrator'
                                                        : 'Member'}
                                                </Typography>
                                            </CardTitle>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </CardContent>
            </div>
        )
    }

    async getUserAccountData() {
        this.setState({ isLoading: true })
        const token = await authService.getAccessToken()
        const userIsAdmin = await authService.isAdmin()
        const response = await fetch('api/users/current', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (response.ok) {
            const data = await response.json()
            this.setState({ user: data, isAdmin: userIsAdmin })
        }
        this.setState({ isLoading: false })
    }

    async updateUserAccount(id, data) {
        this.setState({ isPosting: true })
        let errText = ''

        const token = await authService.getAccessToken()
        await fetch('api/users/' + id, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                let results = null

                if (response.ok) {
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = true
                        alert.message = 'User information is saved.'
                        return { alert }
                    })
                    results = response.json()
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                    results = response.text()
                }
                return results
            })
            .then(d => {
                this.setState({
                    user: data.id ? d : data,
                    isPosting: false,
                    isValidForm: true
                })
            })
            .catch(error => {
                this.setState({
                    isPosting: false
                })
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                console.log(error)
            })
    }
}
AccountDetails.propTypes = {
    classes: PropTypes.object.isRequired
}
export default withStyles(styles)(AccountDetails)
