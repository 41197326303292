import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, TextField, IconButton, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import PortAutocomplete from './PortAutocomplete'
import { getUNLOCO } from '../../api/api'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/moment'
import { Table, TableContainer, TableBody } from '@material-ui/core'
import { TableRow, TableCell, TableHead } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import authService from '../api-authorization/AuthorizeService'
import { Select, MenuItem } from '@material-ui/core'
import {
    Card,
    CardContent,
    CardActions,
    CardActionArea,
    CardHeader
} from '@material-ui/core'
import { updateOrderPlanning } from '../../api/api'
import { Alert, AlertTitle } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { tryParseInt } from '../../utils/numbers'

const classes = makeStyles(theme => ({}))

export default function OrderPlanningCardEdit({
    itemextra,
    item,
    organization
}) {
    const [originUnlocoCodes, setOriginUnlocoCodes] = useState([])
    const [destinationUnlocoCodes, setDestinationUnlocoCodes] = useState([])
    const [loadUnlocoCodes, setLoadUnlocoCodes] = useState([])
    const [dischargeUnlocoCodes, setDischargeUnlocoCodes] = useState([])
    const [loadUNLOCOCodeObject, setLoadUNLOCOCodeObject] = useState(null)
    const [dischargeUNLOCOCodeObject, setDischargeUNLOCOCodeObject] =
        useState(null)
    const [originUNLOCOCodeObject, setOriginUNLOCOCodeObject] = useState(null)
    const [destinationUNLOCOCodeObject, setDestinationUNLOCOCodeObject] =
        useState(null)
    const [plannedDepartureVessel, setPlannedDepartureVessel] = useState('')
    const [plannedDepartureVoyage, setPlannedDepartureVoyage] = useState('')
    const [dateDepartEst, setDateDepartEst] = useState(null)
    const [plannedArrivalVessel, setPlannedArrivalVessel] = useState('')
    const [plannedArrivalVoyage, setPlannedArrivalVoyage] = useState('')
    const [dateArriveEst, setDateArriveEst] = useState(null)
    const [containers, setContainers] = useState([])
    const [masterBillNumber, setMasterBillNumber] = useState('')
    const [orderBookingNumber, setOrderBookingNumber] = useState('')
    const [orderBookingDate, setOrderBookingDate] = useState(null)
    const [backgroundColor, setBackgroundColor] = useState('white')

    const [containerTypes, setContainerTypes] = useState([])
    const [editing, setEditing] = useState(false)
    const [saving, setSaving] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertData, setAlertData] = useState({})

    const theme = useTheme()
    const dateFns = new DateFnsUtils()

    useEffect(() => {
        if (itemextra) {
            setCW1Values()
        }
    }, [itemextra])

    useEffect(() => {
        getContainerTypes()
    }, [])

    useEffect(() => {
        setBackgroundColor(organization.colorThemeRGB)
    }, [organization])

    const renderDatePicker = (pickerValue, dateType) => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disabled={saving}
                    variant="inline"
                    autoOk={true}
                    format={organization.pickerDateFormat}
                    onChange={e => {
                        switch (dateType) {
                            case 'dateDepartEst':
                                setDateDepartEst(
                                    e ? dateFns.format(e, 'YYYY-MM-DD') : null
                                )
                                break
                            case 'dateArriveEst':
                                setDateArriveEst(
                                    e ? dateFns.format(e, 'YYYY-MM-DD') : null
                                )
                                break
                            case 'orderBookingDate':
                                setOrderBookingDate(
                                    e ? dateFns.format(e, 'YYYY-MM-DD') : null
                                )
                                break
                        }
                    }}
                    value={pickerValue}
                />
            </MuiPickersUtilsProvider>
        )
    }

    const setCW1Values = () => {
        if (itemextra.origin && itemextra.origin.length > 4) {
            searchUNLOCO(itemextra.origin.substring(0, 5), 'origin', false)
        }
        if (itemextra.destination && itemextra.destination.length > 4) {
            searchUNLOCO(
                itemextra.destination.substring(0, 5),
                'destination',
                false
            )
        }
        if (itemextra.loadPort && itemextra.loadPort.length > 4) {
            searchUNLOCO(itemextra.loadPort.substring(0, 5), 'load', false)
        }
        if (itemextra.dischargePort && itemextra.dischargePort.length > 4) {
            searchUNLOCO(
                itemextra.dischargePort.substring(0, 5),
                'discharge',
                false
            )
        }
        if (itemextra.containers) {
            setContainers(itemextra.containers)
        }
        setPlannedDepartureVessel(itemextra.plannedDepartureVessel)
        setPlannedDepartureVoyage(itemextra.plannedDepartureVoyage)
        setDateDepartEst(itemextra.dateDepartEst)
        setPlannedArrivalVessel(itemextra.plannedArrivalVessel)
        setPlannedArrivalVoyage(itemextra.plannedArrivalVoyage)
        setDateArriveEst(itemextra.dateArriveEst)
        setMasterBillNumber(itemextra.masterbillNumber)
        if (itemextra.customizedFields) {
            // iterate through customized fields and set values
            for (let i = 0; i < itemextra.customizedFields.length; i++) {
                const field = itemextra.customizedFields[i]
                if (field.key === 'Carrier_Booking#') {
                    setOrderBookingNumber(field.value)
                } else if (field.key === 'Carrier_BookingDate') {
                    setOrderBookingDate(field.value)
                }
            }
        }
    }

    const updateOrderPlanningData = async data => {
        setSaving(true)
        updateOrderPlanning(data, handleUpdateSuccess, handleUpdateFailure)
    }

    const handleUpdateSuccess = () => {
        setShowAlert(true)
        setAlertData({
            severity: 'success',
            title: 'Success',
            message: 'Order planning data updated successfully!'
        })
        setEditing(false)
        setSaving(false)
    }

    const handleUpdateFailure = () => {
        setShowAlert(true)
        setAlertData({
            severity: 'error',
            title: 'Error',
            message:
                'Failed to update order planning data, please contact support.'
        })
        setSaving(false)
    }

    const getContainerTypes = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataTypes?dataTypesRequested=CONTAINER_MODE',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            setContainerTypes(data)
        }
    }

    const handleAddContainer = () => {
        setContainers([
            ...containers,
            {
                containerNumber: '',
                containerCount: 0,
                containerType: ''
            }
        ])
    }

    const handleRemoveContainer = index => {
        const newContainers = [...containers]
        newContainers.splice(index, 1)
        setContainers(newContainers)
    }

    const renderResultAlert = () => {
        return (
            showAlert && (
                <div
                    className="w-100"
                    style={{
                        padding: '10px',
                        backgroundColor:
                            alertData.severity === 'success'
                                ? 'lightgreen'
                                : 'lightcoral',
                        borderRadius: '5px'
                    }}
                >
                    <span style={{ fontWeight: 'bold' }}>
                        {alertData.title + ': '}
                    </span>
                    <span>{alertData.message}</span>
                </div>
            )
        )
    }

    const renderContainersTable = () => {
        return (
            <>
                <Typography
                    variant="h6"
                    gutterBottom
                    className="font-12 font-raleway-medium text-lviv-blue"
                >
                    Planned Containers
                </Typography>
                <TableContainer style={{ maxHeight: '400px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: backgroundColor }}>
                                    Container Number
                                </TableCell>
                                <TableCell style={{ color: backgroundColor }}>
                                    Count
                                </TableCell>
                                <TableCell style={{ color: backgroundColor }}>
                                    Container Type
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {containers.map((container, index) => {
                                return (
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                disabled={saving}
                                                value={
                                                    container.containerNumber
                                                }
                                                onChange={e => {
                                                    const newContainers = [
                                                        ...containers
                                                    ]
                                                    newContainers[
                                                        index
                                                    ].containerNumber =
                                                        e.target.value
                                                    setContainers(newContainers)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width="100px">
                                            <TextField
                                                disabled={saving}
                                                type="number"
                                                value={container.containerCount}
                                                onChange={e => {
                                                    const newContainers = [
                                                        ...containers
                                                    ]
                                                    newContainers[
                                                        index
                                                    ].containerCount =
                                                        e.target.value
                                                    setContainers(newContainers)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                disabled={saving}
                                                style={{ marginTop: '12px' }}
                                                value={container.containerType}
                                                onChange={e => {
                                                    const newContainers = [
                                                        ...containers
                                                    ]
                                                    newContainers[
                                                        index
                                                    ].containerType =
                                                        e.target.value
                                                    setContainers(newContainers)
                                                }}
                                            >
                                                {containerTypes.map(
                                                    containerType => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    containerType.propertyKey
                                                                }
                                                            >
                                                                {containerType.propertyKey +
                                                                    ' - ' +
                                                                    containerType.value}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={saving}
                                                color="primary"
                                                onClick={() =>
                                                    handleRemoveContainer(index)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    disabled={saving}
                    variant="contained"
                    color="primary"
                    style={{ maxWidth: '300px' }}
                    onClick={handleAddContainer}
                    className={classes.button}
                    startIcon={<AddCircleIcon />}
                >
                    Add Container
                </Button>
            </>
        )
    }

    const searchUNLOCO = async (query, portType, isName = true) => {
        getUNLOCO(query, isName, async data => {
            if (portType === 'origin') {
                setOriginUnlocoCodes(data)
            } else if (portType === 'destination') {
                setDestinationUnlocoCodes(data)
            } else if (portType === 'load') {
                setLoadUnlocoCodes(data)
            } else if (portType === 'discharge') {
                setDischargeUnlocoCodes(data)
            }
            if (data.length === 1) {
                if (portType === 'origin') {
                    setOriginUNLOCOCodeObject(data[0])
                } else if (portType === 'destination') {
                    setDestinationUNLOCOCodeObject(data[0])
                } else if (portType === 'load') {
                    setLoadUNLOCOCodeObject(data[0])
                } else if (portType === 'discharge') {
                    setDischargeUNLOCOCodeObject(data[0])
                }
            }
        })
    }

    return (
        <div className="p-0 pl-3 data-data-info w-100">
            <h5 class="text-Curious-blue my-2 font-14" checkOn={false}>
                Planning Details
            </h5>
            <Card>
                <CardContent>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        className={theme.bgColorSection}
                    >
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={3} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Planned Masterbill #
                                    </Typography>
                                    <TextField
                                        disabled={saving}
                                        variant="outlined"
                                        value={masterBillNumber}
                                        onChange={e => {
                                            setMasterBillNumber(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Booking #
                                    </Typography>
                                    <TextField
                                        disabled={saving}
                                        variant="outlined"
                                        value={orderBookingNumber}
                                        onChange={e => {
                                            setOrderBookingNumber(
                                                e.target.value
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Booking Date
                                    </Typography>
                                    {renderDatePicker(
                                        orderBookingDate,
                                        'orderBookingDate'
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    className="sm-flex-100"
                                ></Grid>
                                <Grid item xs={3} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Origin Port
                                    </Typography>
                                    <PortAutocomplete
                                        disabled={saving}
                                        data={originUnlocoCodes}
                                        value={originUNLOCOCodeObject}
                                        labelRender={option =>
                                            `${option.unloco} - ${option.portName}`
                                        }
                                        selectChangeCallback={(e, value) => {
                                            setOriginUNLOCOCodeObject(value)
                                        }}
                                        textChangeCallback={e => {
                                            const value = e.target.value
                                            searchUNLOCO(value, 'origin', true)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Port Of Loading
                                    </Typography>
                                    <PortAutocomplete
                                        disabled={saving}
                                        data={loadUnlocoCodes}
                                        value={loadUNLOCOCodeObject}
                                        labelRender={option =>
                                            `${option.unloco} - ${option.portName}`
                                        }
                                        selectChangeCallback={(e, value) => {
                                            setLoadUNLOCOCodeObject(value)
                                        }}
                                        textChangeCallback={e => {
                                            const value = e.target.value
                                            searchUNLOCO(value, 'load', true)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} className="sm-flex-100">
                                    <div className="column">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue"
                                            >
                                                Departure Vessel
                                            </Typography>
                                            <TextField
                                                disabled={saving}
                                                variant="outlined"
                                                value={plannedDepartureVessel}
                                                onChange={e => {
                                                    setPlannedDepartureVessel(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className="sm-flex-100">
                                    <div className="column">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue"
                                            >
                                                Departure Voyage #
                                            </Typography>
                                            <TextField
                                                disabled={saving}
                                                variant="outlined"
                                                value={plannedDepartureVoyage}
                                                onChange={e => {
                                                    setPlannedDepartureVoyage(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className="sm-flex-100">
                                    <div className="column">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue"
                                            >
                                                ETD
                                            </Typography>
                                            {renderDatePicker(
                                                dateDepartEst,
                                                'dateDepartEst'
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={3} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Destination Port
                                    </Typography>
                                    <PortAutocomplete
                                        disabled={saving}
                                        data={destinationUnlocoCodes}
                                        value={destinationUNLOCOCodeObject}
                                        labelRender={option =>
                                            `${option.unloco} - ${option.portName}`
                                        }
                                        selectChangeCallback={(e, value) => {
                                            setDestinationUNLOCOCodeObject(
                                                value
                                            )
                                        }}
                                        textChangeCallback={e => {
                                            const value = e.target.value
                                            searchUNLOCO(
                                                value,
                                                'destination',
                                                true
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Discharge Port
                                    </Typography>
                                    <PortAutocomplete
                                        disabled={saving}
                                        data={dischargeUnlocoCodes}
                                        value={dischargeUNLOCOCodeObject}
                                        labelRender={option =>
                                            `${option.unloco} - ${option.portName}`
                                        }
                                        selectChangeCallback={(e, value) => {
                                            setDischargeUNLOCOCodeObject(value)
                                        }}
                                        textChangeCallback={e => {
                                            const value = e.target.value
                                            searchUNLOCO(
                                                value,
                                                'discharge',
                                                true
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} className="sm-flex-100">
                                    <div className="column">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue"
                                            >
                                                Arrival Vessel
                                            </Typography>
                                            <TextField
                                                disabled={saving}
                                                variant="outlined"
                                                value={plannedArrivalVessel}
                                                onChange={e => {
                                                    setPlannedArrivalVessel(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className="sm-flex-100">
                                    <div className="column">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue"
                                            >
                                                Arrival Voyage #
                                            </Typography>
                                            <TextField
                                                disabled={saving}
                                                variant="outlined"
                                                value={plannedArrivalVoyage}
                                                onChange={e => {
                                                    setPlannedArrivalVoyage(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className="sm-flex-100">
                                    <div className="column">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue"
                                            >
                                                ETA
                                            </Typography>
                                            {renderDatePicker(
                                                dateArriveEst,
                                                'dateArriveEst'
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row">
                                <Grid item xs={12}>
                                    {renderContainersTable()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <hr />
                <CardActions>
                    {/* <Button
                            variant="contained"
                            color="primary"
                            style={{ maxWidth: '200px' }}
                            onClick={() => {
                                if (editing) {
                                    if (!window.confirm('Cancel editing?')) {
                                        return
                                    }
                                    // reset values and cancel
                                    setCW1Values()
                                    setEditing(false)
                                } else {
                                    setEditing(true)
                                }
                            }}
                            className={classes.button}>
                            { editing ? 'Cancel' : 'Edit' }
                        </Button> */}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ maxWidth: '200px' }}
                        onClick={() => {
                            // clear alert data
                            setShowAlert(false)
                            const data = {
                                buyerCode: item.buyerCode,
                                orderNumber: item.orderNumber,
                                orderNumberSplit: item.orderNumberSplit,
                                orderCarrierBookingNumber: orderBookingNumber,
                                orderCarrierBookingDate: orderBookingDate,
                                orderTransportMode:
                                    itemextra.orderTransportMode,
                                masterBillNumber: masterBillNumber,
                                originPort: originUNLOCOCodeObject?.unloco,
                                loadPort: loadUNLOCOCodeObject?.unloco,
                                dischargePort:
                                    dischargeUNLOCOCodeObject?.unloco,
                                destinationPort:
                                    destinationUNLOCOCodeObject?.unloco,
                                departureVessel: plannedDepartureVessel,
                                departureVoyage: plannedDepartureVoyage,
                                estimatedDeparture: dateDepartEst,
                                arrivalVessel: plannedArrivalVessel,
                                arrivalVoyage: plannedArrivalVoyage,
                                estimatedArrival: dateArriveEst,
                                containers: containers.map(container => {
                                    return {
                                        containerNumber:
                                            container.containerNumber,
                                        containerCount: tryParseInt(
                                            container.containerCount,
                                            0
                                        ),
                                        containerType: container.containerType
                                    }
                                })
                            }
                            updateOrderPlanningData(data)
                        }}
                        className={classes.button}
                    >
                        {saving ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                    {renderResultAlert()}
                </CardActions>
            </Card>
        </div>
    )
}
