import React, { Suspense, PureComponent } from 'react'
import authService from './api-authorization/AuthorizeService'
import Alert from '@material-ui/lab/Alert'
import { Grid } from '@material-ui/core'
import { Card } from '@material-ui/core'
import { renderDetailMap } from '../components/shared/MapHelper'
import { LinearProgress } from '@material-ui/core'
import { renderTable } from '../components/shared/TableHelper'
import { withStyles } from '@material-ui/core/styles'
import CardTitle from './shared/CardTitle'
import OrderInfoCard from './shared/OrderInfoCard'
import OrderSupplierInfoCard from './shared/OrderSupplierInfoCard'
import OrderDeliveryInfoCard from './shared/OrderDeliveryInfoCard'
import OrderNotesTable from './shared/OrderNotesTable'
import OrderShipmentDetailCard from './shared/OrderShipmentDetailCard'
import OrderDetailCard from './shared/OrderDetailCard'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { Paper } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { CardContent } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DocumentUploadSelectDialog from './shared/DocumentUploadSelectDialog'
import OrderPlanningCard from './shared/OrderPlanningCard'
import OrderPlanningCardEdit from './shared/OrderPlanningCardEdit'
import { clientUserHasPermission } from '../utils/permissions'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    bgColorSection: {
        backgroundColor: theme.bgColorSection
    }
})

// we need to lazy load due to speed of downloading docs
const OrderDocumentsCard = React.lazy(() =>
    import('./shared/OrderDocumentsCard')
)

const bgColorSection = 'bg-white text-dark'

const milestones = [
    {
        key: 'description',
        label: 'Description',
        icon: true
    },
    {
        key: 'estimatedDate',
        label: 'Estimate',
        icon: false
    },
    {
        key: 'actualDate',
        label: 'Actual',
        icon: false
    }
]

const routingInfo = [
    { key: 'vesselName', label: 'Vessel Name', icon: false },
    { key: 'masterBillNumber', label: 'Master Bill', icon: false },
    { key: 'legNumber', label: 'Leg #', icon: false },
    { key: 'legType', label: 'Leg Type', icon: false },
    { key: 'transportMode', label: 'Transport Mode', icon: false },
    { key: 'consolMode', label: 'Consol Mode', icon: false },
    { key: 'loadPort', label: 'Load Port', icon: false },
    { key: 'dischargePort', label: 'Discharge Port', icon: false },
    { key: 'voyageFlight', label: 'Voyage Flight', icon: false },
    { key: 'vesselLloydsIMO', label: 'IMO Number', icon: false },
    { key: 'etd', label: 'ETD', icon: false },
    { key: 'eta', label: 'ETA', icon: false },
    { key: 'atd', label: 'ATD', icon: false },
    { key: 'ata', label: 'ATA', icon: false }
]

const orderLines = [
    { key: 'partNumber', label: 'Part Number (SKU)', icon: false },
    { key: 'partDescription', label: 'Part Description', icon: false },
    { key: 'lineNumber', label: 'Line #', icon: false },
    { key: 'orderedQty', label: 'Quantity Ordered', icon: false },
    { key: 'orderedQtyUnit', label: 'Ordered Unit', icon: false },
    { key: 'itemPrice', label: 'Item Price', icon: false },
    { key: 'invoicedQty', label: 'Quantity Invoiced', icon: false },
    { key: 'receivedQty', label: 'Quantity Received', icon: false },
    { key: 'containerNumber', label: 'Container Number', icon: false }
]

class OrderDetailPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            clientId: props.match.params.clientId,
            orderNumber: props.match.params.orderNumber,
            orderNumberSplit: props.match.params.orderNumberSplit,
            item: null,
            itemextra: null,
            shipmentdetails: null,
            loading: true,
            error: false,
            dialogOpen: false,
            selectedContainer: {},
            organization: null,
            expanded: 'panel1',
            uri: props.uri || 'GetOrder',
            client: null,
            canEditOrderPlanning: false
        }
        this.populateOrderData = this.populateOrderData.bind(this)
        this.fetchAllOrderData = this.fetchAllOrderData.bind(this)
    }

    async componentDidMount() {
        await this.populateOrderData()
        await this.getOrderPlanningPermission()
    }

    getOrderPlanningPermission = async () => {
        clientUserHasPermission(this.state.clientId, 'editorderplanning').then(
            result => {
                this.setState({ canEditOrderPlanning: result })
            }
        )
    }

    handleActionsDialog = action => {
        if (action) {
            this.handleDelete()
        }

        this.setState({
            dialogOpen: !this.state.dialogOpen
        })
    }
    handleACCChange = panel => (event, expanded) => {
        this.setState({ expanded: expanded ? panel : false })
    }
    goBack = () => {
        localStorage.setItem('referredFromDetailPage', JSON.stringify(true))
        this.props.history.goBack()
    }

    render() {
        const {
            loading,
            error,
            errorMessage,
            item,
            itemextra,
            shipmentdetails,
            expanded,
            organization,
            canEditOrderPlanning
        } = this.state

        const { classes } = this.props

        let contents = loading ? (
            <LinearProgress color="primary" />
        ) : (
            <>
                {error && (
                    <>
                        <Alert
                            variant="filled"
                            onClose={() => {
                                this.setState({ error: false })
                            }}
                            severity="warning"
                        >
                            {errorMessage}
                        </Alert>
                        <br />
                    </>
                )}
                <Grid container spacing={3} direction="row">
                    <div class="w-100 details-info-page">
                        <CardContent className="p-2">
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                className="d-flex align-items-stretch mobile-col-direction-reverse"
                            >
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    className="pb-3 "
                                >
                                    <span
                                        className="text-Curious-blue font-18 font-raleway-bold"
                                        style={{ cursor: 'pointer' }}
                                        onClick={this.goBack}
                                    >
                                        {' '}
                                        <ArrowBackIcon />
                                        Go Back...
                                    </span>
                                </Grid>
                            </Grid>
                            <div class="bg-white mb-20 ">
                                <Paper className="paper p-0">
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mobile-col-direction-reverse mobile-col-direction-reverse"
                                    >
                                        <Grid
                                            item
                                            lg={6}
                                            sm={12}
                                            xs={12}
                                            className="pb-3"
                                        >
                                            <div>
                                                {item != null && (
                                                    <OrderInfoCard
                                                        item={item}
                                                        organization={
                                                            organization
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                {item != null && (
                                                    <OrderDetailCard
                                                        item={item}
                                                        itemextra={itemextra}
                                                        shipmentdetails={
                                                            shipmentdetails
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            lg={6}
                                            sm={12}
                                            xs={12}
                                            className="d-flex align-items-stretch"
                                        >
                                            <Card
                                                className={[
                                                    bgColorSection,
                                                    'w-100'
                                                ]}
                                            >
                                                {renderDetailMap(
                                                    item?.orderNumber,
                                                    item,
                                                    itemextra,
                                                    organization,
                                                    '550px'
                                                )}
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div class="bg-white mb-20">
                                <Paper className="paper p-0">
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="row"
                                        className="pb-3"
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                            className="d-flex align-items-stretch"
                                        >
                                            {item != null && (
                                                <OrderSupplierInfoCard
                                                    item={item}
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            className="d-flex align-items-stretch"
                                        >
                                            {item != null && (
                                                <OrderDeliveryInfoCard
                                                    itemextra={itemextra}
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className="d-flex align-items-stretch"
                                        >
                                            {item != null && (
                                                <OrderShipmentDetailCard
                                                    item={item}
                                                    itemextra={itemextra}
                                                    clientId={
                                                        this.state.clientId
                                                    }
                                                    shipmentdetails={
                                                        this.state
                                                            .shipmentdetails
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div class="bg-white mb-20">
                                <Paper className="paper p-0">
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="row"
                                        className="pb-3"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className="d-flex align-items-stretch"
                                        >
                                            {itemextra &&
                                                !canEditOrderPlanning && (
                                                    <OrderPlanningCard
                                                        itemextra={itemextra}
                                                        item={item}
                                                        organization={
                                                            organization
                                                        }
                                                    />
                                                )}
                                            {itemextra &&
                                                canEditOrderPlanning && (
                                                    <OrderPlanningCardEdit
                                                        itemextra={itemextra}
                                                        item={item}
                                                        organization={
                                                            organization
                                                        }
                                                    />
                                                )}
                                        </Grid>
                                    </Grid>
                                    <div class="separator bg-primary"></div>
                                    <Grid container spacing={0} direction="row">
                                        <Grid item xs={12}>
                                            <div className="custom-psa-accordian">
                                                <div className="px-3 sm-px-0">
                                                    <ExpansionPanel
                                                        square
                                                        expanded={
                                                            expanded ===
                                                            'panel1'
                                                        }
                                                        onChange={this.handleACCChange(
                                                            'panel1'
                                                        )}
                                                    >
                                                        <ExpansionPanelSummary
                                                            expandIcon={
                                                                <ExpandMoreIcon />
                                                            }
                                                            className="p-0"
                                                        >
                                                            <Typography
                                                                checkOn={false}
                                                                disablePadding={
                                                                    true
                                                                }
                                                            >
                                                                <h4 className="text-Curious-blue font-14">
                                                                    Routing
                                                                </h4>
                                                            </Typography>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            <Typography>
                                                                <div className="psa-shipment-table  psa-shipment-table-info">
                                                                    {renderTable(
                                                                        itemextra?.routingInfo ||
                                                                            shipmentdetails?.routingInfo,
                                                                        routingInfo,
                                                                        'routing',
                                                                        classes,
                                                                        null,
                                                                        {
                                                                            maxHeight:
                                                                                '200px'
                                                                        }
                                                                    )}
                                                                </div>
                                                            </Typography>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div class="separator bg-primary"></div>
                                    <Grid container spacing={1} direction="row">
                                        <div className="custom-psa-accordian w-100 mx-3 sm-mx-0">
                                            <ExpansionPanel
                                                square
                                                expanded={expanded === 'panel1'}
                                                onChange={this.handleACCChange(
                                                    'panel1'
                                                )}
                                            >
                                                <ExpansionPanelSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                >
                                                    <Typography>
                                                        <h4 className="text-Curious-blue font-14">
                                                            Milestones & Order
                                                            Lines
                                                        </h4>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Typography>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="d-flex align-items-stretch no-mobile-dflex"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="sm-flex-100 display-table-row-group"
                                                                >
                                                                    <div class="font-12 text-black mb-2 ">
                                                                        Milestones
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info min-max-height-200px">
                                                                        <CardTitle
                                                                            title="Milestones"
                                                                            checkOn={
                                                                                false
                                                                            }
                                                                            disablePadding={
                                                                                true
                                                                            }
                                                                        >
                                                                            {renderTable(
                                                                                itemextra?.milestones,
                                                                                milestones,
                                                                                'milestones',
                                                                                classes,
                                                                                null,
                                                                                {
                                                                                    maxHeight:
                                                                                        '200px'
                                                                                }
                                                                            )}
                                                                        </CardTitle>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="sm-flex-100"
                                                                >
                                                                    <div class="font-12 text-black mb-2 ">
                                                                        Order
                                                                        Lines
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info  min-max-height-200px">
                                                                        <CardTitle
                                                                            title="Order Lines"
                                                                            checkOn={
                                                                                false
                                                                            }
                                                                            disablePadding={
                                                                                true
                                                                            }
                                                                        >
                                                                            {renderTable(
                                                                                itemextra?.lines,
                                                                                orderLines,
                                                                                'orderlines',
                                                                                classes,
                                                                                null,
                                                                                {
                                                                                    maxHeight:
                                                                                        '200px'
                                                                                }
                                                                            )}
                                                                        </CardTitle>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    </Grid>
                                    <div class="separator bg-primary"></div>
                                    <Grid container spacing={1} direction="row">
                                        <div className="custom-psa-accordian w-100 mx-3 sm-mx-0">
                                            <ExpansionPanel
                                                square
                                                expanded={expanded === 'panel1'}
                                                onChange={this.handleACCChange(
                                                    'panel1'
                                                )}
                                            >
                                                <ExpansionPanelSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                >
                                                    <Typography>
                                                        <h4 className="text-Curious-blue font-14">
                                                            Notes & Documents
                                                        </h4>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Typography>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="d-flex align-items-stretch no-mobile-dflex"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="sm-flex-100 display-table-row-group"
                                                                >
                                                                    <div class="font-12 text-black mb-2 ">
                                                                        Notes
                                                                    </div>

                                                                    <div className="psa-shipment-table  psa-shipment-table-info min-max-height-200px">
                                                                        <OrderNotesTable
                                                                            item={
                                                                                itemextra
                                                                            }
                                                                            isReview={
                                                                                false
                                                                            }
                                                                            classes={
                                                                                this
                                                                                    .props
                                                                                    .classes
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="sm-flex-100 display-table-row-group"
                                                                >
                                                                    <div class="font-12 text-black mb-2 ">
                                                                        Documents
                                                                        <DocumentUploadSelectDialog
                                                                            organization={
                                                                                organization
                                                                            }
                                                                            clientId={
                                                                                this
                                                                                    .state
                                                                                    .clientId
                                                                            }
                                                                            transactiontype="order"
                                                                            transactionnumber={
                                                                                this
                                                                                    .state
                                                                                    .orderNumber +
                                                                                '~' +
                                                                                this
                                                                                    .state
                                                                                    .orderNumberSplit
                                                                            }
                                                                            buyercode={
                                                                                item?.buyerCode
                                                                            }
                                                                            transportmode={
                                                                                item?.orderTransportMode
                                                                            }
                                                                        ></DocumentUploadSelectDialog>
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info  min-max-height-200px">
                                                                        <Suspense
                                                                            fallback={
                                                                                null
                                                                            }
                                                                        >
                                                                            <OrderDocumentsCard
                                                                                orderNumber={
                                                                                    this
                                                                                        .state
                                                                                        .orderNumber
                                                                                }
                                                                                orderNumberSplit={
                                                                                    this
                                                                                        .state
                                                                                        .orderNumberSplit
                                                                                }
                                                                                clientId={
                                                                                    this
                                                                                        .state
                                                                                        .clientId
                                                                                }
                                                                                classes={
                                                                                    this
                                                                                        .props
                                                                                        .classes
                                                                                }
                                                                                itemuri={
                                                                                    this
                                                                                        .state
                                                                                        .uri
                                                                                }
                                                                            />
                                                                        </Suspense>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        </CardContent>
                    </div>
                </Grid>
            </>
        )

        return <div>{contents}</div>
    }

    async populateOrderData() {
        this.fetchAllOrderData()
            .then(([order, orgConfig, clientData]) => {
                this.setState(
                    {
                        item: order,
                        organization: orgConfig,
                        shipmentdetails: order.shipmentDetails,
                        client: clientData
                    },
                    () => {
                        this.fetchOrderExtraData()
                            .then(([orderExtra]) => {
                                this.setState({
                                    itemextra: orderExtra,
                                    loading: false
                                })
                            })
                            .catch(error => {
                                this.setState({
                                    loading: false,
                                    error: true,
                                    errorMessage:
                                        'Unable to fetch order details from Cargowise One. Please try again later.'
                                })
                            })
                    }
                )
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    error: true,
                    errorMessage:
                        'Unable to fetch order details. Please try again later.'
                })
            })
    }

    async fetchAllOrderData() {
        const { orderNumber, orderNumberSplit, clientId, uri } = this.state
        const queryString = require('query-string')

        const qs = {
            ClientId: clientId,
            OrderSearchNumber: orderNumber,
            OrderSearchNumberSplit: orderNumberSplit
        }

        const token = await authService.getAccessToken()

        const [orderResponse, orgConfigResponse, clientResponse] =
            await Promise.all([
                fetch(`api/orders/${uri}?` + queryString.stringify(qs), {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` }
                }),
                fetch('api' + ApplicationPaths.AppInfo),
                fetch(`api/clients/` + clientId, {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` }
                })
            ])

        const order = await orderResponse.json()
        const orgConfig = await orgConfigResponse.json()
        const clientData = await clientResponse.json()

        return [order, orgConfig, clientData]
    }

    async fetchOrderExtraData() {
        const { orderNumber, orderNumberSplit, clientId, uri } = this.state
        const queryString = require('query-string')

        const qs = {
            ClientId: clientId,
            OrderSearchNumber: orderNumber,
            OrderSearchNumberSplit: orderNumberSplit
        }

        const token = await authService.getAccessToken()

        const [orderExtraResponse] = await Promise.all([
            fetch(`api/orders/${uri}extra?` + queryString.stringify(qs), {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            })
        ])

        const orderExtra = await orderExtraResponse.json()

        return [orderExtra]
    }
}
export default withStyles(styles, { withTheme: true })(OrderDetailPage)
