import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import authService from '../api-authorization/AuthorizeService'
import { LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'

export default function UserUploadDialog(props) {
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [isError, setIsError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [isSelected, setIsSelected] = React.useState(false)
    const { clientid, buttonTitle } = props

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setIsSelected(false)
        setSelectedFile(null)
        setOpen(false)
    }

    const handleSuccess = () => {
        setIsSuccess(true)
        // reload page
        setInterval(function () {
            setIsSuccess(false)
            setOpen(false)
        }, 4000)
        window.location.reload()
    }

    const handleError = errorMessage => {
        setErrorMessage(errorMessage)
        setIsError(true)
    }

    const changeHandler = event => {
        setSelectedFile(event.target.files[0])
        setIsSelected(true)
    }

    const handleSubmission = async () => {
        if (selectedFile === null) {
            setIsError(true)
            setErrorMessage('No file selected!')
        } else {
            setIsError(false)
            setIsSuccess(false)
            setErrorMessage('')
            setIsLoading(true)

            const formData = new FormData()
            formData.append('File', selectedFile)

            const token = await authService.getAccessToken()
            const header = {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*'
            }
            const queryParams = clientid ? `?clientid=${clientid}` : ''

            fetch('api/users/ImportUsers' + queryParams, {
                headers: header,
                method: 'POST',
                body: formData
            })
                .then(response => response.text())
                .then(result => {
                    console.log(result)
                    setIsLoading(false)
                    handleSuccess()
                })
                .catch(error => {
                    setIsLoading(false)
                    console.log(error)
                    handleError('User import failed, please retry.')
                })
        }
    }

    return (
        <>
            <IconButton color="inherit" size="small" onClick={handleClickOpen}>
                <ImportExportIcon />
                {buttonTitle}
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>User Import File Select</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText>
                        Accepted file format is .csv with headers - columns are
                        'FirstName', 'LastName', 'Email', 'ClientID (if not
                        importing against current client)'
                    </DialogContentText>
                </DialogContent>
                {isLoading && <LinearProgress />}
                <DialogActions>
                    <input type="file" name="file" onChange={changeHandler} />
                    {isSelected ? (
                        <div>
                            <p>Filename: {selectedFile.name}</p>
                            <p>Type: {selectedFile.type}</p>
                            <p>
                                Size:{' '}
                                {parseInt(Number(selectedFile.size) / 1000)}kb
                            </p>
                            <p>
                                Last Modified:{' '}
                                {selectedFile.lastModifiedDate.toLocaleDateString()}
                            </p>
                        </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )}
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmission} color="primary">
                        Import
                    </Button>
                </DialogActions>
                {isSuccess && (
                    <Alert severity="success">
                        Users successfully imported!
                    </Alert>
                )}
                {isError && (
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setIsError(false)
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMessage}
                    </Alert>
                )}
            </Dialog>
        </>
    )
}
