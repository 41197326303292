import React, { PureComponent } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { Card, CardMedia, CardActionArea, CardContent } from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CollapseBtn, CollapseIcon } from '@mui-treasury/layout'
import { ApplicationPaths } from './../api-authorization/ApiAuthorizationConstants'
import { Grid, Icon } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    select: {
        root: {
            backgroundColor: 'blue'
        },
        icon: {
            fill: 'white'
        },
        select: {
            color: 'white',
            backgroundColor: 'red'
        },
        selectMenu: {
            color: 'white'
        }
    }
})

const whiteLogo = '/whitelogo.png'

class NavBar extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedIndex: 0,
            organization: null,
            isLoading: true,
            orgLogoURL: null
        }
    }
    async componentDidMount() {
        await this.populateConfig()
        // try get logo url from local storage
        const orgLogoURL = localStorage.getItem('orgLogoURL')
        if (orgLogoURL) {
            this.setState({ orgLogoURL })
        }
        this.setInitialOrganization(this.props.organizations)
    }

    handleChange = event => {
        const { organizations } = this.props
        const index = event.target.value
        window.location.href = `https://${organizations[index].subdomain}`
    }

    goBack = () => {
        this.props.history.goBack()
    }

    setInitialOrganization = organizations => {
        var found = 0
        organizations.map((organization, index) => {
            if (
                window.location.hostname.indexOf(organization.subdomain) !== -1
            ) {
                found = index
            }
        })

        this.setState({ selectedIndex: found })
    }

    renderTitle = () => {
        const { location } = this.props
        const { pathname } = location
        const { isLoading } = this.state
        const forwardSlashCount = (pathname.match(/\//g) || []).length

        if (isLoading) {
            return 'Loading...'
        }

        if (pathname === '/account') {
            return 'Account'
        } else if (pathname === '/password') {
            return 'Account > Change Password'
        } else if (pathname === '/organizations') {
            return 'Organization Details'
        } else if (pathname === '/clients') {
            return 'Clients'
        } else if (pathname === '/admin/data_mapping') {
            return 'Data Mappings'
        } else if (pathname === '/admin/users/new') {
            return 'New Admin User'
        } else if (pathname === '/admin/users') {
            return 'Admin Users'
        } else if (pathname === '/clients/new') {
            return 'New Client'
        } else if (pathname === '/shipments/create') {
            return 'Create Booking > Details'
        } else if (pathname === '/shipments/packlines') {
            return 'Create Booking > Packlines'
        } else if (pathname === '/shipments/notes') {
            return 'Create Booking > Notes'
        } else if (pathname === '/shipments/containers') {
            return 'Create Booking > Containers'
        } else if (pathname === '/shipments/review') {
            return 'Create Booking > Review'
        } else if (pathname === '/shipments') {
            return 'Shipments'
        } else if (pathname === '/orders/create') {
            return 'Create Order > Details'
        } else if (pathname === '/orders/create/notes') {
            return 'Create Order > Notes'
        } else if (pathname === '/orders/create/lines') {
            return 'Create Order > Lines'
        } else if (pathname === '/orders/create/review') {
            return 'Create Order > Review'
        } else if (pathname.indexOf('/shipments/') === 0) {
            return 'Shipment Details'
        } else if (pathname.indexOf('/clients/') === 0) {
            return 'Client Details'
        } else if (pathname.indexOf('/userclients/') === 0) {
            return 'Client User Relationships'
        } else if (pathname.indexOf('/admin/users/') === 0) {
            return 'Admin User Details'
        } else if (pathname.indexOf('/admin/data_mapping/') === 0) {
            return 'Edit Data Mapping'
        } else if (pathname === '/admin/data_mapping/new') {
            return 'New Data Mapping'
        } else if (pathname.indexOf('/orders/') === 0) {
            return 'Order Details'
        } else if (pathname === '/orders') {
            return 'Orders'
        } else if (pathname === '/orderlines') {
            return 'Order Lines'
        } else if (pathname === '/bookings') {
            return 'Bookings'
        } else if (pathname.indexOf('/bookings/') === 0) {
            return 'Booking Details'
        } else if (pathname === '/containers') {
            return 'Containers'
        } else if (pathname === '/customs') {
            return 'Customs'
        } else if (pathname.indexOf('/customs/') === 0) {
            return 'Customs Details'
        } else if (pathname === '/accounting') {
            return 'Accounting'
        } else if (pathname === '/clientusers') {
            return 'All Client Users'
        } else if (pathname === '/powerbireports') {
            return 'Reports'
        } else if (pathname === '/') {
            return 'Dashboard'
        } else if (
            pathname.indexOf('/users/' === 0) &&
            forwardSlashCount === 3
        ) {
            return 'Client User Details'
        } else if (
            pathname.indexOf('/users/' === 0) &&
            forwardSlashCount === 2 &&
            pathname.length === 43
        ) {
            return 'Client Users'
        }

        return 'Loading...'
    }

    renderOrgDropdown = () => {
        const { organizations } = this.props
        const { selectedIndex } = this.state
        return organizations.length > 0 ? (
            <NavItem>
                <Select
                    value={selectedIndex}
                    onChange={this.handleChange}
                    classes={styles.select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {organizations.map((organization, index) => {
                        return (
                            <MenuItem key={index} value={index}>
                                {organization.name +
                                    ' - ' +
                                    organization.subdomain}
                            </MenuItem>
                        )
                    })}
                </Select>
            </NavItem>
        ) : null
    }

    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({
            organization: data,
            isLoading: false,
            orgLogoURL: data.organizationLogoUrl
        })
    }

    render() {
        const { backgroundColor, textColor, history } = this.props
        const { organization, orgLogoURL } = this.state

        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'

        const themeTextColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'

        const logoutPath = {
            pathname: `${ApplicationPaths.LogOut}`,
            state: {
                local: true
            }
        }

        return (
            <Grid item xs={12} className="px-4  bg-white navbar-postion-fixed">
                <Nav className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between min-width-246px">
                        <NavItem
                            className="d-flex align-items-center"
                            style={{ cursor: 'pointer' }}
                            onClick={
                                // go home
                                () => history.push('/')
                            }
                        >
                            <Card
                                className="pb-4 px-2"
                                style={{
                                    borderRadius: '0px',
                                    backgroundColor: backgroundColor
                                }}
                            >
                                <CardActionArea>
                                    <CardMedia
                                        className="text-center header-logo-img"
                                        id="org-details-logo"
                                        component="img"
                                        alt={
                                            organization?.name + ' logo' ||
                                            'Organization Logo'
                                        }
                                        image={
                                            organization?.organizationLogoUrl ||
                                            orgLogoURL ||
                                            whiteLogo
                                        }
                                    />
                                </CardActionArea>
                            </Card>
                        </NavItem>
                        <CollapseBtn className="custom-collapse-btn">
                            <img src="/toggle.png"></img>
                        </CollapseBtn>
                        <NavItem className="d-flex align-items-center">
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                disabled
                                to="#"
                            >
                                <h6
                                    className="mb-0"
                                    style={{
                                        color: textColor,
                                        paddingLeft: '15px'
                                    }}
                                >
                                    {this.renderTitle()}
                                </h6>
                            </NavLink>
                        </NavItem>
                    </div>
                    <div className="d-flex flex-col align-items-center header-custom-dropdown">
                        <div
                            style={{
                                background: primarycolor,
                                color: themeTextColor
                            }}
                            className="opacity-70 text-white"
                        >
                            {this.renderOrgDropdown()}
                        </div>
                        <NavItem className="d-flex align-items-center px-4">
                            <Icon
                                className="w-h-30 border-radius-50per d-flex justify-content-center align-items-center font-18"
                                style={{
                                    background: primarycolor,
                                    color: themeTextColor
                                }}
                            >
                                person
                            </Icon>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={logoutPath}
                            >
                                <h5 className="mb-0">
                                    {this.props.logoutButtonTitle || ''}
                                </h5>
                            </NavLink>
                        </NavItem>
                    </div>
                </Nav>
            </Grid>
        )
    }
}

NavBar.propTypes = {
    organizations: PropTypes.array
}
NavBar.defaultProps = {
    organizations: []
}

export default withStyles(styles)(withRouter(NavBar))
