import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import SearchIcon from '@material-ui/icons/Search'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { Paper } from '@material-ui/core'

export default function ClientSelector(props) {
    const { clients, selectedClientId, handleClientSelection } = props

    const [dialogOpen, setDialogOpen] = useState(false)
    const [filteredClients, setFilteredClients] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)

    // set checked state of clients based on selectedClients
    useEffect(() => {
        if (!selectedClientId) return
        const clientsCopy = [...clients]
        clientsCopy.forEach(client => {
            client.checked = selectedClientId === client.id
        })
        setSelectedClient(
            clients.find(client => client.id === selectedClientId)
        )
        setFilteredClients(clientsCopy)
    }, [selectedClientId])

    const handleClientSelectChange = event => {
        const id = event.target.id
        // only one client can be selected
        const clientsCopy = filteredClients.map(client => {
            if (client.id === id) {
                client.checked = true
            } else {
                client.checked = false
            }
            return client
        })
        setFilteredClients(clientsCopy)
    }

    return (
        <>
            <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={() => setDialogOpen(true)}
            >
                {selectedClient?.name}
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false)
                    // reset the filtered clients
                    setFilteredClients(clients)
                }}
            >
                <DialogContent>
                    {/* <DialogContentText>
                    {
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                                onClick={this.setAllClientsChecked}>
                                Select All
                            </Button>
                            &nbsp;
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<RemoveCircleIcon />}
                                onClick={this.setAllClientsUnchecked}>
                                Unselect All
                            </Button>
                        </>
                    }
                    </DialogContentText> */}
                    <div>
                        <br />
                        <TextField
                            style={{ width: '80%' }}
                            onChange={e => {
                                // need to filter the addresses based on the input
                                const value = e.target.value
                                const filteredClients = clients.filter(
                                    client => {
                                        return client.name
                                            .toLowerCase()
                                            .includes(value.toLowerCase())
                                    }
                                )
                                setFilteredClients(filteredClients)
                            }}
                            placeholder={'Enter client name..'}
                            // need to default focus on the text field
                            autoFocus={true}
                        />
                        <br />
                        {filteredClients?.map(client => (
                            <div
                                style={{
                                    height: '35px'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '16px',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        handleClientSelectChange({
                                            target: { id: client.id }
                                        })
                                    }}
                                >
                                    <Checkbox
                                        key={client.id}
                                        id={client.id}
                                        value={client.id}
                                        checked={client.checked}
                                    />
                                    {client.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<CancelIcon />}
                        onClick={() => {
                            setDialogOpen(false)
                        }}
                    >
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleIcon />}
                        onClick={async () => {
                            // get the selected client
                            const newSelectedClient = filteredClients.find(
                                client => client.checked
                            )
                            if (!newSelectedClient) {
                                alert('Please select a client')
                                return
                            }
                            setSelectedClient(newSelectedClient)
                            handleClientSelection(newSelectedClient.id)
                            setDialogOpen(false)
                        }}
                    >
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
