import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'
import authService from '../api-authorization/AuthorizeService'
import { CircularProgress } from '@material-ui/core'

export const BookingOrdersConsolidation = ({
    isOpen,
    handleClose,
    title,
    bookingOrders,
    primaryColor,
    primaryTextColor
}) => {
    const [bookingHeaderValues, setBookingHeaderValues] = React.useState({
        orderNumber: '',
        orderTransportMode: '',
        containerMode: '',
        incoTerms: '',
        serviceLevel: '',
        goodsDescription: '',
        packs: 0,
        packType: '',
        weight: 0,
        weightUOM: '',
        volume: 0,
        volumeUOM: '',
        origin: '',
        destination: '',
        dateDepartEst: '',
        dateArriveEst: '',
        dateDeliveredEst: ''
    })
    const [mergedOrderData, setMergedOrderData] = React.useState(null)
    const [fetchingMergedOrderData, setFetchingMergedOrderData] =
        React.useState(false)
    const [mergedOrderDataConflictsFound, setMergedOrderDataConflictsFound] =
        React.useState(false)
    const [mergedOrderDataConflictMessage, setMergedOrderDataConflictMessage] =
        React.useState('')

    // add use effect call to fetch consolidated order data
    useEffect(() => {
        const fetchConsolidatedOrderData = async () => {
            setMergedOrderDataConflictMessage('')
            setMergedOrderDataConflictsFound(false)
            setFetchingMergedOrderData(true)
            const token = await authService.getAccessToken()
            const response = await fetch(
                'api/orders/getconsolidatedorderdata',
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bookingOrders)
                }
            )

            if (response.ok) {
                var responseJson = await response.json()
                setMergedOrderData(responseJson)
            } else {
                var responseText = await response.text()
                setMergedOrderDataConflictsFound(true)
                setMergedOrderDataConflictMessage(responseText)
            }
            setFetchingMergedOrderData(false)
        }

        if (bookingOrders && bookingOrders.length > 0) {
            fetchConsolidatedOrderData().catch(error => {
                alert(
                    'Failed to retrieve consolidated order data, please contact support. ' +
                        error
                )
                setFetchingMergedOrderData(false)
            })
        }
    }, [bookingOrders])

    // add use effect call to set booking header values to the first order in the booking orders array
    useEffect(() => {
        if (bookingOrders && bookingOrders.length > 0) {
            // need to retrieve consolidated order data from api
            setBookingHeaderValues({
                orderNumber: bookingOrders
                    .map(order => order.orderNumber)
                    .join(', '), // need to join order numbers together
                orderTransportMode:
                    (
                        bookingOrders.find(order => order.orderTransportMode) ||
                        {}
                    ).orderTransportMode || '',
                containerMode:
                    (
                        bookingOrders.find(
                            order => order.containerMode !== null
                        ) || {}
                    ).containerMode || '',
                incoTerms:
                    (
                        bookingOrders.find(order => order.incoTerms !== null) ||
                        {}
                    ).incoTerms || '',
                serviceLevel:
                    (
                        bookingOrders.find(
                            order => order.serviceLevel !== null
                        ) || {}
                    ).serviceLevel || '',
                goodsDescription: bookingOrders
                    .reduce(
                        (unique, item) =>
                            unique.includes(item.goodsDescription) ||
                            !item.goodsDescription
                                ? unique
                                : [...unique, item.goodsDescription],
                        []
                    )
                    .join(', '),
                packs: mergedOrderData.outerPacks,
                packType: mergedOrderData.outerPacksType,
                weight: mergedOrderData.weight,
                weightUOM: mergedOrderData.weightUOM,
                volume: mergedOrderData.volume,
                volumeUOM: mergedOrderData.volumeUOM,
                origin:
                    (bookingOrders.find(order => order.origin !== null) || {})
                        .origin || '',
                destination:
                    (
                        bookingOrders.find(
                            order => order.destination !== null
                        ) || {}
                    ).destination || '',
                dateDepartEst:
                    (
                        bookingOrders.find(
                            order => order.dateDepartEst !== null
                        ) || {}
                    ).dateDepartEst || '',
                dateArriveEst:
                    (
                        bookingOrders.find(
                            order => order.dateArriveEst !== null
                        ) || {}
                    ).dateArriveEst || '',
                dateDeliveredEst:
                    (
                        bookingOrders.find(
                            order => order.dateDeliveredEst !== null
                        ) || {}
                    ).dateDeliveredEst || '',
                supplierCode:
                    (
                        bookingOrders.find(
                            order => order.supplierCode !== null
                        ) || {}
                    ).supplierCode || '',
                buyerCode:
                    (
                        bookingOrders.find(order => order.buyerCode !== null) ||
                        {}
                    ).buyerCode || '',
                orderNumbersArray: bookingOrders.map(order => {
                    return {
                        orderNumber: order.orderNumber,
                        orderNumberSplit: parseInt(order.orderNumberSplit),
                        buyerCode: order.buyerCode
                    }
                })
            })
        }
    }, [mergedOrderData])

    const calculateSum = (array, property) => {
        const total = array.reduce((accumulator, object) => {
            return accumulator + object[property]
        }, 0)

        return total
    }

    const handleCreateBooking = () => {
        localStorage.setItem(
            'newBookingOrderNumber',
            bookingOrders[0].orderNumber
        )
        localStorage.setItem(
            'newBookingOrderNumberSplit',
            bookingOrders[0].orderNumberSplit
        )
        localStorage.setItem(
            'consolidatedOrderData',
            JSON.stringify(bookingHeaderValues)
        )
        localStorage.setItem('bookingOrdersData', JSON.stringify(bookingOrders))

        const currentClientCode = localStorage.getItem('newBookingClientCode')
        // check if code matches either order buyer or supplier code, if not then set newBookingClientCode to supplier code
        if (
            bookingOrders.find(
                order => order.buyerCode === currentClientCode
            ) ||
            bookingOrders.find(
                order => order.supplierCode === currentClientCode
            )
        ) {
            // carry on
        } else {
            localStorage.setItem(
                'newBookingClientCode',
                bookingOrders[0].supplierCode
            )
        }

        // take user to create booking page
        window.location.href = '/shipments/create'
    }

    const handleSetBookingDestination = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, destination: value })
    }

    const handleSetBookingOrigin = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, origin: value })
    }

    const handleSetBookingTransportMode = (e, value) => {
        setBookingHeaderValues({
            ...bookingHeaderValues,
            orderTransportMode: value
        })
    }

    const handleSetBookingContainerType = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, containerMode: value })
    }

    const handleSetBookingIncoTerms = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, incoTerms: value })
    }

    const handleSetBookingServiceLevel = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, serviceLevel: value })
    }

    const handleSetBookingGoodsDescription = (e, value) => {
        setBookingHeaderValues({
            ...bookingHeaderValues,
            goodsDescription: value
        })
    }

    const handleSetBookingTotalPieces = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, packs: value })
    }

    const handleSetBookingPieceUOM = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, packType: value })
    }

    const handleSetBookingTotalWeight = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, weight: value })
    }

    const handleSetBookingWeightUOM = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, weightUOM: value })
    }

    const handleSetBookingTotalVolume = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, volume: value })
    }

    const handleSetBookingVolumeUOM = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, volumeUOM: value })
    }

    const handleSetBookingRequiredETD = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, dateDepartEst: value })
    }

    const handleSetBookingRequiredETA = (e, value) => {
        setBookingHeaderValues({ ...bookingHeaderValues, dateArriveEst: value })
    }

    const handleSetBookingRequestedDeliveryDate = (e, value) => {
        setBookingHeaderValues({
            ...bookingHeaderValues,
            dateDeliveredEst: value
        })
    }

    const uniqueOrigins = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.origin) || !item.origin
                ? unique
                : [...unique, item.origin],
        []
    )
    const uniqueDestinations = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.destination) || !item.destination
                ? unique
                : [...unique, item.destination],
        []
    )
    const uniqueTransportModes = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.orderTransportMode) || !item.orderTransportMode
                ? unique
                : [...unique, item.orderTransportMode],
        []
    )
    const uniqueContainerTypes = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.containerMode) || !item.containerMode
                ? unique
                : [...unique, item.containerMode],
        []
    )
    const uniqueIncoTerms = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.incoTerms) || !item.incoTerms
                ? unique
                : [...unique, item.incoTerms],
        []
    )
    const uniqueServiceLevels = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.serviceLevel) || !item.serviceLevel
                ? unique
                : [...unique, item.serviceLevel],
        []
    )
    const uniquePieceUOMs = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.packType) || !item.packType
                ? unique
                : [...unique, item.packType],
        []
    )
    const uniqueWeightUOMs = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.weightUOM) || !item.weightUOM
                ? unique
                : [...unique, item.weightUOM],
        []
    )
    const uniqueVolumeUOMs = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.volumeUOM) || !item.volumeUOM
                ? unique
                : [...unique, item.volumeUOM],
        []
    )
    const uniqueRequiredETDs = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.dateDepartEst) || !item.dateDepartEst
                ? unique
                : [...unique, item.dateDepartEst],
        []
    )
    const uniqueRequiredETAs = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.dateArriveEst) || !item.dateArriveEst
                ? unique
                : [...unique, item.dateArriveEst],
        []
    )
    const uniqueRequestedDeliveryDates = bookingOrders.reduce(
        (unique, item) =>
            unique.includes(item.dateDeliveredEst) || !item.dateDeliveredEst
                ? unique
                : [...unique, item.dateDeliveredEst],
        []
    )

    let bookingHeaderConflictsFound = false

    if (
        uniqueOrigins.length > 1 ||
        uniqueDestinations.length > 1 ||
        uniqueTransportModes.length > 1 ||
        uniqueContainerTypes.length > 1 ||
        uniqueIncoTerms.length > 1 ||
        uniqueServiceLevels.length > 1 ||
        uniquePieceUOMs.length > 1 ||
        uniqueWeightUOMs.length > 1 ||
        uniqueVolumeUOMs.length > 1 ||
        uniqueRequiredETDs.length > 1 ||
        uniqueRequiredETAs.length > 1 ||
        uniqueRequestedDeliveryDates.length > 1
    ) {
        bookingHeaderConflictsFound = true
    }

    const renderSelectionGroup = (
        title,
        valueArray,
        valueKey,
        handleValueChange,
        defaultValue
    ) => {
        return (
            <>
                <div
                    class="separator"
                    style={{
                        background: primaryColor
                    }}
                ></div>
                <br />
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: primaryColor
                    }}
                >
                    {title}
                </h4>
                <ToggleButtonGroup
                    value={defaultValue}
                    exclusive
                    onChange={handleValueChange}
                >
                    {valueArray.map((value, i) => (
                        <ToggleButton
                            style={{
                                backgroundColor:
                                    value === bookingHeaderValues[valueKey]
                                        ? primaryColor
                                        : null,
                                color:
                                    value === bookingHeaderValues[valueKey]
                                        ? primaryTextColor
                                        : null
                            }}
                            value={value}
                            aria-label={title}
                            key={i}
                        >
                            {value}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </>
        )
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle
                id="max-width-dialog-title"
                style={{
                    textAlign: 'center',
                    color: primaryTextColor,
                    backgroundColor: primaryColor
                }}
            >
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {mergedOrderDataConflictsFound && (
                        <div class="modaldialog-content">
                            <h3>
                                There are conflicts in the consolidated order
                                data. Please update the orders.
                            </h3>
                            <br />
                            <div
                                class="separator"
                                style={{
                                    background: primaryColor
                                }}
                            ></div>
                            <br />
                            <h5 style={{ color: primaryColor }}>
                                {mergedOrderDataConflictMessage}
                            </h5>
                        </div>
                    )}
                    {!bookingHeaderConflictsFound &&
                        !mergedOrderDataConflictsFound &&
                        !fetchingMergedOrderData && (
                            <div class="modaldialog-content">
                                <h3>
                                    No conflicts found in consolidated order
                                    data - click 'Create Booking' to proceed.
                                </h3>
                            </div>
                        )}
                    {bookingHeaderConflictsFound && (
                        <div class="modaldialog-content">
                            <h3>
                                There are conflicts in the booking header
                                values. Please select the correct values for
                                each field.
                            </h3>
                        </div>
                    )}
                </DialogContentText>
                {
                    // if addresses is empty then display 'no results'
                    (!bookingOrders || bookingOrders.length === 0) && (
                        <div class="modaldialog-content">
                            <h4>No orders found!</h4>
                        </div>
                    )
                }
                {uniqueTransportModes.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Transport Mode',
                          uniqueTransportModes,
                          'orderTransportMode',
                          handleSetBookingTransportMode,
                          bookingHeaderValues.orderTransportMode
                      )}
                {uniqueContainerTypes.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Container Type',
                          uniqueContainerTypes,
                          'containerMode',
                          handleSetBookingContainerType,
                          bookingHeaderValues.containerMode
                      )}
                {uniqueIncoTerms.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Inco Terms',
                          uniqueIncoTerms,
                          'incoTerms',
                          handleSetBookingIncoTerms,
                          bookingHeaderValues.incoTerms
                      )}
                {uniqueServiceLevels.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Service Level',
                          uniqueServiceLevels,
                          'serviceLevel',
                          handleSetBookingServiceLevel,
                          bookingHeaderValues.serviceLevel
                      )}
                {uniquePieceUOMs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Total Pieces',
                          [bookingHeaderValues.totalPieces],
                          'totalPieces',
                          handleSetBookingTotalPieces,
                          bookingHeaderValues.totalPieces
                      )}
                {uniquePieceUOMs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Piece UOM',
                          uniquePieceUOMs,
                          'pieceUOM',
                          handleSetBookingPieceUOM,
                          bookingHeaderValues.pieceUOM
                      )}
                {uniqueWeightUOMs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Total Weight',
                          [bookingHeaderValues.totalWeight],
                          'totalWeight',
                          handleSetBookingTotalWeight,
                          bookingHeaderValues.totalWeight
                      )}
                {uniqueWeightUOMs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Weight UOM',
                          uniqueWeightUOMs,
                          'weightUOM',
                          handleSetBookingWeightUOM,
                          bookingHeaderValues.weightUOM
                      )}
                {uniqueVolumeUOMs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Total Volume',
                          [bookingHeaderValues.totalVolume],
                          'totalVolume',
                          handleSetBookingTotalVolume,
                          bookingHeaderValues.totalVolume
                      )}
                {uniqueVolumeUOMs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Volume UOM',
                          uniqueVolumeUOMs,
                          'volumeUOM',
                          handleSetBookingVolumeUOM,
                          bookingHeaderValues.volumeUOM
                      )}
                {uniqueOrigins.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Origin',
                          uniqueOrigins,
                          'origin',
                          handleSetBookingOrigin,
                          bookingHeaderValues.origin
                      )}
                {uniqueDestinations.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Destination',
                          uniqueDestinations,
                          'destination',
                          handleSetBookingDestination,
                          bookingHeaderValues.destination
                      )}
                {uniqueRequiredETDs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Required ETD',
                          uniqueRequiredETDs,
                          'dateDepartEst',
                          handleSetBookingRequiredETD,
                          bookingHeaderValues.dateDepartEst
                      )}
                {uniqueRequiredETAs.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Required ETA',
                          uniqueRequiredETAs,
                          'dateArriveEst',
                          handleSetBookingRequiredETA,
                          bookingHeaderValues.dateArriveEst
                      )}
                {uniqueRequestedDeliveryDates.length <= 1
                    ? null
                    : renderSelectionGroup(
                          'Requested Delivery Date',
                          uniqueRequestedDeliveryDates,
                          'dateDeliveredEst',
                          handleSetBookingRequestedDeliveryDate,
                          bookingHeaderValues.dateDeliveredEst
                      )}
            </DialogContent>
            <DialogActions>
                {fetchingMergedOrderData && <CircularProgress />}
                {!fetchingMergedOrderData && (
                    <>
                        <Button
                            disabled={fetchingMergedOrderData}
                            onClick={handleClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        {!mergedOrderDataConflictsFound && (
                            <Button
                                disabled={fetchingMergedOrderData}
                                onClick={handleCreateBooking}
                                color="primary"
                            >
                                Create Booking
                            </Button>
                        )}
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}

BookingOrdersConsolidation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    bookingOrders: PropTypes.array.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryTextColor: PropTypes.string.isRequired
}
