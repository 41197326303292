import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Grid, Typography, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import CardTitle from './shared/CardTitle'
import { makeStyles } from '@material-ui/core/styles'
import { renderTable } from '../components/shared/TableHelper'
import { renderDetailMap } from '../components/shared/MapHelper'
import { retrieveView } from './shared/Views'
import { Link, Button } from '@material-ui/core'
import ShipmentRoutingCard from './shared/ShipmentRoutingCard'
import { getContainerOrderLines } from '../api/api'
import { LinearProgress } from '@material-ui/core'
import { getOrgViewCodeFromOrganization } from '../utils/organizations'

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
}))

const milestones = [
    { key: 'description', label: 'Description', icon: true },
    { key: 'estimatedDate', label: 'Estimate', icon: false },
    { key: 'actualDate', label: 'Actual', icon: false }
]

export default function DialogContainer(props) {
    const [dialogContainerHeaders, setDialogContainerHeaders] = useState([])
    const [containerOrderLines, setContainerOrderLines] = useState([])
    const [productsLoading, setProductsLoading] = useState(false)
    const [packedItemHeaders, setPackedItemHeaders] = useState([])
    const [orgViewCode, setOrgViewCode] = useState(null)

    const {
        container,
        organization,
        client,
        shipment = null,
        shipmentExtra = null,
        showDialog,
        dialogCallback
    } = props
    const classes = useStyles()

    useEffect(() => {
        getContainerDialogContainerHeaders()
        getContainerPackedItemHeaders()
        getOrgViewCode()
    }, [])

    useEffect(() => {
        if (container && shipment) {
            setProductsLoading(true)
            getContainerOrderLines(
                container.containerNumber,
                shipment.shipmentNumber,
                client.id,
                handleContainerOrderLinesSuccess,
                handleContainerOrderLineFailure
            )
        }
    }, [container, shipment])

    const getContainerPackedItemHeaders = async () => {
        const view = await retrieveView(null, 'ContainerPackedItems')
        if (view) {
            setPackedItemHeaders(view.columnDefinitionsArray)
        }
    }

    const getOrgViewCode = async () => {
        const viewCode = await getOrgViewCodeFromOrganization()
        setOrgViewCode(viewCode)
    }

    const handleContainerOrderLinesSuccess = data => {
        if (data) {
            setContainerOrderLines(data)
        } else {
            setContainerOrderLines(container.packedItems)
        }
        setProductsLoading(false)
    }

    const handleContainerOrderLineFailure = () => {
        setProductsLoading(false)
    }

    const getContainerDialogContainerHeaders = async () => {
        const view = await retrieveView(null, 'ShipmentContainerDetails')
        if (view) {
            let containerHeaders = view.columnDefinitionsArray
            // need to map value and title properties to key and label and add icon and link properties
            containerHeaders.forEach(header => {
                header.key = header.value
                header.label = header.title
                header.icon = false
                header.link = false
            })
            // filter out headers where visible property is false
            containerHeaders = containerHeaders.filter(header => header.visible)
            // remove containerTrackingLink column if organization does not have containerTrackingURL
            if (!organization?.containerTrackingURL) {
                containerHeaders = containerHeaders.filter(
                    c => c.key !== 'containerTrackingLink'
                )
            }
            // set link true if key is containerTrackingLink
            containerHeaders.forEach(header => {
                if (header.key === 'containerTrackingLink') {
                    header.link = true
                }
            })
            setDialogContainerHeaders(containerHeaders)
        }
    }

    if (!showDialog) return null

    const renderContainerLink = (i, index, arrayKey) => {
        if (
            arrayKey.key === 'containerTrackingLink' &&
            organization.containerTrackingURL
        ) {
            return (
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        const newWindow = window.open(
                            organization.containerTrackingURL.replace(
                                '{CONTAINERNO}',
                                i['containerNumber']
                            ),
                            '_blank',
                            'noopener,noreferrer'
                        )
                        if (newWindow) newWindow.opener = null
                    }}
                >
                    View
                </Button>
            )
        }

        if (
            arrayKey.key === 'openTrackLink' &&
            (client.isOpenTrack || organization.isOpenTrack)
        ) {
            return (
                // check if value is null
                i[arrayKey.key] !== null && i[arrayKey.key] !== '' ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            const newWindow = window.open(
                                i[arrayKey.key],
                                '_blank',
                                'noopener,noreferrer'
                            )
                            if (newWindow) newWindow.opener = null
                        }}
                    >
                        View
                    </Button>
                ) : (
                    <Button color="primary" variant="outlined" disabled>
                        View
                    </Button>
                )
            )
        }
    }

    // const getContainerPackedItems = () => {
    //     return container.packedItems || containerOrderLines
    // }

    const renderPackedItems = () => {
        const filteredPackedItemHeaders = packedItemHeaders.map(header => ({
            key: header.value,
            label: header.title,
            icon: false
        }))

        return (
            <CardTitle
                title="Packed Items"
                checkOn={false}
                disablePadding={true}
            >
                {productsLoading ? (
                    <LinearProgress />
                ) : (
                    renderTable(
                        containerOrderLines,
                        filteredPackedItemHeaders,
                        'packedItems',
                        classes,
                        null,
                        {
                            maxHeight: '350px'
                        }
                    )
                )}
            </CardTitle>
        )
    }

    const renderMilestones = container => {
        return (
            <CardTitle title="Milestones" checkOn={false} disablePadding={true}>
                {renderTable(
                    container.milestones,
                    milestones,
                    'milestones',
                    classes,
                    null,
                    {
                        maxHeight: '350px'
                    }
                )}
            </CardTitle>
        )
    }

    const renderDetails = container => {
        return (
            <CardTitle title="Details" checkOn={false} disablePadding={true}>
                {renderTable(
                    [container],
                    dialogContainerHeaders,
                    'Details',
                    classes,
                    renderContainerLink,
                    {
                        maxHeight: '200px'
                    }
                )}
            </CardTitle>
        )
    }

    return (
        <Dialog
            open={showDialog}
            fullScreen
            className="px-5 py-3"
            onClose={() => dialogCallback(false)}
        >
            <DialogTitle className={classes.title} disableTypography>
                <div className="row text-left p-0">
                    <IconButton onClick={() => dialogCallback(false)}>
                        <Close color="secondary" />
                    </IconButton>
                </div>
                <div className="row text-center">
                    <Typography variant="h6" className="w-100">
                        {' '}
                        Container Details | {container?.containerNumber}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {renderDetailMap(
                            container?.containerNumber,
                            container,
                            shipmentExtra,
                            null,
                            '400px',
                            'dialog'
                        )}
                    </Grid>
                    {(container?.routingInfo || shipmentExtra?.routingInfo) && (
                        <Grid item xs={12}>
                            <ShipmentRoutingCard
                                shipment={shipmentExtra ?? container}
                                clientId={client.id}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {renderDetails(container)}
                    </Grid>
                    <Grid item xs={orgViewCode === 961 ? 12 : 6}>
                        {renderPackedItems(container)}
                    </Grid>
                    <Grid item xs={orgViewCode === 961 ? 12 : 6}>
                        {renderMilestones(container)}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
