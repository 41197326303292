// new react function component for port autocomplete
import React, { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

export default function PortAutocomplete({
    data,
    value,
    label,
    labelRender = () => {},
    selectChangeCallback = () => {},
    textChangeCallback = () => {},
    disabled = false
}) {
    return (
        <Autocomplete
            style={{ width: '100%' }}
            value={value}
            getOptionSelected={(option, value) => {
                return option.unloco === value.unloco
            }}
            onChange={selectChangeCallback}
            getOptionLabel={labelRender}
            options={data}
            loading={false}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    style={{ width: '100%' }}
                    onChange={textChangeCallback}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps
                    }}
                />
            )}
        />
    )
}
