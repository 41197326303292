import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import authService from '../api-authorization/AuthorizeService'
import { LinearProgress, MenuItem } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { downloadDocumentPDFForShipment } from '../../api/api'
import GetAppIcon from '@material-ui/icons/GetApp'
import { TextField } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    docTypeSelect: {},
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    inline: {
        display: 'inline'
    }
}))

export default function TransactionCommentsHeaderCard(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [doctype, setDocType] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [isError, setIsError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [newComment, setNewComment] = React.useState('')

    // need to expand this to work for orders as well
    const transactionNumber =
        props.transactiontype === 'shipment' ||
        props.transactiontype === 'booking' ||
        props.transactiontype === 'customs'
            ? props.transactionnumber
            : null

    const getTransactionTypeByName = name => {
        switch (name) {
            case 'shipment':
                return 1
            case 'booking':
                return 2
            case 'customs':
                return 0
            case 'order':
                return 3
        }
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSuccess = () => {
        setIsSuccess(true)
        // reload page
        setInterval(function () {
            setIsSuccess(false)
            setOpen(false)
        }, 3000)
        window.location.reload()
    }

    const handleError = errorMessage => {
        setErrorMessage(errorMessage)
        setIsError(true)
    }

    const handleSubmission = async () => {
        if (newComment === '') {
            setIsError(true)
            setErrorMessage('No comment entered!')
        } else {
            setIsError(false)
            setIsSuccess(false)
            setErrorMessage('')
            setIsLoading(true)

            const requestBody = {
                transactionNumber: transactionNumber,
                transactionType: getTransactionTypeByName(
                    props.transactiontype
                ),
                comment: newComment,
                clientId: props.clientId,
                transportMode: props.transportMode
            }

            const token = await authService.getAccessToken()
            const header = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            const response = await fetch('api/Comments/AddComment', {
                method: 'POST',
                headers: header,
                body: JSON.stringify(requestBody)
            })

            if (response.status === 200) {
                handleSuccess()
            } else if (response.status === 401) {
                handleError('You do not have permission to add a comment!')
            } else {
                handleError(
                    'An error occurred while adding a comment - please contact support.'
                )
            }

            setIsLoading(false)
        }
    }

    return (
        <>
            <IconButton color="inherit" size="small" onClick={handleClickOpen}>
                <AddCircleIcon />
                Add
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Add New Comment |{' '}
                    {props.transactiontype.substring(0, 1).toUpperCase() +
                        props.transactiontype.substring(1)}{' '}
                    {transactionNumber}
                </DialogTitle>
                <DialogContent>
                    <br />
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Comment"
                                multiline
                                minRows={4}
                                variant="outlined"
                                value={newComment}
                                onChange={e => setNewComment(e.target.value)}
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                {isLoading && <LinearProgress />}
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmission} color="primary">
                        Submit Comment
                    </Button>
                </DialogActions>
                {isSuccess && (
                    <Alert severity="success">
                        Comment Successfully Added!
                    </Alert>
                )}
                {isError && (
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setIsError(false)
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMessage}
                    </Alert>
                )}
            </Dialog>
        </>
    )
}
