import React from 'react'
import { Grid } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import IconButton from '@material-ui/core/IconButton'

export default function ShipmentCreationProgress({
    client,
    index,
    hideContainers = false
}) {
    const clientBookingSteps = client?.bookingSteps ? JSON.parse(client.bookingSteps) : []

    const renderStepButton = (step, checked, current) => {
        return (
            <IconButton
                color="primary"
                onClick={() => (window.location.href = step.href)}
            >
                {current ? (
                    <RadioButtonCheckedIcon />
                ) : checked ? (
                    <CheckCircleIcon />
                ) : (
                    <RadioButtonUncheckedIcon />
                )}
            </IconButton>
        )
    }

    const renderStep = (step, i) => {
        if (hideContainers && step.index === 3) {
            return null
        }

        if (step.enabled === true) {
            return (
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    item
                    xs={2}
                    direction="column"
                >
                    <Grid item xs className="mb-2">
                        {step.index < index &&
                            renderStepButton(step, true, false)}
                        {step.index > index &&
                            renderStepButton(step, false, false)}
                        {step.index === index &&
                            renderStepButton(step, false, true)}
                    </Grid>
                    <Grid item xs>
                        {step.name}
                    </Grid>
                </Grid>
            )
        } else {
            return null
        }
    }

    return (
        <div className="py-4">
            <Grid justify="center" container className="w-100" spacing={3}>
                {clientBookingSteps.map((step, i) => {
                    return renderStep(step, i)
                })}
            </Grid>
        </div>
    )
}
