import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardTitle from './CardTitle'
import { Tooltip, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants'

const classes = makeStyles(theme => ({}))

export default function OrderShipmentDetailCard({
    item,
    itemextra,
    clientId,
    shipmentdetails
}) {
    const theme = useTheme()

    useEffect(() => {}, [])

    return (
        <div className="p-0 pl-3 data-data-info w-100">
            <h5 class="text-Curious-blue my-2 font-14" checkOn={false}>
                Shipment Info
            </h5>

            <Grid
                container
                direction="row"
                spacing={1}
                className={theme.bgColorSection}
            >
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={3} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Shipment #
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item && (
                                            <NavLink
                                                to={
                                                    ApplicationPaths.Shipments +
                                                    '/' +
                                                    clientId +
                                                    '/' +
                                                    item.shipmentNumber
                                                }
                                            >
                                                {item.shipmentNumber}
                                            </NavLink>
                                        )) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Housebill #
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(itemextra &&
                                            itemextra.housebillNumber) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Masterbill #
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(shipmentdetails &&
                                            shipmentdetails.masterBillNumber) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    ETD
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(shipmentdetails && shipmentdetails.etd) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    ATD
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(shipmentdetails && shipmentdetails.atd) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    ETA
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(shipmentdetails && shipmentdetails.eta) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    ATA
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(shipmentdetails && shipmentdetails.ata) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
