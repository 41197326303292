import React, { PureComponent, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { TextField, Tooltip, LinearProgress } from '@material-ui/core'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { MenuItem, Select, Grid, Button } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Alert from '@material-ui/lab/Alert'
import { renderMap } from '../components/shared/MapHelper'
import { getIconForShipment } from '../components/shared/MapHelper'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import { CardContent } from '@material-ui/core'
import SummaryInfo from './shared/SummaryInfo'
import MaterialTable from 'material-table'
import { Paper } from '@material-ui/core'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import localforage, { key } from 'localforage'
import { sortData } from '../utils/sorting'
import { BookingOrdersConsolidation } from './shared/BookingOrdersConsolidation'
import { ThemeProvider, createTheme } from '@mui/material'
import TableViewManagementDialog from './shared/TableViewManagementDialog'
import {
    retrieveView,
    getViewCodeBySearchItem,
    getViewNameBySearchItem,
    saveViewColumns
} from './shared/Views'
import TableViewCreateDialog from './shared/TableViewCreateDialog'
import { prepareCsvExport } from '../utils/exporting'
import ClientSelector from '../components/shared/ClientSelector'
import ScheduledReportsManagementDialog from './shared/ScheduledReportsManagementDialog'
import ScheduleIcon from '@material-ui/icons/Schedule'

const styles = theme => ({
    formControl: {
        margin: 0,
        minWidth: '170px',
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
    },
    link: {
        color: theme.palette.primary.contrastText
    }
})

const mapContainerStyle = {
    height: '100%',
    width: '100%'
}

const dateFns = new DateFnsUtils()

class OrdersTable extends PureComponent {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
        this.state = {
            items: [],
            orderList: [],
            clients: [],
            clientId: null,
            organization: null,
            selectedDate: 2, // this month
            toDate: dateFns.moment().endOf('month'),
            fromDate: dateFns.moment().startOf('month'),
            minDate: dateFns.moment().startOf('month'),
            todayDate: new Date(),
            loading: false,
            error: false,
            rowsPerPage: 50,
            page: 0,
            order: 'asc',
            orderBy: 'orderNumber',
            resultsBox1Value: 0,
            resultsBox1Title: '',
            resultsBox2Value: 0,
            resultsBox2Title: '',
            resultsBox3Value: 0,
            resultsBox3Title: '',
            resultsBox4Value: 0,
            resultsBox4Title: '',
            resultsBox5Value: 0,
            resultsBox5Title: '',
            resultsBox6Title: '',
            resultsBox6Value: 0,
            orderStats: [],
            lastCompletedMilestone: 'ALL',
            referenceType: 'ALL',
            dateView: 'ALL',
            itemReference: null,
            mode: 'ALL',
            theme: props.theme,
            searchItem: props.searchItem || 'Orders',
            key: props.itemKey || 'order',
            downloadCSVDisabled: false,
            displayAdvancedSearchProperties: false,
            searching: false,
            orderSearchTypes: [],
            orderFilters: [],
            bookingOrderValidationModalOpen: false,
            bookingOrders: [],
            itemReferenceError: false,
            itemReferenceErrorMessage: '',
            orgHeaderColumns: [],
            dateFiltering: [],
            userViewId: null,
            primaryColor: '#ffffff',
            themeTextColor: '#000000',
            currentSummaryTile: 1,
            createBookingClients: [],
            createBookingButtonDisabled: false,
            selectedDateHelperText: '',
            showScheduledReports: false,
            newReportData: null,
            canSeeScheduledReports: false
        }
    }

    async componentDidMount() {
        this.setState({ searching: true })

        await this.populateConfig()
        await this.setClientsWithCreateBookingAccess()
        await this.populateClientsData()
    }

    async runClientDependentFunctions() {
        const referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )
        this.fetchOrderSearchTypes()
        this.initSearch()
        this.getOrgHeaderColumns()

        if (!referredFromDetailPage) {
            // need to do a dummy data load to get the summary tile data
            this.populateTableData(true, true)
        }

        await this.setCreateBookingAccess()
        this.setState({ searching: false })
    }

    handleNewScheduledReport = () => {
        // first get report data from search params then open dialog
        const newReportData = {
            module: this.state.searchItem,
            key: this.state.key,
            clientId: this.state.clientId,
            clientName: this.state.clients.find(
                c => c.id === this.state.clientId
            ).name,
            transportMode: this.state.mode,
            referenceType: this.state.referenceType,
            referenceTypeDescription: this.state.orderSearchTypes.find(
                gst => gst.searchType === this.state.referenceType
            ).searchTypeDescription,
            itemReference: this.state.itemReference,
            dateView: this.state.dateView,
            selectedDate: this.state.selectedDate,
            dateRange: this.state.dateFiltering.find(
                df => df.id === this.state.selectedDate
            ).label,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            reportQueryString: this.getQueryString(true, false),
            relatedModule: 0
        }
        this.setState({ newReportData: newReportData }, () => {
            this.setState({ showScheduledReports: true })
        })
    }

    async initSearch() {
        // only load search from cache if navigating back from detail page
        var referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )

        if (referredFromDetailPage) {
            await this.loadAndSetFromLocalForageNoKey('orderFilters')
            await this.loadAndSetFromLocalStorage('resultsBox1Value')
            await this.loadAndSetFromLocalStorage('resultsBox1Title')
            await this.loadAndSetFromLocalStorage('resultsBox2Value')
            await this.loadAndSetFromLocalStorage('resultsBox2Title')
            await this.loadAndSetFromLocalStorage('resultsBox3Value')
            await this.loadAndSetFromLocalStorage('resultsBox3Title')
            await this.loadAndSetFromLocalStorage('resultsBox4Value')
            await this.loadAndSetFromLocalStorage('resultsBox4Title')
            await this.loadAndSetFromLocalStorage('resultsBox5Value')
            await this.loadAndSetFromLocalStorage('resultsBox5Title')
            await this.loadAndSetFromLocalStorage('resultsBox6Value')
            await this.loadAndSetFromLocalStorage('resultsBox6Title')
            await this.loadAndSetFromLocalForage('orderList')
            await this.loadAndSetFromLocalForage('items')
            await this.loadAndSetFromLocalForage('currentSummaryTile')
            // reset 'referred' value in local storage
            localStorage.setItem(
                'referredFromDetailPage',
                JSON.stringify(false)
            )
        } else {
            // clear order filters?
        }

        await this.loadAndSetFromLocalStorage('itemReference')
        await this.loadAndSetFromLocalStorage('mode')
        await this.loadAndSetFromLocalStorage('referenceType')
        await this.loadAndSetFromLocalStorage('dateView')
        await this.loadAndSetFromLocalStorage('selectedDate')
        await this.loadAndSetFromLocalStorage('fromDate')
        await this.loadAndSetFromLocalStorage('toDate')
    }

    getOrgHeaderColumns = async () => {
        const { searchItem, clientId } = this.state
        const viewCode = getViewCodeBySearchItem(searchItem)
        if (clientId) {
            const view = await retrieveView(clientId, viewCode)
            if (view) {
                this.setState({
                    orgHeaderColumns: view.columnDefinitionsArray,
                    userViewId: view.id
                })
            }
        }
    }

    async loadAndSetFromLocalStorage(keyName, jsonParse = false) {
        const { key } = this.state
        const localStorageKey = `${key}.${keyName}`
        let value = jsonParse
            ? await JSON.parse(localStorage.getItem(localStorageKey))
            : await localStorage.getItem(localStorageKey)

        if (keyName === 'dateView') {
            // had to add this due to potential issue reading from storage when updating 'All ' to 'ALL'
            if (value?.trim() === 'All' || value?.trim() === 'ALL') {
                value = value.toUpperCase().trim()
            }
        }

        if (value !== null) {
            if (value.toLowerCase() === 'null') {
                this.setState({ [`${keyName}`]: null })
            } else {
                this.setState({ [`${keyName}`]: value }, () => {
                    if (keyName === 'selectedDate') {
                        this.setDatesFromDateDropdown(value)
                    }
                })
            }
        }
    }

    async loadAndSetFromLocalForage(keyName) {
        const { key } = this.state
        const localForageKey = `${key}.${keyName}`
        const value = await localforage.getItem(localForageKey)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    async loadAndSetFromLocalForageNoKey(keyName) {
        const value = await localforage.getItem(keyName)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    async fetchOrderSearchTypes() {
        const { clientId, key } = this.state
        const itemKeyUpper = key?.toUpperCase()

        if (clientId) {
            const token = await authService.getAccessToken()
            const response = await fetch(
                'api/DataManagement/GetGridSearchTypes?clientId=' +
                    clientId +
                    '&module=' +
                    itemKeyUpper,
                {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` }
                }
            )
            if (response.ok) {
                const data = await response.json()
                this.setState({ orderSearchTypes: data })
            }
        }
    }

    saveOrderFilterValuesToState = tableRef => {
        const newColumnFilters = tableRef.current.state.columns.map(column => ({
            field: column.field,
            filterValue: column.tableData.filterValue
        }))
        localforage.setItem('orderFilters', newColumnFilters)
        this.setState({ orderFilters: newColumnFilters })
    }

    handleRequestSort = (event, property) => {
        const { order, orderBy } = this.state
        const isAsc = orderBy === property && order === 'asc'
        this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property })
    }

    handlePageChange = (event, page) => {
        this.setState({ page: page })
    }

    handleChangeRowsPerPage = (event, page) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })
    }

    getURLForItem = (itemvalue, fieldname) => {
        const { clientId, key } = this.state

        var initialPath = `${ApplicationPaths.Orders}`
        if (fieldname === 'shipmentNumber') {
            initialPath = `${ApplicationPaths.Shipments}`
        } else if (key === 'orderline') {
            initialPath = `${ApplicationPaths.Orders}`
        }

        return {
            pathname: initialPath + '/' + clientId + '/' + itemvalue
        }
    }

    validSearchParameters = () => {
        this.setState({
            itemReferenceError: false,
            itemReferenceErrorMessage: ''
        })

        // if selected date is 10 (all time) then make sure the reference isn't null and is at least 3 characters
        const { selectedDate, itemReference } = this.state

        // if (parseInt(selectedDate) === 10) {
        //     if (itemReference === null || itemReference.length < 3) {
        //         this.setState({
        //             itemReferenceError: true,
        //             itemReferenceErrorMessage: '3 characters minimum required for \'All Time\' date search.'
        //         })
        //         return false
        //     }
        // }

        return true
    }

    handleSubmit = async event => {
        if (this.validSearchParameters()) {
            this.saveOrderFilterValuesToState(this.tableRef)
            this.populateTableData(event)
        }
    }

    handleClearAll = async event => {
        for (let [key, value] of Object.entries(localStorage)) {
            if (key.indexOf(`${this.state.key}.`) !== -1) {
                localStorage.removeItem(key)
            }
        }

        localStorage.removeItem('referredFromDetailPage')

        var lfKeys = []

        await localforage
            .keys()
            .then(function (keys) {
                lfKeys = keys
            })
            .catch(function (err) {
                console.log(err)
            })

        for (let lfKey of lfKeys) {
            if (lfKey.indexOf(this.state.key + '.') !== -1) {
                await localforage
                    .removeItem(lfKey)
                    .then(function () {
                        // nothing
                    })
                    .catch(function (err) {
                        console.log(err)
                    })
            }
        }

        window.location.reload()
    }

    setDatesFromDateDropdown = value => {
        let from = null
        let to = null
        const { key, fromDate, toDate } = this.state

        this.setState({
            selectedDateHelperText: ''
        })

        switch (parseInt(value)) {
            case 0: // Today
                from = dateFns.moment().startOf('day')
                to = dateFns.moment().endOf('day')
                break
            case 1: // This Week
                from = dateFns.moment().startOf('week')
                to = dateFns.moment().endOf('week')
                break
            case 2: // This Month
                from = dateFns.moment.utc().startOf('month')
                to = dateFns.moment.utc().endOf('month')
                break
            case 3: // This Year
                from = dateFns.moment.utc().startOf('year')
                to = dateFns.moment.utc().endOf('year')
                break
            case 4: // Next Week
                let nwDate = dateFns.moment.utc(new Date()).add(1, 'week')
                from = dateFns.moment(nwDate).startOf('week')
                to = dateFns.moment(nwDate).endOf('week')
                break
            case 5: // Next Month
                let nmDate = dateFns.moment.utc(new Date()).add(1, 'month')
                from = dateFns.moment(nmDate).startOf('month')
                to = dateFns.moment(nmDate).endOf('month')
                break
            case 6: // Next Year
                let nyDate = dateFns.moment.utc(new Date()).add(1, 'year')
                from = dateFns.moment(nyDate).startOf('year')
                to = dateFns.moment(nyDate).endOf('year')
                break
            case 7: // Last Week
                let lwDate = dateFns.moment.utc(new Date()).subtract(1, 'week')
                from = dateFns.moment(lwDate).startOf('week')
                to = dateFns.moment(lwDate).endOf('week')
                break
            case 8: // Last Month
                let lmDate = dateFns.moment.utc(new Date()).subtract(1, 'month')
                from = dateFns.moment(lmDate).startOf('month')
                to = dateFns.moment(lmDate).endOf('month')
                break
            case 9: // Last Year
                let lyDate = dateFns.moment.utc(new Date()).subtract(1, 'year')
                from = dateFns.moment(lyDate).startOf('year')
                to = dateFns.moment(lyDate).endOf('year')
                break
            case 10: //All Time
                from = fromDate
                to = toDate
                this.setState({
                    selectedDateHelperText:
                        'All Time search may take a while to load.'
                })
                break
            case 11: //Custom
                from = dateFns.moment.utc(fromDate)
                to = dateFns.moment.utc(toDate)
                break
            default:
                break
        }

        localStorage.setItem(key + '.fromDate', from)
        localStorage.setItem(key + '.toDate', to)

        this.setState({
            selectedDate: parseInt(value),
            fromDate: from,
            toDate: to
        })
    }

    handleClientSelectChange = selectedClientId => {
        localStorage.setItem(this.state.key + '.clientId', selectedClientId)
        this.setState({ clientId: selectedClientId })
    }

    handleChangeValue = (event, elem) => {
        let value = null
        const { key } = this.state

        const customIndex = 11

        switch (elem) {
            case 'fromDate':
                this.setState({ fromDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.fromDate', event)
                break

            case 'toDate':
                this.setState({ toDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.toDate', event)
                break

            case 'selectedDate':
                value = event.target.value
                localStorage.setItem(key + '.selectedDate', value)
                this.setDatesFromDateDropdown(value)
                break

            case 'dateView':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.dateView', value)
                this.setState({
                    dateView: value
                })
                break

            case 'reference-type':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.referenceType', value)
                this.setState({
                    referenceType: value
                })
                break

            case 'client':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.clientId', value)
                this.setState({
                    clientId: value
                })
                break

            case 'mode':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.mode', value)
                this.setState({
                    mode: value
                })
                break

            case 'reference':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.itemReference', value)
                this.setState({
                    itemReference: value
                })
                break

            default:
                break
        }
    }

    getDateObject = (time, substract = false) => {
        var moment = require('moment')
        const format = 'YYYY-MM-DD HH:mm:ss'
        var base = substract ? moment().substract(1, 'month') : moment()
        return {
            From: base.startOf(time).format(format),
            To: base.endOf(time).format(format)
        }
    }

    getDates = () => {
        const { selectedDate } = this.state

        if (selectedDate === 0) {
            return this.getDateObject('day')
        } else if (selectedDate === 1) {
            return this.getDateObject('week')
        } else if (selectedDate === 2) {
            return this.getDateObject('month')
        } else if (selectedDate === 3) {
            return this.getDateObject('month', true)
        } else if (selectedDate === 4) {
            return this.getDateObject('year')
        }

        return this.getDateObject('day')
    }

    renderToolTipOrderRef = orderRefValue => {
        var orderRef = ''
        var array = []
        if (orderRefValue != null) {
            array = orderRefValue.split(',')
            if (array.length <= 2) {
                orderRef = array.join()
            } else {
                orderRef = array[0]
            }
        }

        return (
            <p>
                {orderRef}
                {array.length > 2 ? (
                    <Tooltip
                        title={
                            <>
                                <h6>{array.join()}</h6>
                            </>
                        }
                        placement="right"
                    >
                        <MoreHorizIcon />
                    </Tooltip>
                ) : (
                    ''
                )}
            </p>
        )
    }

    renderToolTipDeliveryAddress = delAddressValue => {
        var delAddress = ''
        var array = []
        if (delAddressValue != null) {
            array = delAddressValue.split(',')
            delAddress = array[0]
        }
        // remove any empty elements
        array = array.filter(function (e) {
            return e !== ''
        })

        return (
            <>
                {delAddress}
                <Tooltip
                    title={
                        <>
                            <h6>{array.join(', ')}</h6>
                        </>
                    }
                    placement="right"
                >
                    <MoreHorizIcon />
                </Tooltip>
            </>
        )
    }

    async createBooking(rowData) {
        const { clientId } = this.state

        let approvecount = rowData.length
        // prompt user to confirm they want to create booking for single order
        if (approvecount === 1) {
            if (
                !window.confirm(
                    `Are you sure you want to create booking for order # ${rowData[0].orderNumber}?`
                )
            ) {
                return
            }
        } else {
            if (
                !window.confirm(
                    `Are you sure you want to create consolidated booking from ${approvecount} orders?` +
                        `\n - ${rowData.map(e => e.orderNumber).join('\n - ')}`
                )
            ) {
                return
            }
        }

        const currentClient = this.state.clients?.find(e => e.id === clientId)
        localStorage.setItem(
            'newBookingClientCode',
            currentClient.integrationCompanyCode
        )

        // open consolidation modal
        this.setState(
            {
                bookingOrders: rowData
            },
            () => {
                this.setState({ bookingOrderValidationModalOpen: true })
            }
        )
    }

    renderOrdersTable = items => {
        const {
            organization,
            key,
            searchItem,
            clientId,
            clients,
            orderFilters,
            orgHeaderColumns,
            userViewId,
            primaryColor,
            themeTextColor,
            createBookingButtonDisabled,
            canSeeScheduledReports
        } = this.state

        const tableTheme = createTheme()
        const tableViewCode = getViewCodeBySearchItem(this.state.searchItem)
        const tableViewName = getViewNameBySearchItem(this.state.searchItem)

        const extraCells =
            key === 'booking'
                ? [
                      {
                          title: 'Booking Date',
                          value: 'bookingDate',
                          visible: true
                      }
                  ]
                : []

        // Have to map new property as column header doesn't match field value
        const mappedItems = items.map(item => ({
            ...item,
            transportMode: item.orderTransportMode
        }))
        const currentClient = clients?.find(e => e.id === clientId)

        var cachedHeaderKeyValue = ''
        if (searchItem === 'OrderLines') {
            cachedHeaderKeyValue =
                currentClient?.clientView !== 'DEFAULT'
                    ? 'orderLineHeaders'.concat(
                          '_',
                          currentClient?.clientViewId
                      )
                    : 'orderLineHeaders'
        } else {
            cachedHeaderKeyValue =
                currentClient?.clientView !== 'DEFAULT'
                    ? 'orderHeaders'.concat('_', currentClient?.clientViewId)
                    : 'orderHeaders'
        }

        // Map headCells to match required properties for material-table
        function getHeaderColumns() {
            const columnHeaders =
                organization !== null && orgHeaderColumns !== null
                    ? orgHeaderColumns.concat(extraCells).map(headcell => {
                          return {
                              field: headcell.value,
                              title: headcell.title,
                              type: headcell.type,
                              sortField: headcell.sortField,
                              hidden: headcell.visible ? false : true,
                              cellStyle: {
                                  whiteSpace: 'nowrap'
                              },
                              removable: true,
                              hiddenByColumnsButton: true,
                              id: headcell.id,
                              order: headcell.order,
                              customTitle: headcell.customTitle,
                              width: headcell.width
                          }
                      })
                    : []
            return columnHeaders
        }

        let savedColumns = getHeaderColumns()

        // Need to add the render function as can't store this in localstorage
        var columnHeaders = savedColumns.map(column => {
            return {
                field: column.field,
                title: column.title,
                type: column.type,
                sortField: column.sortField,
                customSort: (a, b) => sortData(column, a, b),
                hidden: column.hidden,
                cellStyle: column.cellStyle,
                removable: column.removable,
                hiddenByColumnsButton: column.hiddenByColumnsButton,
                defaultFilter: getFilter(column.field),
                cellStyle: {
                    whiteSpace: 'nowrap'
                },
                render: params => {
                    if (column.field === 'transportMode') {
                        return getIconForShipment(params)
                    } else if (column.field === 'shipmentNumber') {
                        return (
                            <NavLink
                                onClick={() => {
                                    this.saveOrderFilterValuesToState(
                                        this.tableRef
                                    )
                                }}
                                to={this.getURLForItem(
                                    params.shipmentNumber,
                                    column.field
                                )}
                            >
                                {params.shipmentNumber}
                            </NavLink>
                        )
                    } else if (column.field === 'orderReferenceNumber') {
                        return this.renderToolTipOrderRef(
                            params.orderReferenceNumber
                        )
                    } else if (column.field === 'deliveryAddress') {
                        return this.renderToolTipDeliveryAddress(
                            params.deliveryAddress
                        )
                    } else if (column.field === 'orderNumber') {
                        return (
                            <NavLink
                                onClick={() => {
                                    this.saveOrderFilterValuesToState(
                                        this.tableRef
                                    )
                                }}
                                to={this.getURLForItem(
                                    btoa(params.orderNumber).replace('/', '_') +
                                        '/' +
                                        params.orderNumberSplit,
                                    column.field
                                )}
                            >
                                {params.orderNumber}
                            </NavLink>
                        )
                    } else if (column.field === 'orderFlagged') {
                        return params.orderFlagged ? 'YES' : null
                    } else {
                        return params[column.field]
                    }
                }
            }
        })

        const handleColumnDrag = (sourceIndex, destinationIndex) => {
            // take copy of source column
            const sourceColumn = savedColumns[sourceIndex]
            // remove source column
            savedColumns.splice(sourceIndex, 1)
            // insert at destination
            savedColumns.splice(destinationIndex, 0, sourceColumn)
            // update database
            const viewCode = getViewCodeBySearchItem(searchItem)
            // map savedColumns from frontend to match the database
            savedColumns = savedColumns.map(column => {
                return {
                    id: column.id,
                    title: column.title,
                    customTitle: column.customTitle,
                    value: column.field,
                    width: column.width,
                    visible: column.hidden ? false : true,
                    order: column.order,
                    type: column.type,
                    sortField: column.sortField
                }
            })

            this.setState({ orgHeaderColumns: savedColumns })

            saveViewColumns(
                viewCode,
                savedColumns,
                userViewId,
                currentClient?.clientViewId,
                true,
                clientId
            )
        }

        function handleChangeColumnHidden(column, hidden) {
            // Update hidden flag
            for (let i = 0; i < savedColumns.length; i++) {
                if (savedColumns[i].field === column.field) {
                    // Set column hidden
                    savedColumns[i].hidden = hidden
                    localStorage.setItem(
                        cachedHeaderKeyValue,
                        JSON.stringify(savedColumns)
                    )
                    break
                }
            }
        }

        function getFilter(field) {
            const columnData = orderFilters.find(col => col.field === field)
            if (columnData) {
                return columnData.filterValue
            } else {
                return null
            }
        }

        let tableActions = [
            {
                icon: () => {
                    return (
                        <Button variant="contained" color="primary">
                            {'Flag/Unflag Order'}
                        </Button>
                    )
                },
                tooltip: 'Toggle Order Flagged',
                onClick: (event, rowData) => {
                    this.toggleOrderFlagged(rowData)
                }
            },
            {
                icon: () => {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={createBookingButtonDisabled}
                        >
                            {'Create Booking'}
                        </Button>
                    )
                },
                tooltip: 'Create Booking',

                onClick: (event, rowData) => {
                    this.createBooking(rowData)
                },
                disabled: createBookingButtonDisabled
            },
            {
                icon: () => {
                    return (
                        <TableViewManagementDialog
                            viewCode={tableViewCode}
                            viewName={tableViewName}
                            fontColor={themeTextColor}
                            backgroundColor={primaryColor}
                            successCallback={this.getOrgHeaderColumns}
                            clientId={clientId}
                        />
                    )
                },
                isFreeAction: true
            },
            {
                icon: () => {
                    return (
                        <TableViewCreateDialog
                            fontColor={themeTextColor}
                            backgroundColor={primaryColor}
                        />
                    )
                },
                isFreeAction: true
            }
        ]

        if (canSeeScheduledReports) {
            tableActions.push({
                icon: () => {
                    return (
                        <ScheduledReportsManagementDialog
                            viewCode={tableViewCode}
                            viewName={tableViewName}
                            fontColor={themeTextColor}
                            backgroundColor={primaryColor}
                            organization={organization}
                            clientId={clientId}
                            showDialog={this.state.showScheduledReports}
                            closeDialog={() =>
                                this.setState({ showScheduledReports: false })
                            }
                            newReportData={this.state.newReportData}
                            reportSearchTypes={this.state.orderSearchTypes}
                            reportDateOptions={this.state.dateFiltering}
                            relatedModule={0}
                        />
                    )
                },
                isFreeAction: true
            })
        }

        return (
            <ThemeProvider theme={tableTheme}>
                <MaterialTable
                    tableRef={this.tableRef}
                    columns={columnHeaders}
                    data={mappedItems}
                    isLoading={this.state.searching}
                    icons={{
                        SortArrow: forwardRef((props, ref) => (
                            <ArrowDownward {...props} ref={ref} />
                        ))
                    }}
                    actions={tableActions}
                    options={{
                        filtering: true,
                        filterRowStyle: {
                            position: 'sticky',
                            top: 49,
                            background: 'white',
                            zIndex: 5 /* optionally */
                        },
                        maxBodyHeight: 'calc(100vh - 470px)',
                        pageSizeOptions: [25, 50, 75],
                        pageSize: 50,
                        showTitle: false,
                        search: true,
                        sorting: true,
                        columnsButton: false,
                        doubleHorizontalScroll: true,
                        draggable: true,
                        toolbar: true,
                        selection: true,
                        padding: 'dense',
                        exportButton: { csv: true },
                        exportAllData: true,
                        rowStyle: rowData => ({
                            backgroundColor: rowData.orderFlagged
                                ? '#F8AFA7'
                                : null
                        }),
                        pagination: {
                            rowsPerPage: 'Rows per page:',
                            displayRows: 'off'
                        },
                        exportCsv: (columns, data) =>
                            prepareCsvExport(
                                columns,
                                data,
                                organization,
                                searchItem + 'Export_' + Date.now()
                            )
                    }}
                    onColumnDragged={handleColumnDrag}
                    onChangeColumnHidden={handleChangeColumnHidden}
                />
            </ThemeProvider>
        )
    }

    handleBookingOrderValidationModalClose = () => {
        this.setState({ bookingOrderValidationModalOpen: false })
    }

    handleBookingOrderValidationModalSubmit = () => {
        // something
    }

    renderSummary = () => {
        const { classes } = this.props
        const {
            resultsBox1Value,
            resultsBox1Title,
            resultsBox2Value,
            resultsBox2Title,
            resultsBox3Value,
            resultsBox3Title,
            resultsBox4Value,
            resultsBox4Title,
            resultsBox5Value,
            resultsBox5Title,
            resultsBox6Value,
            resultsBox6Title,
            key,
            currentSummaryTile
        } = this.state

        const handleSummaryTileClick = (tileName, tileId) => {
            this.saveOrderFilterValuesToState(this.tableRef)
            var newOrderList = this.state.items.filter(
                i => i.orderStage !== null && i.orderStage.includes(tileName)
            )
            this.setState({
                orderList: newOrderList,
                currentSummaryTile: tileId
            })
            localforage.setItem(key + '.orderList', newOrderList)
            localforage.setItem(key + '.currentSummaryTile', tileId)
        }

        return (
            <div className={classes.root + ' theme-card'}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-blue border-3"
                                    title={resultsBox1Title}
                                    data={resultsBox1Value}
                                    isClicked={currentSummaryTile === 1}
                                    onClick={() => {
                                        this.setState({
                                            orderList: this.state.items,
                                            currentSummaryTile: 1
                                        })
                                        localforage.setItem(
                                            key + '.orderList',
                                            this.state.items
                                        )
                                        localforage.setItem(
                                            key + '.currentSummaryTile',
                                            1
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-dark-orchid border-3"
                                    title={resultsBox2Title}
                                    data={resultsBox2Value}
                                    isClicked={currentSummaryTile === 2}
                                    onClick={() => {
                                        handleSummaryTileClick(
                                            resultsBox2Title,
                                            2
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-forest-green border-3"
                                    title={resultsBox3Title}
                                    data={resultsBox3Value}
                                    isClicked={currentSummaryTile === 3}
                                    onClick={() => {
                                        handleSummaryTileClick(
                                            resultsBox3Title,
                                            3
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-ruby border-3"
                                    title={resultsBox4Title}
                                    data={resultsBox4Value}
                                    isClicked={currentSummaryTile === 4}
                                    onClick={() => {
                                        handleSummaryTileClick(
                                            resultsBox4Title,
                                            4
                                        )
                                    }}
                                />
                            </Grid>
                            {resultsBox5Title && resultsBox5Value && (
                                <Grid item xs>
                                    <SummaryInfo
                                        className="theme-mini-card gm-purple-heart border-3"
                                        title={resultsBox5Title}
                                        data={resultsBox5Value}
                                        isClicked={currentSummaryTile === 5}
                                        onClick={() => {
                                            handleSummaryTileClick(
                                                resultsBox5Title,
                                                5
                                            )
                                        }}
                                    />
                                </Grid>
                            )}
                            {resultsBox6Title && resultsBox6Value && (
                                <Grid item xs>
                                    <SummaryInfo
                                        className="theme-mini-card gm-blue border-3"
                                        title={resultsBox6Title}
                                        data={resultsBox6Value}
                                        isClicked={currentSummaryTile === 6}
                                        onClick={() => {
                                            handleSummaryTileClick(
                                                resultsBox6Title,
                                                6
                                            )
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const {
            clients,
            clientId,
            selectedDate,
            fromDate,
            toDate,
            minDate,
            todayDate,
            referenceType,
            dateView,
            itemReference,
            mode,
            downloadCSVDisabled,
            organization,
            itemReferenceError,
            itemReferenceErrorMessage,
            dateFiltering,
            primaryColor,
            themeTextColor,
            orderList,
            selectedDateHelperText,
            canSeeScheduledReports
        } = this.state

        const currentClient = clients?.find(e => e.id === clientId)

        let contents = this.state.loading ? (
            <LinearProgress color="primary" />
        ) : (
            <div>
                <CardContent className="p-0">
                    <Paper className="paper page-cover-height-200px">
                        <Grid container spacing={0} direction="column">
                            <Grid
                                item
                                xs={12}
                                className="mb-0 mb-4 w-100 d-flex"
                            >
                                <Grid item xs={3}>
                                    {this.state.error && (
                                        <Grid item xs={12}>
                                            <Alert
                                                className="mb-4"
                                                onClose={() => {
                                                    this.setState({
                                                        error: false
                                                    })
                                                }}
                                                severity="error"
                                            >
                                                {' '}
                                                Sorry, there was an error while
                                                retrieving items.
                                            </Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid
                                container
                                spacing={0}
                                className="mb-4 w-100 d-flex mobile-mode-no-flex"
                            >
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div
                                        className="mr-2 mb-3 map"
                                        style={{
                                            height: mapContainerStyle.height,
                                            position: 'relative',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {renderMap(orderList)}
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    className="p-25 pb-0 mt-2"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding"
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20"
                                        >
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-client">
                                                    Client
                                                </InputLabel>
                                                <ClientSelector
                                                    clients={clients}
                                                    selectedClientId={
                                                        clientId
                                                            ? clientId
                                                            : clients[0]?.id
                                                    }
                                                    handleClientSelection={
                                                        this
                                                            .handleClientSelectChange
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20"
                                        >
                                            <FormControl className="mr-auto">
                                                <div>
                                                    <InputLabel
                                                        id="select-label-mode"
                                                        className="my-auto"
                                                    >
                                                        Mode
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-mode"
                                                        id="mode"
                                                        value={mode}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'mode'
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                        <MenuItem value={'AIR'}>
                                                            Air
                                                        </MenuItem>
                                                        <MenuItem value={'SEA'}>
                                                            Sea
                                                        </MenuItem>
                                                        <MenuItem value={'ROA'}>
                                                            Road
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'Courier'}
                                                        >
                                                            COU
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20"
                                        >
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-referencetype">
                                                    Search Type
                                                </InputLabel>
                                                <Select
                                                    labelId="select-label-referencetype"
                                                    id="reference-type"
                                                    value={referenceType}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference-type'
                                                        )
                                                    }
                                                >
                                                    {this.state.orderSearchTypes.map(
                                                        (type, i) => {
                                                            return (
                                                                <MenuItem
                                                                    key={i + 1}
                                                                    value={
                                                                        type.searchType
                                                                    }
                                                                >
                                                                    {
                                                                        type.searchTypeDescription
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="Reference2">
                                                    Reference # (3 chars min)
                                                </InputLabel>
                                                <TextField
                                                    error={itemReferenceError}
                                                    helperText={
                                                        itemReferenceErrorMessage
                                                    }
                                                    size="small"
                                                    id="reference"
                                                    labelid="Reference2"
                                                    value={itemReference || ''}
                                                    placeholder="Reference # (3 chars min)"
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference'
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding  "
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2"
                                        >
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel
                                                        className="my-auto mx-auto flex-shrink-1"
                                                        id="select-label-dateview"
                                                    >
                                                        Date View
                                                    </InputLabel>
                                                    <Select
                                                        disabled={
                                                            selectedDate === 10
                                                                ? true
                                                                : false
                                                        }
                                                        labelId="select-label-dateview"
                                                        id="dateView"
                                                        value={dateView || ''}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'dateView'
                                                            )
                                                        }
                                                    >
                                                        <MenuItem
                                                            value={'OrderDate'}
                                                        >
                                                            Order Date
                                                        </MenuItem>
                                                        {currentClient?.clientView ===
                                                        'CLARK' ? (
                                                            <MenuItem
                                                                value={'ECRD'}
                                                            >
                                                                ECRD
                                                            </MenuItem>
                                                        ) : null}
                                                        <MenuItem
                                                            value={
                                                                'DateArriveEst'
                                                            }
                                                        >
                                                            ETA
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={
                                                                'DateDepartEst'
                                                            }
                                                        >
                                                            ETD
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={
                                                                'ExWorksRequiredByDate '
                                                            }
                                                        >
                                                            ExWorks Required By
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={
                                                                'DeliveryRequiredByDate '
                                                            }
                                                        >
                                                            Delivery Required By
                                                        </MenuItem>
                                                        <MenuItem value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2"
                                        >
                                            <FormControl className="mx-auto ">
                                                <div>
                                                    <InputLabel id="select-label-date">
                                                        Date
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-date"
                                                        value={selectedDate}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'selectedDate'
                                                            )
                                                        }
                                                    >
                                                        {dateFiltering.map(
                                                            function (date, i) {
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={
                                                                            date.id
                                                                        }
                                                                    >
                                                                        {
                                                                            date.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {selectedDateHelperText}
                                                    </FormHelperText>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className=""
                                        >
                                            <FormControl className="mx-auto calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            disabled={
                                                                selectedDate ===
                                                                10
                                                                    ? true
                                                                    : false
                                                            }
                                                            autoOk
                                                            format={
                                                                organization?.pickerDateFormat
                                                            }
                                                            label="From Date"
                                                            value={
                                                                fromDate
                                                                    ? fromDate
                                                                    : minDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'fromDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className=""
                                        >
                                            <FormControl className="mx-auto  calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        className={
                                                            classes.textField
                                                        }
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            disabled={
                                                                selectedDate ===
                                                                10
                                                                    ? true
                                                                    : false
                                                            }
                                                            autoOk
                                                            format={
                                                                organization?.pickerDateFormat
                                                            }
                                                            label="To Date"
                                                            value={
                                                                toDate
                                                                    ? toDate
                                                                    : todayDate
                                                            }
                                                            minDate={
                                                                fromDate
                                                                    ? fromDate
                                                                    : todayDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'toDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding mt-2 "
                                    >
                                        <Grid
                                            item
                                            xs={canSeeScheduledReports ? 4 : 6}
                                        >
                                            <Button
                                                className="MuiButton-containedPrimary submit w-100"
                                                style={{
                                                    background: primaryColor,
                                                    color: themeTextColor
                                                }}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                disabled={this.state.searching}
                                                onClick={e =>
                                                    this.handleSubmit(true)
                                                }
                                            >
                                                {this.state.searching
                                                    ? 'Loading...'
                                                    : 'SEARCH'}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={canSeeScheduledReports ? 4 : 6}
                                        >
                                            <Button
                                                style={{
                                                    border: primaryColor,
                                                    color: primaryColor
                                                }}
                                                size="small"
                                                className="submit mb-2 w-100 secondary"
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.handleClearAll}
                                            >
                                                RESET SEARCH
                                            </Button>
                                        </Grid>
                                        {canSeeScheduledReports && (
                                            <Grid item xs={4}>
                                                <Button
                                                    style={{
                                                        border: primaryColor,
                                                        color: primaryColor
                                                    }}
                                                    size="small"
                                                    className="submit mb-2 w-100 secondary"
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled={
                                                        this.state.searching
                                                    }
                                                    onClick={
                                                        this
                                                            .handleNewScheduledReport
                                                    }
                                                    startIcon={<ScheduleIcon />}
                                                >
                                                    SCHEDULE REPORT
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className="mb-2 w-100 d-flex align-items-center mobile-mode-no-flex"
                            >
                                <Grid item xs={12} className="px-4 w-100">
                                    {this.renderSummary()}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className="w-100 px-3">
                                <div className="psa-shipment-table custom-table-styles w-100">
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mb-4 w-100"
                                    >
                                        <Grid item xs={12}>
                                            <div className="first-row-filter-hidden">
                                                {this.renderOrdersTable(
                                                    orderList
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </CardContent>
                <BookingOrdersConsolidation
                    isOpen={this.state.bookingOrderValidationModalOpen}
                    handleClose={this.handleBookingOrderValidationModalClose}
                    title={'Booking Orders Consolidation'}
                    bookingOrders={this.state.bookingOrders}
                    primaryColor={primaryColor}
                    primaryTextColor={themeTextColor}
                    style={{
                        border: primaryColor
                    }}
                ></BookingOrdersConsolidation>
            </div>
        )

        return <div>{contents}</div>
    }

    async populateClientsData() {
        const token = await authService.getAccessToken()
        const { clientId, key, searchItem } = this.state
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch(
            `api/clients/getclientslist?module=orders`,
            headers
        )

        if (response.ok) {
            const data = await response.json()
            this.setState({ clients: data }, () => {
                const { clients } = this.state
                const cachedClientId = localStorage.getItem(key + '.clientId')
                if (cachedClientId) {
                    // see if cached client id is in clients array
                    const client = clients?.find(
                        client => client.id === cachedClientId
                    )
                    if (client) {
                        this.setState({ clientId: cachedClientId }, () => {
                            this.runClientDependentFunctions()
                        })
                    } else {
                        // set client id to first client in clients array
                        this.setState({ clientId: clients[0]?.id }, () => {
                            localStorage.setItem(
                                key + '.clientId',
                                clients[0]?.id
                            )
                            this.runClientDependentFunctions()
                        })
                    }
                } else {
                    // set client id to first client in clients array
                    this.setState({ clientId: clients[0]?.id }, () => {
                        localStorage.setItem(key + '.clientId', clients[0]?.id)
                        this.runClientDependentFunctions()
                    })
                }
            })
        } else {
            this.setState({ loading: false, error: true })
        }
    }

    async populateConfig() {
        const { key, searchItem } = this.state
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        const showScheduledReports = data.featureAccessParameters.find(
            fap => fap.featureCode === 'ScheduledReports'
        )
            ? true
            : false
        this.setState({
            organization: data,
            canSeeScheduledReports:
                showScheduledReports && searchItem !== 'OrderLines',
            dateFiltering: data.dateFilterTypeArray,
            primaryColor: data.colorThemePrimaryRGB,
            themeTextColor: data.colorThemeTextRGB
        })
        localforage.setItem(key + '.organization', data)
    }

    getRandomColor = () => {
        // generate a light hex color
        let color = '#'
        for (let i = 0; i < 3; i++)
            color += (
                '0' +
                Math.floor(
                    ((1 + Math.random()) * Math.pow(16, 2)) / 2
                ).toString(16)
            ).slice(-2)
        return color
    }

    getQueryString = (useQuickSearch = true, initialLoad = false) => {
        const {
            clientId,
            referenceType,
            fromDate,
            toDate,
            mode,
            itemReference,
            dateView,
            searchItem,
            selectedDate
        } = this.state

        const queryString = require('query-string')

        var qs = {}

        qs['SearchItem'] = searchItem
        qs['referenceType'] = referenceType
        qs['Mode'] = mode
        qs['UseQuickSearch'] = useQuickSearch

        if (itemReference !== null && itemReference.length > 1) {
            qs['OrderSearchNumber'] = itemReference
        }

        qs['DateType'] = dateView

        if (fromDate && selectedDate !== 10){
            qs['FromDate'] = fromDate.toISOString(false)
        }

        if (toDate && selectedDate !== 10){
            qs['ToDate'] = toDate.toISOString(false)
        }

        if (clientId !== null) {
            qs['ClientId'] = clientId
        }

        if (initialLoad) {
            qs['InitialLoad'] = true
        }

        qs['SelectedDate'] = selectedDate

        return queryString.stringify(qs)
    }

    async toggleOrderFlagged(rowData) {
        let approvecount = rowData.length

        if (approvecount > 1) {
            alert('You can only update one order at a time.')
            return
        }

        if (rowData.shipmentNumber) {
            alert('Order has linked shipment, cannot flag.')
        } else {
            const token = await authService.getAccessToken()
            const rawResponse = await fetch('api/orders/FlagOrder', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ClientId: this.state.clientId,
                    FlaggedValue: rowData.orderFlagged ? false : true,
                    OrderNumber: rowData.orderNumber,
                    OrderNumberSplit: rowData.orderNumberSplit,
                    BuyerCode: rowData.buyerCode
                })
            })

            if (rawResponse.ok) {
                // indicate success to user
                this.populateTableData()
            } else {
                var responseText = await rawResponse.text()
                if (rawResponse.status === 403) {
                    // feature not enabled
                    alert(responseText)
                } else {
                    alert('Failed to flag shipment, please contact support.')
                }
            }
        }
    }

    async setClientsWithCreateBookingAccess() {
        const token = await authService.getAccessToken()
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch(
            `api/clients/getclientslist?module=CreateBooking`,
            headers
        )

        if (response.ok) {
            const data = await response.json()
            this.setState({ createBookingClients: data })
        }
    }

    async setCreateBookingAccess() {
        const { clientId, createBookingClients } = this.state

        const createBookingClient = createBookingClients?.find(
            c => c.id === clientId
        )

        this.setState({
            createBookingButtonDisabled: createBookingClient ? false : true
        })
    }

    async populateTableData(useQuickSearch = true, initialLoad = false) {
        // set summary tiles back to default
        this.setState({ currentSummaryTile: 1 })

        await this.setCreateBookingAccess()

        const { clientId, key } = this.state

        this.setState({ searching: true })

        const token = await authService.getAccessToken()

        if (clientId !== null) {
            this.setState({ error: false })

            const headers = {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }

            const response = await fetch(
                `api/orders/GetOrders?` +
                    this.getQueryString(useQuickSearch, initialLoad),
                headers
            )

            if (response.ok) {
                const data = await response.json()
                var tableData = null

                switch (this.state.searchItem) {
                    case 'OrderLines':
                        tableData = data.orderLines
                        break
                    default:
                        tableData = data.orders
                        break
                }

                this.setState({
                    resultsBox1Value: data.resultsBox1Value,
                    resultsBox1Title: data.resultsBox1Title,
                    resultsBox2Value: data.resultsBox2Value,
                    resultsBox2Title: data.resultsBox2Title,
                    resultsBox3Value: data.resultsBox3Value,
                    resultsBox3Title: data.resultsBox3Title,
                    resultsBox4Value: data.resultsBox4Value,
                    resultsBox4Title: data.resultsBox4Title,
                    resultsBox5Value: data.resultsBox5Value,
                    resultsBox5Title: data.resultsBox5Title,
                    resultsBox6Value: data.resultsBox6Value,
                    resultsBox6Title: data.resultsBox6Title
                })

                localforage.setItem(key + '.orderList', tableData)
                localforage.setItem(key + '.items', tableData)
                localStorage.setItem(
                    key + '.resultsBox1Value',
                    data.resultsBox1Value
                )
                localStorage.setItem(
                    key + '.resultsBox1Title',
                    data.resultsBox1Title
                )
                localStorage.setItem(
                    key + '.resultsBox2Value',
                    data.resultsBox2Value
                )
                localStorage.setItem(
                    key + '.resultsBox2Title',
                    data.resultsBox2Title
                )
                localStorage.setItem(
                    key + '.resultsBox3Value',
                    data.resultsBox3Value
                )
                localStorage.setItem(
                    key + '.resultsBox3Title',
                    data.resultsBox3Title
                )
                localStorage.setItem(
                    key + '.resultsBox4Value',
                    data.resultsBox4Value
                )
                localStorage.setItem(
                    key + '.resultsBox4Title',
                    data.resultsBox4Title
                )
                localStorage.setItem(
                    key + '.resultsBox5Value',
                    data.resultsBox5Value
                )
                localStorage.setItem(
                    key + '.resultsBox5Title',
                    data.resultsBox5Title
                )
                localStorage.setItem(
                    key + '.resultsBox6Value',
                    data.resultsBox6Value
                )
                localStorage.setItem(
                    key + '.resultsBox6Title',
                    data.resultsBox6Title
                )

                this.setState({
                    items: tableData,
                    orderList: tableData,
                    loading: false
                })
            } else {
                this.setState({ loading: false, error: true })
            }
        } else {
            this.setState({ loading: false })
        }

        this.setState({ searching: false })
    }
}
export default withStyles(styles)(OrdersTable)
