import authService from '../components/api-authorization/AuthorizeService'
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants'

async function fetchCall(
    uri,
    successBlock = () => {},
    failureBlock = () => {},
    isFile = false
) {
    const token = await authService.getAccessToken()
    fetch(uri, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
        .then(async response => {
            if (response.ok) {
                const data = await (isFile ? response.blob() : response.json())
                successBlock(data, response.headers)
            } else {
                failureBlock()
            }
        })
        .catch(e => {
            failureBlock()
        })
}

async function fetchPOSTCall(
    uri,
    successBlock = () => {},
    failureBlock = () => {},
    isFile = false,
    postData
) {
    const token = await authService.getAccessToken()
    fetch(uri, {
        headers: !token
            ? {}
            : {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              },
        method: 'POST',
        body: JSON.stringify(postData)
    })
        .then(async response => {
            if (response.ok) {
                const data = await (isFile ? response.blob() : response.json())
                successBlock(data, response.headers)
            } else {
                failureBlock()
            }
        })
        .catch(e => {
            failureBlock()
        })
}

export async function getAppInfo(
    clientId = null,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const query = clientId !== null ? '?clientId=' + clientId : ''
    const uri = 'api' + ApplicationPaths.AppInfo + query
    fetchCall(uri, successBlock, failureBlock)
}

export async function downloadCSV(
    query,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri = 'api/Shipments/DownloadShipmentResults?' + query
    fetchCall(uri, successBlock, failureBlock, true)
}

export async function downloadOrdersCSV(
    searchItem,
    query,
    successBlock = () => {},
    failureBlock = () => {}
) {
    var uri = ''
    switch (searchItem) {
        case 'OrderLines':
            uri = 'api/Orders/DownloadOrderLineResults?'
            break
        default:
            uri = 'api/Orders/DownloadOrderResults?'
    }
    uri += query
    fetchCall(uri, successBlock, failureBlock, true)
}

export async function downloadInvoicesCSV(
    query,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri = 'api/Accounting/DownloadAccountingResults?' + query
    fetchCall(uri, successBlock, failureBlock, true)
}

export async function downloadInvoicePDF(
    searchNumber,
    clientId,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri =
        'api/Accounting/DownloadInvoice?InvoiceSearchNumber=' +
        searchNumber +
        '&ClientId=' +
        clientId
    fetchCall(uri, successBlock, failureBlock, true)
}

export async function downloadInvoicePDFForShipment(
    searchNumber,
    clientId,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri =
        'api/Shipments/DownloadInvoiceOnShipment?InvoiceSearchNumber=' +
        searchNumber +
        '&ClientId=' +
        clientId
    fetchCall(uri, successBlock, failureBlock, true)
}

export async function downloadDocumentPDFForShipment(
    document,
    successBlock = () => {},
    failureBlock = () => {}
) {
    let uri = 'api/Shipments/DownloadDocumentOnShipment'
    if (document.DownloadAll) {
        uri = 'api/Shipments/DownloadAllDocumentsOnShipment'
    }
    fetchPOSTCall(uri, successBlock, failureBlock, true, document)
}

export async function downloadDocumentPDFForOrder(
    orderNumber,
    orderNumberSplit,
    clientId,
    documentName,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri =
        'api/Orders/DownloadDocumentOnOrder?OrderSearchNumber=' +
        orderNumber +
        '&OrderSearchNumberSplit=' +
        orderNumberSplit +
        '&SearchItem=' +
        btoa(encodeURIComponent(documentName)).replace('/', '_') +
        '&ClientId=' +
        clientId
    fetchCall(uri, successBlock, failureBlock, true)
}

export async function getCreatedShipment(
    shipmentNumber,
    editCopyBookingClientId,
    editCopyBookingNumber,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const token = await authService.getAccessToken()
    if (editCopyBookingNumber !== null && editCopyBookingNumber !== undefined) {
        const postData = {
            BookingNumber: editCopyBookingNumber,
            ClientId: editCopyBookingClientId
        }
        fetch('api/CreateShipments/RetrieveBookingForUpdate', {
            headers: !token
                ? {}
                : {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  },
            method: 'POST',
            body: JSON.stringify(postData)
        })
            .then(async response => {
                if (response.ok) {
                    const data = await response.json()
                    successBlock(data)
                } else {
                    failureBlock()
                }
            })
            .catch(e => {
                failureBlock()
            })
    } else {
        fetch(
            'api/CreateShipments/GetCreateShipment?shipmentNumber=' +
                shipmentNumber,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
            .then(async response => {
                if (response.ok) {
                    const data = await response.json()
                    successBlock(data)
                } else {
                    failureBlock()
                }
            })
            .catch(e => {
                failureBlock()
            })
    }
}

export async function getCreatedOrder(
    orderId,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const token = await authService.getAccessToken()
    fetch('api/CreateOrders/GetCreateOrder?orderId=' + orderId, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
        .then(async response => {
            if (response.ok) {
                const data = await response.json()
                successBlock(data)
            } else {
                failureBlock()
            }
        })
        .catch(e => {
            failureBlock()
        })
}

export async function updateOrderPlanning(
    orderPlanningData,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const token = await authService.getAccessToken()
    fetch('api/CreateOrders/UpdateOrderPlanning', {
        headers: !token
            ? {}
            : {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              },
        method: 'POST',
        body: JSON.stringify(orderPlanningData)
    })
        .then(async response => {
            if (response.ok) {
                successBlock()
            } else {
                failureBlock()
            }
        })
        .catch(e => {
            failureBlock()
        })
}

export async function getScheduledReports(module = 2) {
    const token = await authService.getAccessToken()
    const response = await fetch('api/ScheduledJobs?module=' + module, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
    if (response.ok) {
        return await response.json()
    }
    return []
}

export async function createScheduledReport(scheduledReport) {
    // need to determine the URL for the API call based on the scheduledreport relatedmodule
    // 2, 4, 1 = Shipment
    // 0 = Order
    // 3 = Container
    // 5 = Invoice

    let urlModule = ''
    switch (scheduledReport.RelatedModule) {
        case 0:
            urlModule = 'Order'
            break
        case 1:
        case 2:
        case 4:
            urlModule = 'Shipment'
            break
        case 3:
            urlModule = 'Container'
            break
        case 5:
            urlModule = 'Invoice'
            break
        default:
            urlModule = 'Shipment'
            break
    }

    const token = await authService.getAccessToken()
    const response = await fetch(
        `api/ScheduledJobs/Create${urlModule}ScheduledReport?${scheduledReport.ReportQueryString}`,
        {
            headers: !token
                ? {}
                : {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  },
            method: 'POST',
            body: JSON.stringify(scheduledReport)
        }
    )

    if (response.ok) {
        return await response.json()
    }
    return null
}

export async function updateScheduledReport(scheduledReport) {
    const token = await authService.getAccessToken()
    const response = await fetch(`api/ScheduledJobs/${scheduledReport.Id}`, {
        headers: !token
            ? {}
            : {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              },
        method: 'PUT',
        body: JSON.stringify(scheduledReport)
    })

    if (response.ok) {
        return true
    }
    return null
}

export async function deleteScheduledReport(id) {
    const token = await authService.getAccessToken()
    const response = await fetch(`api/ScheduledJobs/${id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
        method: 'DELETE'
    })

    if (response.ok) {
        return true
    }
    return null
}

export async function getContainerOrderLines(
    containerNumber,
    shipmentNumber,
    clientId,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const token = await authService.getAccessToken()
    fetch(
        'api/Containers/GetContainerOrderLines?containerNumber=' +
            containerNumber +
            '&shipmentNumber=' +
            shipmentNumber +
            '&clientId=' +
            clientId,
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
    )
        .then(async response => {
            if (response.ok) {
                const data = await response.json()
                successBlock(data)
            } else {
                failureBlock()
            }
        })
        .catch(e => {
            failureBlock()
        })
}

export async function alignClientUserModuleAccess(
    successBlock = () => {},
    failureBlock = () => {}
) {
    const token = await authService.getAccessToken()
    fetch('api/clients/UpdateClientUsersModuleAccessForOrgs', {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
        method: 'POST'
    })
        .then(async response => {
            if (response.ok) {
                const data = await response.json()
                successBlock(data)
            } else {
                failureBlock()
            }
        })
        .catch(e => {
            failureBlock()
        })
}

export async function getCreateOrderTemplate(
    orderId,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const token = await authService.getAccessToken()
    fetch('api/CreateOrders/GetCreateOrderTemplate?orderId=' + orderId, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
        .then(async response => {
            if (response.ok) {
                const data = await response.json()
                successBlock(data)
            } else {
                failureBlock()
            }
        })
        .catch(e => {
            failureBlock()
        })
}

export async function getUNLOCO(
    query,
    isName,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri = `api/DataManagement/GetUNLOCOs?pageNumber=1&pageSize=20&searchQuery=${query}&isNameSearch=${
        isName ? 'true' : 'false'
    }`
    fetchCall(uri, successBlock, failureBlock)
}

export async function getLocationByUNLOCO(
    unloco,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri = `api/DataManagement/GetUNLOCO?unloco=${unloco}`
    fetchCall(uri, successBlock, failureBlock)
}

export async function getCountry(
    query,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri = `api/DataManagement/GetCountries?pageNumber=1&pageSize=20&searchQuery=${query}&isNameSearch=false`
    fetchCall(uri, successBlock, failureBlock)
}

export async function getClientData(
    id,
    successBlock = () => {},
    failureBlock = () => {}
) {
    const uri = 'api/clients/' + id
    fetchCall(uri, successBlock, failureBlock)
}

export async function getTypesData() {
    /*const token = await authService.getAccessToken()
    const response = await fetch(
        'api/DataManagement/GetDataTypes?dataTypesRequested=ALL',
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
    )
    if (response.ok) {
        const data = await response.json()
        sessionStorage.setItem(dataTypesKey(), JSON.stringify(data))
        setDataTypes(data)
    }*/
}
