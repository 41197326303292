import authService from '../components/api-authorization/AuthorizeService'

const checkCustomPermission = (key, array) => {
    if (array === undefined || array === null || array.length === 0)
        return false

    const customPermissions = array.filter(
        permission => permission.propertyKey === key
    )
    return customPermissions.length > 0 ? customPermissions[0].value : false
}

export const extractCreateShipment = array => {
    return checkCustomPermission('ShipmentCreate', array)
}

export const extractSearchShipment = array => {
    return checkCustomPermission('ShipmentSearch', array)
}

export const extractSeeCustoms = array => {
    return checkCustomPermission('Customs', array)
}

export const extractSeeAccounting = array => {
    return checkCustomPermission('Accounting', array)
}

export const extractSeeBooking = array => {
    return checkCustomPermission('Booking', array)
}

export const extractSeeContainers = array => {
    return checkCustomPermission('Container', array)
}

export const extractSeeOrder = array => {
    return checkCustomPermission('Order', array)
}

export const extractCreateOrder = array => {
    return checkCustomPermission('CreateOrder', array)
}

export const extractSeeOrderLine = array => {
    return checkCustomPermission('OrderLine', array)
}

export const canSeeCustoms = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin && extractSeeCustoms(appInfoArray.modulePermissionArray)) ||
        (extractSeeCustoms(appInfoArray.modulePermissionArray) &&
            extractSeeCustoms(appInfoArray.userModulePermissionArray))
    )
}

export const canSeeContainers = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin && extractSeeContainers(appInfoArray.modulePermissionArray)) ||
        (extractSeeContainers(appInfoArray.modulePermissionArray) &&
            extractSeeContainers(appInfoArray.userModulePermissionArray))
    )
}

export const canSeeAccounting = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin && extractSeeAccounting(appInfoArray.modulePermissionArray)) ||
        (extractSeeAccounting(appInfoArray.modulePermissionArray) &&
            extractSeeAccounting(appInfoArray.userModulePermissionArray))
    )
}

export const canSeeBooking = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin && extractSeeBooking(appInfoArray.modulePermissionArray)) ||
        (extractSeeBooking(appInfoArray.modulePermissionArray) &&
            extractSeeBooking(appInfoArray.userModulePermissionArray))
    )
}

export const canSeeOrders = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin && extractSeeOrder(appInfoArray.modulePermissionArray)) ||
        (extractSeeOrder(appInfoArray.modulePermissionArray) &&
            extractSeeOrder(appInfoArray.userModulePermissionArray))
    )
}

export const canCreateOrder = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin && extractCreateOrder(appInfoArray.modulePermissionArray)) ||
        (extractCreateOrder(appInfoArray.modulePermissionArray) &&
            extractCreateOrder(appInfoArray.userModulePermissionArray))
    )
}

export const canCreateShipment = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin &&
            extractCreateShipment(appInfoArray.modulePermissionArray)) ||
        (extractCreateShipment(appInfoArray.modulePermissionArray) &&
            extractCreateShipment(appInfoArray.userModulePermissionArray))
    )
}

export const canSearchShipment = async appInfoArray => {
    const isSuperAdmin = await authService.isSuperAdmin()
    const isAdmin = await authService.isAdmin()
    return (
        isSuperAdmin ||
        (isAdmin &&
            extractSearchShipment(appInfoArray.modulePermissionArray)) ||
        (extractSearchShipment(appInfoArray.modulePermissionArray) &&
            extractSearchShipment(appInfoArray.userModulePermissionArray))
    )
}

export const clientUserHasPermission = async (clientId, permissionName) => {
    const token = await authService.getAccessToken()
    const response = await fetch(
        'api/users/GetClientUserPermissionValue?clientId=' +
            clientId +
            '&permissionName=' +
            permissionName,
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
    )

    if (response.ok) {
        const data = await response.json()
        return data
    }
    return false
}
