import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid } from '@material-ui/core'
import CountUp from 'react-countup'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        cursor: 'pointer'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    avatar: {
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    }
}))

const SummaryInfo = props => {
    const { title, data, className, isClicked, ...rest } = props
    const cardId = isClicked ? 'summary-tile-clicked' : null
    const cardTitleId = isClicked ? 'summary-tile-clicked-title' : null
    const cardValueId = isClicked ? 'summary-tile-clicked-value' : null
    const countUpEnd = data ? parseFloat(data.replace(/[^0-9.-]+/g, '')) : 0
    const countUpDecimals = data ? (data.substring(0, 1) === '$' ? 2 : 0) : 0
    const countUpPrefix = data ? (data.substring(0, 1) === '$' ? '$' : '') : ''

    const classes = useStyles()

    return (
        <Card {...rest} className={clsx(classes.root, className)} id={cardId}>
            <CardContent>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className="py-0 d-flex align-content-stretch"
                >
                    <Grid item xs={12} className="text-center">
                        <div id={cardTitleId} className="align-self-stretch">
                            {title}
                        </div>
                        <div id={cardValueId}>
                            <CountUp
                                start={0}
                                end={countUpEnd}
                                duration={2.75}
                                separator=","
                                decimals={countUpDecimals}
                                prefix={countUpPrefix}
                            />
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

SummaryInfo.propTypes = {
    className: PropTypes.string
}

export default SummaryInfo
