import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { renderTable } from './TableHelper'

const classes = makeStyles(theme => ({}))

export default function OrderPlanningCard({ itemextra, item }) {
    const theme = useTheme()

    const containers = [
        { key: 'containerNumber', label: 'Container Number', icon: false },
        { key: 'containerCount', label: 'Container Count', icon: false },
        { key: 'containerType', label: 'Container Type', icon: false }
    ]

    return (
        <div className="p-0 pl-3 data-data-info w-100">
            <h5 class="text-Curious-blue my-2 font-14" checkOn={false}>
                Planning Info
            </h5>
            <Grid
                container
                direction="row"
                spacing={1}
                className={theme.bgColorSection}
            >
                <Grid item xs={8}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={3} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Goods Origin
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(itemextra && itemextra.origin) || '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Port Of Loading
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(itemextra && itemextra.loadPort) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Departure Vessel
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item &&
                                            item.plannedDepartureVessel) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Departure Voyage #
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item &&
                                            item.plannedDepartureVoyage) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        ETD
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item && item.dateDepartEstString) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Goods Destination
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(itemextra && itemextra.destination) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    Discharge Port
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra && itemextra.dischargePort) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={2} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Arrival Vessel
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item && item.plannedArrivalVessel) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        Arrival Voyage #
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item && item.plannedArrivalVoyage) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} className="sm-flex-100">
                            <div className="column">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue"
                                    >
                                        ETA
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item && item.dateArriveEstString) ||
                                            '-'}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={4}
                    className="sm-flex-100 display-table-row-group"
                >
                    <div class="font-12 text-black mb-2 ">
                        Planned Containers
                    </div>
                    <Typography>
                        <div className="psa-shipment-table  psa-shipment-table-info">
                            {renderTable(
                                itemextra?.containers,
                                containers,
                                'containers',
                                classes,
                                null,
                                {
                                    maxHeight: '200px'
                                }
                            )}
                        </div>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
