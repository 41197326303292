import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import authService from '../api-authorization/AuthorizeService'
import { LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@mui/material/Grid'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useEffect } from 'react'
import { Select, MenuItem, InputLabel, Checkbox } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import ScheduleIcon from '@material-ui/icons/Schedule'
import DateFnsUtils from '@date-io/moment'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import MaterialTable from 'material-table'
import {
    getScheduledReports,
    createScheduledReport,
    updateScheduledReport,
    deleteScheduledReport
} from '../../api/api'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    docTypeSelect: {},
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    editReportTextField: {
        width: '150px'
    }
}))

const dateFns = new DateFnsUtils()

export default function ScheduledReportsManagementDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [successMessage, setSuccessMessage] = React.useState('View Saved!')
    const [isCreateSuccess, setIsCreateSuccess] = React.useState(false)
    const [isError, setIsError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [changesMade, setChangesMade] = React.useState(false)
    const [adminUser, setAdminUser] = React.useState(false)
    const [superAdminUser, setSuperAdminUser] = React.useState(false)
    const [selectedReport, setSelectedReport] = React.useState(null)
    const [allReports, setAllReports] = React.useState([])
    const [editDialogOpen, setEditDialogOpen] = React.useState(false)
    const [isNewReport, setIsNewReport] = React.useState(false)
    const [nameInputHelperText, setNameInputHelperText] = React.useState(null)
    const [nameInputError, setInputNameError] = React.useState(false)
    const [emailInputHelperText, setEmailInputHelperText] = React.useState(null)
    const [emailInputError, setEmailInputError] = React.useState(false)

    useEffect(() => {
        async function fetchIsAdmin() {
            const userIsAdmin = await authService.isAdmin()
            const userIsSuperAdmin = await authService.isSuperAdmin()
            setAdminUser(userIsAdmin || userIsSuperAdmin)
            setSuperAdminUser(userIsSuperAdmin)
        }
        fetchIsAdmin()
    }, [])

    // check if showDialog props is true
    useEffect(() => {
        if (props.showDialog) {
            handleScheduledReportsClickOpen().then(() => {
                // show the edit report dialog
                setSelectedReport(props.newReportData)
                setIsNewReport(true)
                setEditDialogOpen(true)
            })
        } else {
            setIsNewReport(false)
        }
    }, [props.showDialog])

    const { palette } = createTheme()
    const theme = createTheme({
        palette: {
            primary: {
                main: props.backgroundColor,
                contrastText: props.fontColor
            }
        }
    })

    const fetchScheduledReports = async () => {
        setIsLoading(true)

        const fetchedReports = await getScheduledReports(props.relatedModule)

        setAllReports(fetchedReports)
        setIsLoading(false)
    }

    const handleScheduledReportsClickOpen = async () => {
        await fetchScheduledReports()
        setOpen(true)
    }

    const getSelectStartHourMenuItems = () => {
        const menuItems = []
        for (let i = 0; i < 24; i++) {
            menuItems.push(<MenuItem value={i}>{`${i}:00`}</MenuItem>)
        }
        return menuItems
    }

    const handleClose = () => {
        if (changesMade) {
            if (!window.confirm('Cancel without saving changes?')) {
                return
            } else {
                setChangesMade(false)
            }
        }
        setIsError(false)
        setErrorMessage('')
        setIsSuccess(false)
        setOpen(false)
        props.closeDialog()
    }

    const handleSuccess = successMessage => {
        setChangesMade(false)
        setSuccessMessage(successMessage)
        setIsSuccess(true)
        setInterval(function () {
            // call callback function passed in from props
            setIsSuccess(false)
        }, 3000)
    }

    const handleDeleteError = errorMessage => {
        setErrorMessage(errorMessage)
        setIsError(true)
        setIsLoading(false)
    }

    const handleDeleteSuccess = () => {
        setIsLoading(false)
        handleSuccess('Report Successfully Removed!')
        fetchScheduledReports()
    }

    const handleError = errorMessage => {
        setErrorMessage(errorMessage)
        setIsError(true)
    }

    const handleEditReportDialogClose = () => {
        setEditDialogOpen(false)
        // refresh the list of reports
        fetchScheduledReports()
        if (isNewReport) handleClose()
    }

    const handleDeleteReport = async id => {
        if (!window.confirm('Are you sure you want to delete this report?')) {
            return
        }
        setIsLoading(true)
        const response = await deleteScheduledReport(id)
        if (response !== null) {
            handleDeleteSuccess()
        } else {
            handleDeleteError('Error Deleting Scheduled Report')
        }
    }

    const getUTCDateTime = (date, hour) => {
        if (!date) {
            return dateFns.moment.utc(new Date())
        }

        // need to set the hour before converting to UTC
        date = new Date(date)
        date = date.setHours(hour || 0, 0, 0, 0)

        return dateFns.moment.utc(date).format('YYYY-MM-DDTHH:mm:ss')
    }

    const handleEditReportDialogSubmission = async () => {
        // check that name and email address are not empty
        if (!selectedReport?.name) {
            setInputNameError(true)
            setNameInputHelperText('Name is required')
        } else {
            setInputNameError(false)
            setNameInputHelperText(null)
        }

        if (!selectedReport?.emailAddress) {
            setEmailInputError(true)
            setEmailInputHelperText('Email Address is required')
        } else {
            setEmailInputError(false)
            setEmailInputHelperText(null)
        }

        if (!selectedReport?.name || !selectedReport?.emailAddress) {
            return
        }

        // check if email address is valid
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(selectedReport?.emailAddress)) {
            setEmailInputError(true)
            setEmailInputHelperText('Invalid Email Address')
            return
        }

        if (isNewReport) {
            // handle new report submission
            setIsLoading(true)
            // handle new report submission here
            var newReport = {
                Name: selectedReport?.name,
                JobType: 0,
                RelatedModule: selectedReport?.relatedModule,
                Frequency: selectedReport?.frequency,
                NextRunDate: getUTCDateTime(
                    selectedReport?.nextRunDate,
                    selectedReport?.nextRunTime
                ),
                IsActive: selectedReport?.isActive,
                EmailAddress: selectedReport?.emailAddress,
                ReportQueryString: selectedReport?.reportQueryString,
                FromDateType: selectedReport?.fromDateType || 'fixed',
                ToDateType: selectedReport?.toDateType || 'fixed'
            }

            const response = await createScheduledReport(newReport)

            if (response !== null) {
                handleSuccess('Scheduled Report Created!')
                fetchScheduledReports()
            } else {
                handleError('Error Creating Scheduled Report')
            }
        } else {
            // handle edit report submission
            setIsLoading(true)
            // handle edit report submission here
            var updatedReport = {
                Id: selectedReport?.id,
                Name: selectedReport?.name,
                JobType: 0,
                RelatedModule: selectedReport?.relatedModule,
                Frequency: selectedReport?.frequency,
                NextRunDate: getUTCDateTime(
                    selectedReport?.nextRunDate,
                    selectedReport?.nextRunTime
                ),
                IsActive: selectedReport?.isActive,
                EmailAddress: selectedReport?.emailAddress,
                FromDateType: selectedReport?.fromDateType || 'fixed',
                ToDateType: selectedReport?.toDateType || 'fixed'
            }

            const response = await updateScheduledReport(updatedReport)
            if (response !== null) {
                fetchScheduledReports()
                handleSuccess('Scheduled Report Updated!')
            } else {
                handleError('Error Updating Scheduled Report')
            }
        }

        setEditDialogOpen(false)
        setIsLoading(false)
    }

    const renderReportEditDialog = () => {
        // need to render a new dialog to show when the user clicks the edit icon on a specific column
        // this dialog will allow the user to change the column name and other properties
        // the dialog will have a text field for the column name, a checkbox for visible, and a checkbox for required
        // the dialog will have a save and cancel button
        // the dialog will have a delete button if the column is not required
        // the dialog will have a close button

        return (
            <Dialog
                open={editDialogOpen}
                onClose={handleEditReportDialogClose}
                fullWidth
            >
                <DialogTitle>{`${
                    isNewReport ? 'Create New ' : 'Edit '
                } Scheduled Report - ${
                    isNewReport ? selectedReport?.module : selectedReport?.name
                }`}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                id="editReportClient"
                                label="Client"
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={selectedReport?.clientName}
                                margin="normal"
                                disabled
                            />
                            <br />
                            <TextField
                                id="editReportReferenceType"
                                label="Search Type"
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={
                                    selectedReport?.referenceTypeDescription
                                }
                                margin="normal"
                                disabled
                            />
                            <br />
                            <TextField
                                id="editReportDateView"
                                label="Date View"
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={selectedReport?.dateView}
                                margin="normal"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="editReportTransportMode"
                                label="Transport Mode"
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={selectedReport?.transportMode}
                                margin="normal"
                                disabled
                            />
                            <br />
                            <TextField
                                id="editReportItemReference"
                                label="Search Value"
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={selectedReport?.itemReference}
                                margin="normal"
                                disabled
                            />
                            <br />
                            <TextField
                                id="editReportDateRange"
                                label="Date Range"
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={selectedReport?.dateRange || ''}
                                margin="normal"
                                disabled
                                helperText="*Report from/to dates will be dynamic based on 'Date Range' unless custom date range selected."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="editReportFromDate"
                                    label="From Date"
                                    format={
                                        props.organization?.pickerDateFormat
                                    }
                                    value={
                                        selectedReport?.fromDate || new Date()
                                    }
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                    disabled
                                />
                            </MuiPickersUtilsProvider>
                            <ToggleButtonGroup
                                color="primary"
                                value={selectedReport?.fromDateType || 'fixed'} // need to make this exclusive
                                exclusive
                                onChange={e =>
                                    setSelectedReport({
                                        ...selectedReport,
                                        fromDateType: e.target.value
                                    })
                                }
                                aria-label="FromDateType"
                                id="editReportFromDateType"
                                disabled={
                                    selectedReport?.selectedDate === 11
                                        ? false
                                        : true
                                }
                            >
                                <ToggleButton value="fixed">Fixed</ToggleButton>
                                <ToggleButton value="relative">
                                    Relative
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="editReportToDate"
                                    label="To Date"
                                    format={
                                        props.organization?.pickerDateFormat
                                    }
                                    value={selectedReport?.toDate || new Date()}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                    disabled
                                />
                            </MuiPickersUtilsProvider>
                            <ToggleButtonGroup
                                color="primary"
                                value={selectedReport?.toDateType || 'fixed'} // need to make this exclusive
                                exclusive
                                onChange={e =>
                                    setSelectedReport({
                                        ...selectedReport,
                                        toDateType: e.target.value
                                    })
                                }
                                aria-label="ToDateType"
                                id="editReportToDateType"
                                disabled={
                                    selectedReport?.selectedDate === 11
                                        ? false
                                        : true
                                }
                            >
                                <ToggleButton value="fixed">Fixed</ToggleButton>
                                <ToggleButton value="relative">
                                    Relative
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <br />
                    <p style={{ fontSize: '12px', color: 'gray' }}>
                        <strong>Note</strong>: Relative date range will be
                        calculated based on the current date.
                    </p>
                    <br />
                    <h4>Report Details</h4>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="editReportName"
                                label="Name"
                                required
                                error={nameInputError}
                                helperText={nameInputHelperText}
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={selectedReport?.name}
                                margin="normal"
                                onChange={e =>
                                    setSelectedReport({
                                        ...selectedReport,
                                        name: e.target.value
                                    })
                                }
                            />
                            <br />
                            <TextField
                                id="editReportEmailAddress"
                                label="Email Address"
                                required
                                error={emailInputError}
                                helperText={emailInputHelperText}
                                className={classes.editReportTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                defaultValue={selectedReport?.emailAddress}
                                margin="normal"
                                type="email"
                                onChange={e =>
                                    setSelectedReport({
                                        ...selectedReport,
                                        emailAddress: e.target.value
                                    })
                                }
                            />
                            <br />
                            <InputLabel id="editReportFrequencyLabel">
                                Frequency
                            </InputLabel>
                            <Select
                                id="editReportFrequency"
                                required
                                value={selectedReport?.frequency || 0}
                                margin="normal"
                                className={classes.editReportTextField}
                                onChange={e =>
                                    setSelectedReport({
                                        ...selectedReport,
                                        frequency: e.target.value
                                    })
                                }
                            >
                                <MenuItem value={0}>Daily</MenuItem>
                                <MenuItem value={1}>Weekly</MenuItem>
                                <MenuItem value={2}>Monthly</MenuItem>
                            </Select>
                            <br />
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="editReportStartDate"
                                            autoOk
                                            label={
                                                isNewReport
                                                    ? 'First Run Date'
                                                    : 'Next Run Date'
                                            }
                                            format={
                                                props.organization
                                                    ?.pickerDateFormat
                                            }
                                            value={
                                                selectedReport?.nextRunDate ||
                                                new Date()
                                            }
                                            onChange={date => {
                                                setSelectedReport({
                                                    ...selectedReport,
                                                    nextRunDate: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel id="editReportStartHourLabel">
                                        {isNewReport
                                            ? 'First Run Hour'
                                            : 'Next Run Hour'}
                                    </InputLabel>
                                    <Select
                                        id="editReportStartHour"
                                        value={selectedReport?.nextRunTime || 6}
                                        margin="normal"
                                        style={{ marginTop: '5px' }}
                                        className={classes.editReportTextField}
                                        onChange={e =>
                                            setSelectedReport({
                                                ...selectedReport,
                                                nextRunTime: e.target.value
                                            })
                                        }
                                    >
                                        {getSelectStartHourMenuItems()}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Checkbox
                                        id="editReportActive"
                                        checked={selectedReport?.isActive}
                                        onChange={e =>
                                            setSelectedReport({
                                                ...selectedReport,
                                                isActive: e.target.checked
                                            })
                                        }
                                        inputProps={{
                                            'aria-label': 'primary checkbox'
                                        }}
                                    />
                                    <label htmlFor="editReportActive">
                                        Active
                                    </label>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <ThemeProvider theme={theme}>
                    <DialogActions>
                        <Button
                            onClick={handleEditReportDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleEditReportDialogSubmission}
                            color="primary"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </ThemeProvider>
            </Dialog>
        )
    }

    const handleReportSelection = id => {
        let editReport = allReports.find(report => report.id === id)

        // editReport has a parameters property that is a stringified JSON object base 64 encoded
        // need to decode it and parse it
        editReport.parameters = atob(editReport.parameters)
        editReport.parameters = JSON.parse(editReport.parameters)

        editReport.clientName = editReport.clientName
        editReport.referenceTypeDescription = props.reportSearchTypes.find(
            searchType =>
                searchType.searchType === editReport.parameters.ReferenceType
        ).searchTypeDescription
        editReport.dateView = editReport.parameters.DateType
        editReport.transportMode = editReport.parameters.Mode
        editReport.itemReference = editReport.parameters.ShipmentSearchNumber
        editReport.dateRange = props.reportDateOptions.find(
            dateOption => dateOption.id === editReport.parameters.SelectedDate
        ).label
        editReport.fromDate = editReport.parameters.FromDate
        editReport.toDate = editReport.parameters.ToDate
        editReport.fromDateType = editReport.parameters.FromDateType
        editReport.toDateType = editReport.parameters.ToDateType

        editReport.nextRunTime = dateFns.moment
            .utc(editReport.nextRunDate)
            .local()
            .hour()
        editReport.nextRunDate = dateFns.moment
            .utc(editReport.nextRunDate)
            .local()
            .format('YYYY-MM-DD')

        setSelectedReport(editReport)
        setIsNewReport(false)
        setEditDialogOpen(true)
    }

    const dateToLocalString = date => {
        if (!date) return '-'
        return dateFns.moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
    }

    const renderReportList = () => {
        const reportsTableTheme = createTheme({
            palette: {
                primary: {
                    main: props.backgroundColor,
                    contrastText: props.fontColor
                }
            }
        })

        return (
            <MaterialTable
                title="Scheduled Reports"
                columns={[
                    {
                        title: 'Edit',
                        field: 'edit',
                        render: rowData => (
                            <IconButton
                                onClick={() =>
                                    handleReportSelection(rowData.id)
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        ),
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Delete',
                        field: 'delete',
                        render: rowData => (
                            <IconButton
                                onClick={() => handleDeleteReport(rowData.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        ),
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Active',
                        field: 'isActive',
                        render: rowData => (rowData.isActive ? 'Yes' : 'No'),
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Name',
                        field: 'name',
                        cellStyle: { whiteSpace: 'nowrap', fontWeight: 'bold' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Status',
                        field: 'currentStatusString',
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Frequency',
                        field: 'frequencyString',
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Email Address',
                        field: 'emailAddress',
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Next Run',
                        field: 'nextRunDate',
                        render: rowData =>
                            dateToLocalString(rowData.nextRunDate),
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Last Run',
                        field: 'lastRunDate',
                        render: rowData =>
                            dateToLocalString(rowData.lastRunDate),
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Last Run Duration',
                        field: 'lastRunDurationString',
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Created By',
                        field: 'createdByString',
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Created',
                        field: 'createdDate',
                        render: rowData =>
                            dateToLocalString(rowData.createdDate),
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Modified By',
                        field: 'modifiedByString',
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    },
                    {
                        title: 'Modified',
                        field: 'modifiedDate',
                        render: rowData =>
                            dateToLocalString(rowData.modifiedDate),
                        cellStyle: { whiteSpace: 'nowrap' },
                        headerStyle: { whiteSpace: 'nowrap' }
                    }
                ]}
                data={allReports}
                options={{
                    search: false,
                    paging: false,
                    sorting: false,
                    showTitle: false,
                    toolbar: false
                }}
                components={{
                    Container: props => <div {...props} />
                }}
                theme={reportsTableTheme}
            />
        )
    }

    return (
        <>
            <IconButton
                style={{ float: 'left', zIndex: 99 }}
                color="inherit"
                size="small"
                onClick={handleScheduledReportsClickOpen}
                disableRipple
                disableFocusRipple
            >
                <ScheduleIcon />
                Scheduled Reports
            </IconButton>

            <Dialog maxWidth={'xl'} open={open} onClose={handleClose}>
                <DialogTitle
                    style={{
                        textAlign: 'center',
                        color: props.fontColor,
                        backgroundColor: props.backgroundColor
                    }}
                >
                    {'Scheduled Reports: ' + props.viewName}
                </DialogTitle>
                <DialogContent style={{ minHeight: '500px' }}>
                    {isLoading && <LinearProgress />}
                    {
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {
                                    // put list of scheduled reports here
                                    renderReportList()
                                }
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ minWidth: '100px' }}
                        onClick={handleClose}
                        variant="outlined"
                        disabled={isLoading}
                    >
                        Close
                    </Button>
                </DialogActions>
                {isSuccess && (
                    <Alert severity="success" variant="filled">
                        {successMessage}
                    </Alert>
                )}
                {isError && (
                    <Alert
                        severity="error"
                        variant="filled"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setIsError(false)
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMessage}
                    </Alert>
                )}
            </Dialog>
            {renderReportEditDialog()}
        </>
    )
}
