import React, { PureComponent, forwardRef } from 'react'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { Paper } from '@material-ui/core'
import { TextField, Tooltip } from '@material-ui/core'
import {
    FormControl,
    InputLabel,
    LinearProgress,
    FormHelperText
} from '@material-ui/core'
import { MenuItem, Select, Grid, Button } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Alert from '@material-ui/lab/Alert'
import { CardContent } from '@material-ui/core'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import SummaryInfo from './shared/SummaryInfo'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { downloadInvoicePDF, downloadInvoicesCSV } from '../api/api'
import Icon from '@material-ui/core/Icon'
import MaterialTable from 'material-table'
import { NavLink } from 'react-router-dom'
import { sortData } from '../utils/sorting'
import { ThemeProvider, createTheme } from '@mui/material'
import TableViewManagementDialog from './shared/TableViewManagementDialog'
import TableViewCreateDialog from './shared/TableViewCreateDialog'
import { retrieveView } from './shared/Views'
import { prepareCsvExport } from '../utils/exporting'
import ClientSelector from '../components/shared/ClientSelector'

const styles = theme => ({
    formControl: {
        margin: 0,
        minWidth: '170px',
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
    },
    link: {
        color: theme.palette.primary.contrastText
    }
})

const dateFns = new DateFnsUtils()

class AccountingTable extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            items: [],
            invoiceList: [],
            clients: [],
            clientId: null,
            organization: null,
            selectedDate: 2, // this month
            fromDate: dateFns.moment().startOf('month'),
            toDate: dateFns.moment().endOf('month'),
            loading: true,
            error: false,
            rowsPerPage: 25,
            page: 0,
            order: 'asc',
            orderBy: 'aH_TransactionNum',
            ageNotDue: 0,
            ageCurrent: 0,
            age30_45: 0,
            age46_60: 0,
            age60_90: 0,
            age90: 0,
            totalInvoiceAmount: 0,
            totalOutstandingAmount: 0,
            paymentStatus: 'ALL',
            dateView: 'Due Date',
            transactionType: 'ALL',
            theme: props.theme,
            labeledInvoice: '',
            key: props.itemKey || 'invoice',
            downloadCSVDisabled: true,
            searching: false,
            itemReferenceError: false,
            itemReferenceErrorMessage: '',
            orgHeaderColumns: [],
            dateFiltering: [],
            currentSummaryTile: 1,
            orderClients: [],
            showOrders: false,
            selectedDateHelperText: ''
        }
    }

    async componentDidMount() {
        this.setState({ searching: true })

        await this.setClientsWithOrderAccess()
        await this.populateConfig()
        await this.populateClientsData()
    }

    async runClientDependentFunctions() {
        await this.initSearch()
        await this.getOrgHeaderColumns()
        await this.populateTableData(true)

        this.setState({ searching: false })
    }

    async initSearch() {
        await this.loadAndSetFromLocalStorage('clientId')
        await this.loadAndSetFromLocalStorage('paymentStatus')
        await this.loadAndSetFromLocalStorage('transactionType')
        await this.loadAndSetFromLocalStorage('labeledInvoice')
        await this.loadAndSetFromLocalStorage('selectedDate')
        await this.loadAndSetFromLocalStorage('fromDate', true)
        await this.loadAndSetFromLocalStorage('toDate', true)
    }

    getOrgHeaderColumns = async () => {
        const { clientId } = this.state
        if (clientId) {
            const accountingOrgView = await retrieveView(
                clientId,
                'AccountingSearchGrid'
            )
            if (accountingOrgView) {
                this.setState({
                    orgHeaderColumns: accountingOrgView.columnDefinitionsArray
                })
            }
        }
    }

    setClientsWithOrderAccess = async () => {
        const token = await authService.getAccessToken()
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch(
            `api/clients/getclientslist?module=orders`,
            headers
        )

        if (response.ok) {
            const data = await response.json()
            this.setState({ orderClients: data })
        }
    }

    setDatesFromDateDropdown = value => {
        let from = null
        let to = null
        const { key, fromDate, toDate } = this.state

        this.setState({
            selectedDateHelperText: ''
        })

        switch (parseInt(value)) {
            case 0: // Today
                from = dateFns.moment().startOf('day')
                to = dateFns.moment().endOf('day')
                break
            case 1: // This Week
                from = dateFns.moment().startOf('week')
                to = dateFns.moment().endOf('week')
                break
            case 2: // This Month
                from = dateFns.moment.utc().startOf('month')
                to = dateFns.moment.utc().endOf('month')
                break
            case 3: // This Year
                from = dateFns.moment.utc().startOf('year')
                to = dateFns.moment.utc().endOf('year')
                break
            case 4: // Next Week
                let nwDate = dateFns.moment.utc(new Date()).add(1, 'week')
                from = dateFns.moment(nwDate).startOf('week')
                to = dateFns.moment(nwDate).endOf('week')
                break
            case 5: // Next Month
                let nmDate = dateFns.moment.utc(new Date()).add(1, 'month')
                from = dateFns.moment(nmDate).startOf('month')
                to = dateFns.moment(nmDate).endOf('month')
                break
            case 6: // Next Year
                let nyDate = dateFns.moment.utc(new Date()).add(1, 'year')
                from = dateFns.moment(nyDate).startOf('year')
                to = dateFns.moment(nyDate).endOf('year')
                break
            case 7: // Last Week
                let lwDate = dateFns.moment.utc(new Date()).subtract(1, 'week')
                from = dateFns.moment(lwDate).startOf('week')
                to = dateFns.moment(lwDate).endOf('week')
                break
            case 8: // Last Month
                let lmDate = dateFns.moment.utc(new Date()).subtract(1, 'month')
                from = dateFns.moment(lmDate).startOf('month')
                to = dateFns.moment(lmDate).endOf('month')
                break
            case 9: // Last Year
                let lyDate = dateFns.moment.utc(new Date()).subtract(1, 'year')
                from = dateFns.moment(lyDate).startOf('year')
                to = dateFns.moment(lyDate).endOf('year')
                break
            case 10: //All Time
                from = fromDate
                to = toDate
                this.setState({
                    selectedDateHelperText:
                        'All Time search may take a while to load.'
                })
                break
            case 11: //Custom
                from = dateFns.moment.utc(fromDate)
                to = dateFns.moment.utc(toDate)
                break
            default:
                break
        }

        localStorage.setItem(key + '.fromDate', from)
        localStorage.setItem(key + '.toDate', to)

        this.setState({
            selectedDate: parseInt(value),
            fromDate: from,
            toDate: to
        })
    }

    renderNavLinkOrderRef = (clientId, invoiceData) => {
        const { showOrders } = this.state
        var orderRefs = invoiceData.orderReferenceNumber
        if (orderRefs != null) {
            return (
                <>
                    {orderRefs.split(',').map((refnum, index) => {
                        if (invoiceData.orders) {
                            var order = invoiceData.orders.find(
                                order => order.orderNumber === refnum.trim()
                            )
                            if (order) {
                                return (
                                    <div>
                                        {showOrders ? (
                                            <NavLink
                                                key={index}
                                                to={
                                                    '/orders/' +
                                                    clientId +
                                                    '/' +
                                                    btoa(refnum.trim()).replace(
                                                        '/',
                                                        '_'
                                                    ) +
                                                    '/' +
                                                    order.orderNumberSplit
                                                }
                                            >
                                                {refnum}
                                            </NavLink>
                                        ) : (
                                            <span>{refnum}</span>
                                        )}
                                    </div>
                                )
                            } else {
                                return <div>{refnum}</div>
                            }
                        } else {
                            return <div>{refnum}</div>
                        }
                    })}
                </>
            )
        } else {
            return <></>
        }
    }

    async loadAndSetFromLocalStorage(keyName, isDate = false) {
        const { key } = this.state
        const localStorageKey = `${key}.${keyName}`
        const value =
            isDate && localStorage.getItem(localStorageKey) !== null
                ? await dateFns.moment(localStorage.getItem(localStorageKey))
                : await localStorage.getItem(localStorageKey)

        if (value !== null) {
            this.setState({ [`${keyName}`]: value }, () => {
                if (keyName === 'selectedDate') {
                    this.setDatesFromDateDropdown(value)
                }
            })
        }
    }

    handleRequestSort = (event, property) => {
        const { order, orderBy } = this.state
        const isAsc = orderBy === property && order === 'asc'
        this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property })
    }

    handleChangePage = (event, page) => {
        this.setState({ page: page })
    }

    handleChangeRowsPerPage = (event, page) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })
    }

    validSearchParameters = () => {
        this.setState({
            itemReferenceError: false,
            itemReferenceErrorMessage: ''
        })

        // if selected date is 11 (all time) then make sure the reference isn't null and is at least 3 characters
        const { selectedDate, labeledInvoice } = this.state

        // if (parseInt(selectedDate) === 10) {
        //     if (labeledInvoice === null || labeledInvoice.length < 3) {
        //         this.setState({
        //             itemReferenceError: true,
        //             itemReferenceErrorMessage: '3 characters minimum required for \'All Time\' date search.'
        //         })
        //         return false
        //     }
        // }

        return true
    }

    handleSubmit = async event => {
        if (this.validSearchParameters()) {
            this.populateTableData()
        }
    }

    handleDownloadCSV = async event => {
        this.setState({ downloadCSVDisabled: true })
        downloadInvoicesCSV(
            this.getQueryString(),
            blob => {
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `invoices.csv`)
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)

                this.setState({ downloadCSVDisabled: false })
            },
            () => {
                this.setState({ downloadCSVDisabled: false })
            }
        )
    }

    handleClientSelectChange = selectedClientId => {
        localStorage.setItem(this.state.key + '.clientId', selectedClientId)
        this.setState({ clientId: selectedClientId })
    }

    handleChangeValue = (event, elem) => {
        let value = null
        const customIndex = 11

        switch (elem) {
            case 'client':
                value = event ? event.target.value : event
                localStorage.setItem(this.state.key + '.clientId', value)
                this.setState({
                    clientId: value
                })
                break

            case 'fromDate':
                this.setState({ fromDate: event, selectedDate: customIndex })
                localStorage.setItem(
                    this.state.key + '.selectedDate',
                    customIndex
                )
                localStorage.setItem(this.state.key + '.fromDate', event)
                break

            case 'toDate':
                this.setState({ toDate: event, selectedDate: customIndex })
                localStorage.setItem(
                    this.state.key + '.selectedDate',
                    customIndex
                )
                localStorage.setItem(this.state.key + '.toDate', event)
                break

            case 'selectedDate':
                value = event.target.value
                localStorage.setItem(this.state.key + '.selectedDate', value)
                this.setDatesFromDateDropdown(value)
                break

            case 'paymentStatus':
                value = event ? event.target.value : event
                localStorage.setItem(this.state.key + '.paymentStatus', value)
                this.setState({
                    paymentStatus: value
                })
                break

            case 'transactionType':
                value = event ? event.target.value : event
                localStorage.setItem(this.state.key + '.transactionType', value)
                this.setState({
                    transactionType: value
                })
                break

            case 'dateView':
                value = event ? event.target.value : event
                localStorage.setItem(this.state.key + '.dateView', value)
                this.setState({
                    dateView: value
                })
                break

            case 'labeledInvoice':
                value = event ? event.target.value : event
                localStorage.setItem(this.state.key + '.labeledInvoice', value)
                this.setState({
                    labeledInvoice: value
                })
                break

            default:
                break
        }
    }

    renderToolTipOrderRef = item => {
        var orderRef = ''
        var array = []
        if (item.orderReferenceNumber != null) {
            array = item.orderReferenceNumber.split(',')
            if (array.length <= 2) {
                orderRef = array.join()
            } else {
                orderRef = array[0]
            }
        }

        return (
            <p>
                {orderRef}
                {array.length > 2 ? (
                    <Tooltip
                        title={
                            <React.Fragment>
                                <p>{array.join()}</p>
                            </React.Fragment>
                        }
                        placement="right"
                    >
                        <MoreHorizIcon />
                    </Tooltip>
                ) : (
                    ''
                )}
            </p>
        )
    }

    renderAccountingTable = items => {
        const { clientId, orgHeaderColumns, organization } = this.state
        const tableTheme = createTheme()

        var headCells = orgHeaderColumns
            .filter(column => column.visible)
            .map(column => {
                return {
                    field: column.value,
                    title: column.title,
                    type: column.type,
                    sortField: column.sortField
                }
            })

        // doing the previous map as shipment/order tables have pre-parsing of columns for local user
        headCells = headCells.map(column => {
            return {
                field: column.field,
                title: column.title,
                type: column.type,
                sortField: column.sortField,
                cellStyle: {
                    whiteSpace: 'nowrap'
                },
                customSort: (a, b) => sortData(column, a, b),
                render: params => {
                    if (column.field === 'aH_TransactionNum') {
                        return (
                            <Button
                                className="table-download-btn"
                                variant="outlined"
                                onClick={() => {
                                    handleDownloadInvoicePDF(
                                        params.aH_TransactionNum,
                                        clientId
                                    )
                                }}
                            >
                                {params.aH_TransactionNum}
                            </Button>
                        )
                    } else if (column.field === 'shipmentNumber') {
                        return (
                            <NavLink
                                to={
                                    '/shipments/' +
                                    clientId +
                                    '/' +
                                    params.shipmentNumber
                                }
                            >
                                {params.shipmentNumber}
                            </NavLink>
                        )
                    } else if (column.field === 'orderReferenceNumber') {
                        return this.renderNavLinkOrderRef(clientId, params)
                    } else if (
                        column.field === 'aH_InvoiceAmount' ||
                        column.field === 'aH_OutstandingAmount'
                    ) {
                        return <>{params[column.field].toLocaleString()}</>
                    } else {
                        return <>{params[column.field]}</>
                    }
                }
            }
        })

        const handleDownloadInvoicePDF = async (invoiceNumber, clientId) => {
            downloadInvoicePDF(
                invoiceNumber,
                clientId,
                (blob, headers) => {
                    const filename = headers
                        .get('Content-Disposition')
                        .split('filename=')[1]
                        .split(';')[0]

                    const newFilename = filename.slice(1, filename.length - 1)

                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', newFilename)
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode.removeChild(link)
                },
                () => {}
            )
        }

        return (
            <ThemeProvider theme={tableTheme}>
                <MaterialTable
                    columns={headCells}
                    data={items}
                    isLoading={this.state.searching}
                    icons={{
                        SortArrow: forwardRef((props, ref) => (
                            <ArrowDownward {...props} ref={ref} />
                        ))
                    }}
                    options={{
                        filtering: true,
                        maxBodyHeight: 'calc(100vh - 350px)',
                        pageSizeOptions: [15, 25, 50, 75],
                        filterRowStyle: {
                            position: 'sticky',
                            top: 49,
                            background: 'white',
                            zIndex: 5 /* optionally */
                        },
                        sorting: true,
                        pageSize: 15,
                        showTitle: false,
                        doubleHorizontalScroll: true,
                        search: false,
                        columnsButton: false,
                        showFirstLastPageButtons: false,
                        toolbar: true,
                        draggable: false,
                        padding: 'dense',
                        pagination: {
                            rowsPerPage: 'Rows per page:',
                            displayRows: 'off'
                        },
                        exportButton: { csv: true },
                        exportAllData: true,
                        exportCsv: (columns, data) =>
                            prepareCsvExport(
                                columns,
                                data,
                                organization,
                                'AccountingExport_' + Date.now()
                            )
                    }}
                />
            </ThemeProvider>
        )
    }

    handleSummaryTileClick = (tileName, tileId) => {
        this.setState({
            invoiceList: this.state.items.filter(
                i =>
                    i.invoiceStage !== null && i.invoiceStage.includes(tileName)
            ),
            currentSummaryTile: tileId
        })
    }

    renderSummary = () => {
        const { classes } = this.props
        const {
            resultsBox1Value,
            resultsBox1Title,
            resultsBox2Value,
            resultsBox2Title,
            resultsBox3Value,
            resultsBox3Title,
            resultsBox4Value,
            resultsBox4Title,
            resultsBox5Value,
            resultsBox5Title,
            resultsBox6Value,
            resultsBox6Title,
            resultsBox7Value,
            resultsBox7Title,
            resultsBox8Value,
            resultsBox8Title,
            currentSummaryTile
        } = this.state
        return (
            // <div className={classes.root} className="theme-card no-flex accounting root">
            <div className="theme-card no-flex accounting root">
                <Grid container spacing={2}>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            title={resultsBox1Title}
                            data={resultsBox1Value}
                            isClicked={currentSummaryTile === 1}
                            className="theme-mini-card gm-forest-green border-width-3"
                            onClick={() => {
                                this.handleSummaryTileClick('1', 1)
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            title={resultsBox2Title}
                            data={resultsBox2Value}
                            isClicked={currentSummaryTile === 2}
                            className="theme-mini-card gm-blue border-width-3"
                            onClick={() => {
                                this.handleSummaryTileClick('2', 2)
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            title={resultsBox3Title}
                            data={resultsBox3Value}
                            isClicked={currentSummaryTile === 3}
                            className="theme-mini-card gm-gargoyle-gas border-width-3"
                            onClick={() => {
                                this.handleSummaryTileClick('3', 3)
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            title={resultsBox4Title}
                            data={resultsBox4Value}
                            isClicked={currentSummaryTile === 4}
                            className="theme-mini-card gm-orange border-width-3"
                            onClick={() => {
                                this.handleSummaryTileClick('4', 4)
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            title={resultsBox5Title}
                            data={resultsBox5Value}
                            isClicked={currentSummaryTile === 5}
                            className="theme-mini-card gm-ruby border-width-3"
                            onClick={() => {
                                this.handleSummaryTileClick('5', 5)
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            title={resultsBox6Title}
                            data={resultsBox6Value}
                            isClicked={currentSummaryTile === 6}
                            className="theme-mini-card gm-dark-orchid border-width-3"
                            onClick={() => {
                                this.handleSummaryTileClick('6', 6)
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            className="theme-mini-card gm-steel-teal border-width-3"
                            title={resultsBox7Title}
                            data={resultsBox7Value}
                            isClicked={currentSummaryTile === 7}
                            onClick={() => {
                                this.setState({
                                    invoiceList: this.state.items,
                                    currentSummaryTile: 7
                                })
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="xxl-3"
                    >
                        <SummaryInfo
                            className="theme-mini-card gm-dark-red border-width-3"
                            title={resultsBox8Title}
                            data={resultsBox8Value}
                            isClicked={currentSummaryTile === 8}
                            onClick={() => {
                                this.handleSummaryTileClick(
                                    '_TOTALOUTSTANDING',
                                    8
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }

    isDueDateView = () => {
        const { dateView } = this.state
        return dateView === 'Due Date'
    }

    dateFromLabel = () => {
        return this.isDueDateView() ? 'Due Date From' : 'Post Date From'
    }

    dateToLabel = () => {
        return this.isDueDateView() ? 'Due Date To' : 'Post Date To'
    }

    render() {
        const { classes } = this.props
        const {
            clients,
            clientId,
            fromDate,
            toDate,
            postDateTo,
            paymentStatus,
            transactionType,
            dateView,
            labeledInvoice,
            selectedDate,
            selectedDateHelperText,
            downloadCSVDisabled,
            organization,
            itemReferenceError,
            itemReferenceErrorMessage,
            dateFiltering
        } = this.state
        const backgroundColor =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#ffffff'
        const textColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'
        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'
        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'
        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#000000'
        let contents = this.state.loading ? (
            <LinearProgress color="primary" />
        ) : (
            <div>
                <CardContent className="p-0">
                    <Paper className="paper page-cover-height-200px">
                        <Grid container spacing={1}>
                            <div className="p-3 mb-4 w-100 d-flex mobile-mode-no-flex">
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    className=" pb-0"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        className="mb-4 searchshipment-col-padding mt-2 "
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-3"
                                        >
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-client">
                                                    Client
                                                </InputLabel>
                                                <ClientSelector
                                                    clients={clients}
                                                    selectedClientId={
                                                        clientId
                                                            ? clientId
                                                            : clients[0]?.id
                                                    }
                                                    handleClientSelection={
                                                        this
                                                            .handleClientSelectChange
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-3"
                                        >
                                            <FormControl className="mx-auto">
                                                <InputLabel id="select-label-client">
                                                    Labeled Invoice #
                                                </InputLabel>
                                                <TextField
                                                    error={itemReferenceError}
                                                    helperText={
                                                        itemReferenceErrorMessage
                                                    }
                                                    size="medium"
                                                    id="labeledInvoice"
                                                    placeholder="Labeled Invoice #"
                                                    value={labeledInvoice}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'labeledInvoice'
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-3"
                                        >
                                            <FormControl className="mx-auto">
                                                <InputLabel
                                                    className="mx-auto my-auto"
                                                    id="select-label-dateView"
                                                >
                                                    Date View
                                                </InputLabel>
                                                <Select
                                                    disabled={
                                                        selectedDate === 10
                                                            ? true
                                                            : false
                                                    }
                                                    style={{
                                                        width: '100px'
                                                    }}
                                                    labelId="select-label-dateView"
                                                    label="Labeled Invoice #"
                                                    id="dateView"
                                                    value={dateView}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'dateView'
                                                        )
                                                    }
                                                >
                                                    <MenuItem
                                                        value={'Due Date'}
                                                    >
                                                        Due Date
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'Post Date'}
                                                    >
                                                        Post Date
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-3"
                                        >
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel id="select-label-date">
                                                        Date
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-date"
                                                        value={selectedDate}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'selectedDate'
                                                            )
                                                        }
                                                    >
                                                        {dateFiltering.map(
                                                            function (date, i) {
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={
                                                                            date.id
                                                                        }
                                                                    >
                                                                        {
                                                                            date.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {selectedDateHelperText}
                                                    </FormHelperText>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-3"
                                        >
                                            <FormControl className="calender-icon-controller">
                                                <div>
                                                    <InputLabel id="select-label-date1">
                                                        {this.dateFromLabel()}
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            disabled={
                                                                selectedDate ===
                                                                10
                                                                    ? true
                                                                    : false
                                                            }
                                                            autoOk
                                                            format={
                                                                organization.pickerDateFormat
                                                            }
                                                            labelid="select-label-date1"
                                                            value={fromDate}
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'fromDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-3"
                                        >
                                            <FormControl className="calender-icon-controller">
                                                <div>
                                                    <InputLabel id="select-label-date2">
                                                        {this.dateToLabel()}
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider
                                                        className={
                                                            classes.textField
                                                        }
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            disabled={
                                                                selectedDate ===
                                                                10
                                                                    ? true
                                                                    : false
                                                            }
                                                            autoOk
                                                            format={
                                                                organization.pickerDateFormat
                                                            }
                                                            labelid="select-label-date2"
                                                            minDate={fromDate}
                                                            value={toDate}
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'toDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-2"
                                        >
                                            <FormControl className="mx-auto p-1">
                                                <div>
                                                    <InputLabel id="select-label-paymentStatus">
                                                        Paid Status
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-paymentStatus"
                                                        id="paymentStatus"
                                                        value={paymentStatus}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'paymentStatus'
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'Paid'}
                                                        >
                                                            Paid
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'Unpaid'}
                                                        >
                                                            Unpaid
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 mb-2"
                                        >
                                            <FormControl>
                                                <div>
                                                    <InputLabel id="select-label-transactionType">
                                                        Transaction Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-transactionType"
                                                        id="transactionType"
                                                        value={transactionType}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'transactionType'
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                        <MenuItem value={'CRD'}>
                                                            CRD
                                                        </MenuItem>
                                                        <MenuItem value={'INV'}>
                                                            INV
                                                        </MenuItem>
                                                        <MenuItem value={'REC'}>
                                                            REC
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3 d-none-normal"
                                        ></Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3"
                                        >
                                            <Button
                                                className="MuiButton-containedPrimary submit w-100"
                                                style={{
                                                    background: primarycolor,
                                                    color: textColor
                                                }}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                disabled={this.state.searching}
                                                onClick={e =>
                                                    this.handleSubmit(true)
                                                }
                                            >
                                                {this.state.searching
                                                    ? 'Loading...'
                                                    : 'QUICK SEARCH'}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="xxl-3"
                                        >
                                            <></>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.error && (
                                                <Grid item xs={6}>
                                                    <Alert
                                                        className="mb-4"
                                                        onClose={() => {
                                                            this.setState({
                                                                error: false
                                                            })
                                                        }}
                                                        severity="error"
                                                    >
                                                        {' '}
                                                        Sorry, there was an
                                                        error while retrieving
                                                        items.
                                                    </Alert>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        space={4}
                                        spacing={4}
                                        className="pl-3"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className="p-3  pr-0"
                                        >
                                            {this.renderSummary()}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid container spacing={0} className="w-100 px-3">
                                <div className="psa-shipment-table custom-table-styles w-100">
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mb-4 w-100"
                                    >
                                        <Grid item xs={12}>
                                            <div class="first-row-filter-hidden">
                                                <TableViewManagementDialog
                                                    viewCode={
                                                        'AccountingSearchGrid'
                                                    }
                                                    viewName={
                                                        'Accounting Search'
                                                    }
                                                    fontColor={textColor}
                                                    backgroundColor={
                                                        backgroundColor
                                                    }
                                                    successCallback={
                                                        this.getOrgHeaderColumns
                                                    }
                                                    clientId={clientId}
                                                />
                                                <TableViewCreateDialog
                                                    fontColor={textColor}
                                                    backgroundColor={
                                                        backgroundColor
                                                    }
                                                />
                                                {this.renderAccountingTable(
                                                    this.state.invoiceList,
                                                    this.state.page,
                                                    this.state.rowsPerPage,
                                                    this.handleChangePage,
                                                    this.handleChangeRowsPerPage
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </CardContent>
            </div>
        )

        return <div>{contents}</div>
    }

    async populateClientsData() {
        const token = await authService.getAccessToken()
        const { key } = this.state
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch(
            'api/clients/getclientslist?module=accounting',
            headers
        )

        if (response.ok) {
            const data = await response.json()
            this.setState({ clients: data }, () => {
                const { clients } = this.state
                const cachedClientId = localStorage.getItem(key + '.clientId')
                if (cachedClientId) {
                    // see if cached client id is in clients array
                    const client = clients.find(
                        client => client.id === cachedClientId
                    )
                    if (client) {
                        this.setState({ clientId: cachedClientId }, () => {
                            this.runClientDependentFunctions()
                        })
                    } else {
                        // set client id to first client in clients array
                        this.setState({ clientId: clients[0].id }, () => {
                            localStorage.setItem(
                                key + '.clientId',
                                clients[0].id
                            )
                            this.runClientDependentFunctions()
                        })
                    }
                } else {
                    // set client id to first client in clients array
                    this.setState({ clientId: clients[0].id }, () => {
                        localStorage.setItem(key + '.clientId', clients[0].id)
                        this.runClientDependentFunctions()
                    })
                }
            })
        } else {
            this.setState({ loading: false, error: true })
        }
    }

    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({
            organization: data,
            dateFiltering: data.dateFilterTypeArray
        })
    }

    getQueryString = (initialLoad = false) => {
        const {
            clientId,
            paymentStatus,
            transactionType,
            fromDate,
            toDate,
            labeledInvoice,
            selectedDate
        } = this.state

        const queryString = require('query-string')

        var qs = {}

        qs['TransactionType'] = transactionType
        qs['PaymentStatus'] = paymentStatus

        if (labeledInvoice.length > 0) {
            qs['AH_DescSearch'] = labeledInvoice
        }

        if (fromDate && selectedDate !== 10){
            const dateFromKey = this.isDueDateView()
                ? 'DueFromDate'
                : 'PostFromDate'
            qs[dateFromKey] = fromDate.toISOString(false)
        }

        if (toDate && selectedDate !== 10){
            const dateToKey = this.isDueDateView() ? 'DueToDate' : 'PostToDate'
            qs[dateToKey] = toDate.toISOString(false)
        }

        if (clientId !== null) {
            qs['ClientId'] = clientId
        }

        if (initialLoad) {
            qs['InitialLoad'] = true
        }

        qs['SelectedDate'] = selectedDate

        return queryString.stringify(qs)
    }

    async populateTableData(initialLoad = false) {
        // set summary tiles back to default
        this.setState({ currentSummaryTile: 1 })

        const { clientId, orderClients } = this.state
        const orderClient = orderClients?.find(c => c.id === clientId)

        this.setState({ showOrders: orderClient ? true : false })
        this.setState({ searching: true })

        const token = await authService.getAccessToken()

        if (clientId !== null) {
            this.setState({ error: false })

            const headers = {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }

            const response = await fetch(
                `api/Accounting/GetInvoices?` +
                    this.getQueryString(initialLoad),
                headers
            )

            if (response.ok) {
                const data = await response.json()
                this.setState({
                    items: data.invoices,
                    invoiceList: data.invoices,
                    loading: false,
                    resultsBox1Value: data.resultsBox1Value,
                    resultsBox1Title: data.resultsBox1Title,
                    resultsBox2Value: data.resultsBox2Value,
                    resultsBox2Title: data.resultsBox2Title,
                    resultsBox3Value: data.resultsBox3Value,
                    resultsBox3Title: data.resultsBox3Title,
                    resultsBox4Value: data.resultsBox4Value,
                    resultsBox4Title: data.resultsBox4Title,
                    resultsBox5Value: data.resultsBox5Value,
                    resultsBox5Title: data.resultsBox5Title,
                    resultsBox6Value: data.resultsBox6Value,
                    resultsBox6Title: data.resultsBox6Title,
                    resultsBox7Value: data.resultsBox7Value,
                    resultsBox7Title: data.resultsBox7Title,
                    resultsBox8Value: data.resultsBox8Value,
                    resultsBox8Title: data.resultsBox8Title
                })
            } else {
                this.setState({ loading: false, error: true })
            }
        } else {
            this.setState({ loading: false })
        }

        this.setState({ searching: false })
    }
}
export default withStyles(styles)(AccountingTable)
