import React, { useState, useEffect } from 'react'
import authService from '../api-authorization/AuthorizeService'
import { makeStyles } from '@material-ui/core/styles'
import {
    FormControl,
    TextField,
    Button,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    CardContent,
    Grid
} from '@material-ui/core'
import AlertMessage from '../shared/Alert'
import { useForm, Controller } from 'react-hook-form'
import _, { each, set } from 'lodash'
import { deleteDataMapping } from '../../utils/data-mapping'
import Paper from '@material-ui/core/Paper'
import Alert from '@material-ui/lab/Alert'
import MaterialTable from 'material-table'
import { ThemeProvider, createTheme } from '@mui/material'

const classes = makeStyles(theme => ({}))

export default function DataMappingEdit(props) {
    const { handleSubmit, errors, control, setValue } = useForm()
    const [typesMenu, setTypesMenu] = useState([])
    const [firstData, setFirstData] = useState(null) // item
    const [secondData, setSecondData] = useState([]) // array
    const [links, setLinks] = useState([])
    const [typeRules, setTypeRules] = useState([])
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        message: ''
    })
    const [isNewMap, setIsNewMap] = useState(false)
    const [open, setOpen] = React.useState(false)
    const tableTheme = createTheme()

    useEffect(() => {
        populateTypeMenus()

        if (props.match.params.propertyType && props.match.params.propertyKey) {
            getDataItem(
                props.match.params.propertyType,
                props.match.params.propertyKey,
                false
            )
        } else {
            setIsNewMap(true)
        }
    }, [])

    useEffect(() => {
        if (!firstData) return

        const firstDataId = firstData.id

        getExistingLinksForOrg(firstDataId).then(existingLinks => {
            setLinks(existingLinks)
        })
    }, [firstData])

    const renderAlert = () => {
        return alert.open ? (
            <div className="w-100">
                <Alert
                    variant="filled"
                    severity={alert.success ? 'success' : 'error'}
                    onClose={() => {
                        handleCloseAlert()
                    }}
                >
                    {alert.message}
                </Alert>
            </div>
        ) : null
    }

    const renderInput = (
        label,
        stateValue,
        required = true,
        disabled = false,
        maxLength
    ) => {
        return (
            <FormControl style={{ width: '100%' }}>
                <Grid container spacing={0} alignItems="flex-end">
                    <Grid item style={{ width: '100%' }}>
                        <Controller
                            rules={{
                                required: {
                                    value: required,
                                    message: 'This field is required.'
                                },
                                maxLength: {
                                    value: maxLength,
                                    message:
                                        'This field cannot exceed ' +
                                        maxLength +
                                        ' characters.'
                                }
                            }}
                            name={stateValue}
                            control={control}
                            defaultValue={''}
                            as={
                                <TextField
                                    style={{ width: '80%' }}
                                    label={label}
                                    placeholder={`Enter ${label}`}
                                    disabled={disabled}
                                />
                            }
                        />
                        {errors[stateValue] && (
                            <p className="m-0 message-validation">
                                {errors[stateValue].message}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </FormControl>
        )
    }

    const renderTitle = (title, width = 12) => {
        return (
            <Grid item xs={width}>
                <div className="row">
                    <Grid container>
                        <Grid item xs>
                            <Typography gutterBottom variant="h6">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        )
    }

    const populateTypeMenus = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataTypes?dataTypesRequested=ALL',
            {
                headers: !token
                    ? {}
                    : {
                          Authorization: `Bearer ${token}`
                      }
            }
        )
        if (response.ok) {
            const data = await response.json()
            const uniqueTypes = _.uniqBy(data, 'propertyType')
            setTypesMenu(uniqueTypes)
        } else {
            console.log('error GetDataTypes')
        }
    }

    const getDataItem = async (type, key, isChange) => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataType?dataType=' +
                type +
                '&dataKey=' +
                key,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()

            if (!isChange) {
                setValue('propertyKey', data.propertyKey)
                setValue('value', data.value)
                setValue('isAvailable', data.isAvailable)
                setValue('isDefault', data.isDefault)
            }
            setValue('propertyType', data.propertyType)
            setValue('maxLength', data.maxLength)

            setFirstData(data)
        } else {
            console.log('error GetDataType')
        }
    }

    const handleCreateLinksClickOpen = async () => {
        await GetDataTypeLinkRules()
        setOpen(true)
    }

    const GetDataTypeLinkRules = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataTypeLinkRules',
            {
                headers: !token
                    ? {}
                    : {
                          Authorization: `Bearer ${token}`
                      }
            }
        )

        if (response.ok) {
            var rules = await response.json()
            setTypeRules(rules)
            return
        }
        return null
    }

    const handleCreateLinksClose = () => {
        setOpen(false)
    }

    const populateKeyMenus = async typeSelected => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            `api/DataManagement/GetRelevantKeys?selectedLinkType=${typeSelected}&dataMappingType=${props.match.params.propertyType}`,
            {
                headers: !token
                    ? {}
                    : {
                          Authorization: `Bearer ${token}`
                      }
            }
        )

        if (response.ok) {
            let newSecondData = await response.json()

            const linkInfo = await getExistingLinksForOrg(firstData.id)

            setLinks(linkInfo)

            newSecondData.map(x => {
                x.checked = linkInfo.some(y => y.secondDataId === x.id)
            })
            setSecondData(newSecondData)
        } else {
            console.log('error GetRelevantKeys')
        }
    }

    const getExistingLinksForOrg = async firstDataId => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetExistingLinksForOrg?firstDataId=' +
                firstDataId,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
        if (response.ok) {
            const existingLinks = await response.json()
            return existingLinks
        } else {
            console.log('error GetExistingLinksForOrg')
            return null
        }
    }

    const handleSecondDataKeyChanged = async (e, secondDataItem) => {
        secondDataItem.checked = e.target.checked
        var updatedSecoundData = secondData.map(x =>
            x.id === secondDataItem.id ? secondDataItem : x
        )
        setSecondData(updatedSecoundData)
    }

    const handleSaveLinks = async () => {
        if (firstData.id == null) {
            setAlert({
                open: true,
                success: false,
                message: 'Please select the first Data Type and Code.'
            })
            return
        } else {
            const requestBody = JSON.stringify({
                FirstDataId: firstData.id,
                SecondDataArray: secondData
            })
            const token = await authService.getAccessToken()
            await fetch('api/DataManagement/PostOrRemoveDataIdsToLinkTable', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: requestBody
            })
                .then(response => {
                    if (response.ok) {
                        setAlert({
                            open: true,
                            success: true,
                            message: 'Data mapping links are saved.'
                        })

                        getExistingLinksForOrg(firstData.id).then(
                            existingLinks => {
                                setLinks(existingLinks)
                            }
                        )
                    }
                })
                .catch(() => {
                    setAlert({
                        open: true,
                        success: false,
                        message:
                            'Sorry, there was an error while saving this details.'
                    })
                })
        }
    }

    const saveDataItem = async data => {
        data.id = firstData.id
        const token = await authService.getAccessToken()
        await fetch('api/dataManagement/PutDataType', {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                let results = null

                if (response.ok) {
                    deleteDataMapping()

                    setAlert({
                        open: true,
                        success: true,
                        message:
                            'Data mapping item information is saved. Redirecting...'
                    })

                    setTimeout(() => {
                        window.location.replace('/admin/data_mapping')
                    }, 3000)

                    results = response.json()
                } else {
                    setAlert({
                        open: true,
                        success: false,
                        message:
                            'Sorry, there was an error while saving this details.'
                    })

                    results = response.text()
                }
                return results
            })
            .catch(error => {
                setAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while saving this Shipment information.'
                })
            })
    }

    const handleFormSubmit = data => {
        saveDataItem(prepareData(data))
    }

    const prepareData = data => {
        const newData = _.clone(_.omitBy(data, _.isNil))
        return newData
    }

    const handleCloseAlert = () => {
        setAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleRemoveRowIconClick = async (e, rowData) => {
        if (!window.confirm('Are you sure you want to remove this link?')) {
            return
        }

        const token = await authService.getAccessToken()
        await fetch(
            'api/DataManagement/DeleteDataTypeLink?linkId=' + rowData.id,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                if (response.ok) {
                    setAlert({
                        open: true,
                        success: true,
                        message: 'Data mapping link is removed.'
                    })

                    getExistingLinksForOrg(firstData.id).then(existingLinks => {
                        setLinks(existingLinks)
                    })
                }
            })
            .catch(() => {
                setAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while removing this link.'
                })
            })
    }

    return (
        <div>
            <CardContent>
                {renderAlert()}
                <Paper className="paper p-4 ">
                    <form
                        className={classes.container}
                        onSubmit={handleSubmit(handleFormSubmit)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={1}>
                            <Grid
                                container
                                xs={12}
                                className="mb-0"
                                spacing={0}
                                class="mb-4 w-100"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    direction="column"
                                    spacing={4}
                                >
                                    <div class="p-34">
                                        <Grid
                                            container
                                            direction="row"
                                            space={2}
                                            spacing={2}
                                            className="my-4"
                                        >
                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={6}
                                                xs={12}
                                                className="xxl-2"
                                            >
                                                <FormControl>
                                                    <InputLabel id="select-label">
                                                        Data Type
                                                    </InputLabel>
                                                    <Controller
                                                        rules={{
                                                            required:
                                                                'This field is required.'
                                                        }}
                                                        name={'propertyType'}
                                                        control={control}
                                                        defaultValue={
                                                            props.match.params
                                                                .propertyType
                                                        }
                                                        render={({
                                                            onChange,
                                                            onBlur,
                                                            value,
                                                            name
                                                        }) => (
                                                            <Select
                                                                labelId="select-label"
                                                                id="select-id"
                                                                classes={
                                                                    classes.select
                                                                }
                                                                style={{
                                                                    width: 300
                                                                }}
                                                                defaultValue={
                                                                    value
                                                                }
                                                                onChange={e => {
                                                                    onChange(e)
                                                                    getDataItem(
                                                                        e.target
                                                                            .value,
                                                                        '',
                                                                        true
                                                                    )
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'Without label'
                                                                }}
                                                            >
                                                                {typesMenu.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    item.propertyType
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.propertyType
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    }
                                                                )}
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={6}
                                                xs={12}
                                                className="xxl-2"
                                            >
                                                {renderInput(
                                                    'Code' +
                                                        (firstData?.maxLength
                                                            ? ' (Max ' +
                                                              firstData?.maxLength +
                                                              ' characters)'
                                                            : ''),
                                                    'propertyKey',
                                                    true,
                                                    false,
                                                    firstData?.maxLength
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={6}
                                                xs={12}
                                                className="xxl-2"
                                            >
                                                {renderInput(
                                                    'Description',
                                                    'value',
                                                    true,
                                                    false
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={6}
                                                xs={12}
                                                className="xxl-2"
                                            >
                                                {renderInput(
                                                    'Max Code Length',
                                                    'maxLength',
                                                    false,
                                                    true
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                className="xxl-2"
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={'isAvailable'}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name
                                                            }) => (
                                                                <Checkbox
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    onChange={e =>
                                                                        onChange(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        )
                                                                    }
                                                                    checked={
                                                                        value
                                                                    }
                                                                    name={name}
                                                                    color="default"
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label="Is Visible"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={6}
                                                className="xxl-2"
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={'isDefault'}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name
                                                            }) => (
                                                                <Checkbox
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    onChange={e =>
                                                                        onChange(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        )
                                                                    }
                                                                    checked={
                                                                        value
                                                                    }
                                                                    name={name}
                                                                    color="default"
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label="Is Default"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className="xxl-2"
                                            >
                                                <div class="d-flex justify-content-end w-100 xs-justify-content-start">
                                                    {props.match.params
                                                        .propertyType && (
                                                        <Button
                                                            variant="contained"
                                                            color="secondry"
                                                            onClick={
                                                                handleCreateLinksClickOpen
                                                            }
                                                        >
                                                            Data Type Links
                                                        </Button>
                                                    )}
                                                    <Dialog
                                                        fullWidth
                                                        open={open}
                                                        onClose={
                                                            handleCreateLinksClose
                                                        }
                                                    >
                                                        <DialogTitle
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                color: props.fontColor,
                                                                backgroundColor:
                                                                    props.backgroundColor
                                                            }}
                                                        >
                                                            {
                                                                'Create Data Type Links'
                                                            }
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                justifyContent="space-evenly"
                                                                alignItems="center"
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="h6"
                                                                        style={{
                                                                            margin: '10px'
                                                                        }}
                                                                    >
                                                                        Available
                                                                        Data
                                                                        Types
                                                                    </Typography>
                                                                    <Select
                                                                        labelId="select-label"
                                                                        id="select-id"
                                                                        classes={
                                                                            classes.select
                                                                        }
                                                                        style={{
                                                                            width: 300
                                                                        }}
                                                                        defaultValue={
                                                                            ''
                                                                        }
                                                                        onChange={e => {
                                                                            populateKeyMenus(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }}
                                                                        displayEmpty
                                                                        inputProps={{
                                                                            'aria-label':
                                                                                'Without label'
                                                                        }}
                                                                    >
                                                                        {typesMenu
                                                                            .filter(
                                                                                x =>
                                                                                    x.propertyType !==
                                                                                    props
                                                                                        .match
                                                                                        .params
                                                                                        .propertyType
                                                                            )
                                                                            .filter(
                                                                                y =>
                                                                                    typeRules.filter(
                                                                                        z =>
                                                                                            z.firstDataType ===
                                                                                                props
                                                                                                    .match
                                                                                                    .params
                                                                                                    .propertyType &&
                                                                                            z.secondDataType ===
                                                                                                y.propertyType
                                                                                    )
                                                                                        .length >
                                                                                    0
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        value={
                                                                                            item.propertyType
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.propertyType
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            )}
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="h6"
                                                                        style={{
                                                                            margin: '10px'
                                                                        }}
                                                                    >
                                                                        Available
                                                                        Codes
                                                                    </Typography>
                                                                    <Select
                                                                        labelId="select-label"
                                                                        id="select-id"
                                                                        classes={
                                                                            classes.select
                                                                        }
                                                                        style={{
                                                                            width: 300
                                                                        }}
                                                                        defaultValue={
                                                                            ''
                                                                        }
                                                                        displayEmpty
                                                                        inputProps={{
                                                                            'aria-label':
                                                                                'Without label'
                                                                        }}
                                                                    >
                                                                        {secondData.map(
                                                                            (
                                                                                secondDataItem,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        value={
                                                                                            secondDataItem.propertyKey
                                                                                        }
                                                                                    >
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                secondDataItem.checked
                                                                                            }
                                                                                            onChange={e =>
                                                                                                handleSecondDataKeyChanged(
                                                                                                    e,
                                                                                                    secondDataItem
                                                                                                )
                                                                                            }
                                                                                            onClick={e =>
                                                                                                e.stopPropagation()
                                                                                            }
                                                                                        />
                                                                                        {
                                                                                            secondDataItem.propertyKey
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                style={{
                                                                    margin: 'auto',
                                                                    justifyContent:
                                                                        'center',
                                                                    display:
                                                                        'flex',
                                                                    marginTop:
                                                                        '100px'
                                                                }}
                                                                onClick={
                                                                    handleSaveLinks
                                                                }
                                                            >
                                                                {' '}
                                                                Save Links
                                                            </Button>
                                                        </DialogContent>
                                                    </Dialog>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className="xxl-2"
                                            >
                                                <div class="d-flex justify-content-end w-100 xs-justify-content-start">
                                                    <Button
                                                        type="submit"
                                                        className={
                                                            classes.submit
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={props.onSubmit}
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </CardContent>
            <div style={{ paddingTop: '100px', paddingLeft: '15px' }}>
                <div className="psa-shipment-table">
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="mb-4">
                                <Paper elevation={0}>
                                    <ThemeProvider theme={tableTheme}>
                                        <MaterialTable
                                            title={'Existing Links'}
                                            columns={[
                                                {
                                                    title: 'First Data Type',
                                                    field: 'firstDataType'
                                                },
                                                {
                                                    title: 'First Data Key',
                                                    field: 'firstDataKey'
                                                },
                                                {
                                                    title: 'Second Data Type',
                                                    field: 'secondDataType'
                                                },
                                                {
                                                    title: 'Second Data Key',
                                                    field: 'secondDataKey'
                                                }
                                            ]}
                                            data={links}
                                            options={{
                                                filtering: true,
                                                maxBodyHeight:
                                                    'calc(100vh - 348px)',
                                                pageSizeOptions: [25, 50, 75],
                                                pageSize: 25,
                                                showTitle: true,
                                                showFirstLastPageButtons: true,
                                                toolbar: true,
                                                padding: 'dense',
                                                search: false
                                            }}
                                            actions={[
                                                {
                                                    icon: 'remove',
                                                    tooltip: 'Remove Link',
                                                    onClick: (e, rowData) =>
                                                        handleRemoveRowIconClick(
                                                            e,
                                                            rowData
                                                        )
                                                }
                                            ]}
                                        />
                                    </ThemeProvider>
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}
