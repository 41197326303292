import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const FooterNav = () => (
    <div className="custom-footer d-none  overflow-none">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <Typography
                    align={'center'}
                    gutterBottom
                    color={'textSecondary'}
                >
                    © Copyright 2024
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography
                    align={'center'}
                    gutterBottom
                    color={'textSecondary'}
                >
                    <a
                        target="_blank"
                        href="https://psashipping.blob.core.windows.net/pdfs/Terms%2520and%2520Conditions.pdf?sp=r&st=2020-05-07T18:13:53Z&se=2021-10-03T02:13:53Z&spr=https&sv=2019-10-10&sr=b&sig=H2%2BJs8Hb8AmDMUi%2FDu6ZupGvH%2B9WJsSy6nXUtOjWcTg%3D"
                    >
                        Terms & Conditions
                    </a>
                </Typography>
            </Grid>
        </Grid>
    </div>
)

FooterNav.propTypes = {}
FooterNav.defaultProps = {}

export default FooterNav
