import { isNotFclOrFtl } from '../../utils/shipment'

export const getNextEnabledBookingStep = (
    currentStep,
    bookingSteps,
    containerType
) => {
    bookingSteps = JSON.parse(bookingSteps)
    let nextStep = currentStep + 1
    while (nextStep < bookingSteps.length) {
        if (bookingSteps[nextStep].enabled) {
            if (nextStep === 3 && isNotFclOrFtl(containerType)) {
                return bookingSteps[4]
            } else {
                return bookingSteps[nextStep]
            }
        }
        nextStep++
    }
    return bookingSteps[nextStep]
}

export const getPreviousEnabledBookingStep = (
    currentStep,
    bookingSteps,
    containerType
) => {
    bookingSteps = JSON.parse(bookingSteps)
    let previousStep = currentStep - 1
    while (previousStep >= 0) {
        if (bookingSteps[previousStep].enabled) {
            if (previousStep === 3 && isNotFclOrFtl(containerType)) {
                // continue
            } else {
                return bookingSteps[previousStep]
            }
        }
        previousStep--
    }
    return bookingSteps[previousStep]
}

export const hasAccessToStep = (step, bookingSteps, containerType) => {
    bookingSteps = JSON.parse(bookingSteps)
    if (step === 3 && isNotFclOrFtl(containerType)) {
        return false
    }
    return bookingSteps[step].enabled
}
