import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import authService from '../api-authorization/AuthorizeService'
import { LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
    retrieveViewDefinitions,
    saveViewColumns,
    createView,
    deleteView,
    updateAllViewColumnDefinitionMetaData,
    retrieveView
} from './Views'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useEffect } from 'react'
import { Select, MenuItem, InputLabel } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1)
}

function union(a, b) {
    return [...a, ...not(b, a)]
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    docTypeSelect: {},
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    editColumnTextField: {
        width: '40ch'
    }
}))

export default function TableViewManagementDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [successMessage, setSuccessMessage] = React.useState('View Saved!')
    const [isCreateSuccess, setIsCreateSuccess] = React.useState(false)
    const [isError, setIsError] = React.useState(false)
    const [creatingView, setCreatingView] = React.useState(false)
    const [isCreateViewError, setIsCreateViewError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [createViewErrorMessage, setCreateViewErrorMessage] =
        React.useState('')
    const [tableView, setTableView] = React.useState(null)
    const [hiddenColumns, setHiddenColumns] = React.useState([])
    const [visibleColumns, setVisibleColumns] = React.useState([])
    const [selectedColumn, setSelectedColumn] = React.useState('')
    const [changesMade, setChangesMade] = React.useState(false)
    const [adminUser, setAdminUser] = React.useState(false)
    const [superAdminUser, setSuperAdminUser] = React.useState(false)
    const [checked, setChecked] = React.useState([])
    const [selectedView, setSelectedView] = React.useState(null)
    const [allViews, setAllViews] = React.useState([])
    const [promptForViewName, setPromptForViewName] = React.useState(false)
    const [editIconDialogOpen, setEditIconDialogOpen] = React.useState(false)
    const [addColumnDialog, setAddColumnDialog] = React.useState(false)
    const [appliedViewId, setAppliedViewId] = React.useState(null)

    useEffect(() => {
        async function fetchIsAdmin() {
            const userIsAdmin = await authService.isAdmin()
            const userIsSuperAdmin = await authService.isSuperAdmin()
            setAdminUser(userIsAdmin || userIsSuperAdmin)
            setSuperAdminUser(userIsSuperAdmin)
        }
        fetchIsAdmin()
    }, [])

    const { palette } = createTheme()
    const theme = createTheme({
        palette: {
            primary: {
                main: props.backgroundColor,
                contrastText: props.fontColor
            }
        }
    })

    const loadColumnHeaders = viewData => {
        const columnHeaders = viewData.columnDefinitionsArray
        // need to set visible and hidden column arrays based on the 'visible' property of the data
        // set visible columns
        const visibleColumns = columnHeaders?.filter(
            column => column.visible === true
        )
        setVisibleColumns(visibleColumns)
        // set hidden columns
        const hiddenColumns = columnHeaders?.filter(
            column => column.visible === false
        )
        setHiddenColumns(hiddenColumns)
        setTableView(columnHeaders)
    }

    const fetchTableViews = async () => {
        setIsLoading(true)
        const isUser = !adminUser && !superAdminUser
        const currentUserView = await retrieveView(
            props.clientId,
            props.viewCode
        )
        const data = await retrieveViewDefinitions(
            props.viewCode,
            props.clientId,
            isUser
        )
        if (!data) {
            setIsLoading(false)
            handleError('View not found.')
        } else {
            setAllViews(data)
            if (data.length > 0) {
                let currentViewById = data.find(
                    view => view.id === currentUserView.id
                )
                if (currentViewById) {
                    setSelectedView(currentViewById)
                    setAppliedViewId(currentViewById.id)
                    loadColumnHeaders(currentViewById)
                } else {
                    let currentViewByClientViewId = data.find(
                        view =>
                            view.clientViewId === currentUserView.clientViewId
                    )
                    if (currentViewByClientViewId) {
                        setSelectedView(currentViewByClientViewId)
                        setAppliedViewId(currentViewByClientViewId.id)
                        loadColumnHeaders(currentViewByClientViewId)
                    } else {
                        // shouldn't land here... but if it does, just set the first view in the list
                        setSelectedView(data[0])
                        loadColumnHeaders(data[0])
                    }
                }
            }
            setIsLoading(false)
        }
    }

    const handleViewNameClose = () => {
        setPromptForViewName(false)
        setIsLoading(false)
    }

    const handleViewNameSave = async () => {
        setCreatingView(true)
        const viewName = document.getElementById('viewName').value
        if (viewName === '') {
            alert('Please provide a name for the new view.')
            return
        }
        await createView(
            viewName,
            handleCreateViewSuccess,
            handleCreateViewError
        )
    }

    const renderAddColumnDialog = () => {
        return (
            <Dialog
                open={addColumnDialog}
                onClose={() => setAddColumnDialog(false)}
            >
                <DialogTitle>Add Column</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="newColumnTitle"
                        label="Title"
                        type="text"
                        fullWidth
                    />
                    <br />
                    <TextField
                        margin="normal"
                        id="newColumnValue"
                        label="Field"
                        type="text"
                        fullWidth
                    />
                    <br />
                    <TextField
                        margin="normal"
                        id="newColumnType"
                        label="Data Type"
                        type="text"
                        fullWidth
                    />
                    <br />
                    <TextField
                        margin="normal"
                        id="newColumnSortField"
                        label="Sort Field"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setAddColumnDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleAddColumn} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleAddColumn = () => {
        const columnTitle = document.getElementById('newColumnTitle').value
        const columnValue = document.getElementById('newColumnValue').value
        const columnType = document.getElementById('newColumnType').value
        const columnSortField =
            document.getElementById('newColumnSortField').value

        const newColumn = {
            id: '00000000-0000-0000-0000-000000000000',
            title: columnTitle,
            value: columnValue,
            type: columnType,
            sortField: columnSortField,
            visible: false
        }

        setHiddenColumns([...hiddenColumns, newColumn])
        setAddColumnDialog(false)
        setChangesMade(true)
    }

    const handleDrop = droppedItem => {
        setChangesMade(true)
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return
        var updatedList = [...visibleColumns]
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
        // Update State
        setVisibleColumns(updatedList)
    }

    const handleCustomizeClickOpen = async () => {
        await fetchTableViews()
        setOpen(true)
    }

    const handleCreateClickOpen = async () => {
        setPromptForViewName(true)
    }

    const handleClose = () => {
        if (changesMade) {
            if (!window.confirm('Cancel without saving changes?')) {
                return
            } else {
                setChangesMade(false)
            }
        }
        setIsError(false)
        setErrorMessage('')
        setIsSuccess(false)
        setOpen(false)
    }

    const handleSuccess = successMessage => {
        setChangesMade(false)
        setSuccessMessage(successMessage)
        setIsSuccess(true)
        setInterval(function () {
            // call callback function passed in from props
            props.successCallback()
            setIsSuccess(false)
        }, 3000)
    }

    const handleCreateViewSuccess = () => {
        setIsCreateSuccess(true)
        setInterval(function () {
            // call callback function passed in from props
            props.successCallback()
            setIsCreateSuccess(false)
            setPromptForViewName(false)
            setCreatingView(false)
        }, 3000)
    }

    const updateViewMetaData = async () => {
        setIsLoading(true)
        var result = await updateAllViewColumnDefinitionMetaData()
        setIsLoading(false)
        if (result) {
            setIsSuccess(true)
            setSuccessMessage('View Metadata Updated!')
        } else {
            handleError('Error updating view metadata.')
        }
    }

    const handleDeleteError = errorMessage => {
        setErrorMessage(errorMessage)
        setIsError(true)
        setIsLoading(false)
    }

    const handleDeleteSuccess = () => {
        setIsLoading(false)
        handleSuccess('View Deleted!')
        fetchTableViews()
    }

    const handleError = errorMessage => {
        setErrorMessage(errorMessage)
        setIsError(true)
    }

    const handleCreateViewError = errorMessage => {
        setCreateViewErrorMessage(errorMessage)
        setIsCreateViewError(true)
        setCreatingView(false)
    }

    const handleSubmission = async () => {
        setIsLoading(true)
        const allColumns = [...visibleColumns, ...hiddenColumns]
        const isUserView = !adminUser && !superAdminUser
        await saveViewColumns(
            props.viewCode,
            allColumns,
            selectedView?.id,
            selectedView?.clientViewId,
            isUserView,
            props.clientId
        )
        setIsLoading(false)
        handleSuccess('View Saved!')
    }

    const handleEditColumnDialogClose = () => {
        setEditIconDialogOpen(false)
    }

    const handleEditColumnDialogSubmission = () => {
        // need to update the column name and other properties
        // need to update the state of the visible and hidden columns
        // need to close the dialog
        // need to set changes made to true

        // get the values from the text fields
        const columnTitle = document.getElementById('editColumnTitle').value
        const columnValue = document.getElementById('editColumnValue').value
        const columnType = document.getElementById('editColumnType').value
        const columnSortField = document.getElementById(
            'editColumnSortField'
        ).value

        // find the column in the visible or hidden columns
        const updatedVisibleColumns = visibleColumns.map(column => {
            if (column.value === selectedColumn.value) {
                column.title = columnTitle
                column.value = columnValue
                column.type = columnType
                column.sortField = columnSortField
            }
            return column
        })

        const updatedHiddenColumns = hiddenColumns.map(column => {
            if (column.value === selectedColumn.value) {
                column.title = columnTitle
                column.value = columnValue
                column.type = columnType
                column.sortField = columnSortField
            }
            return column
        })

        setVisibleColumns(updatedVisibleColumns)
        setHiddenColumns(updatedHiddenColumns)

        setEditIconDialogOpen(false)
        setChangesMade(true)
    }

    const renderColumnHeaderEditDialog = () => {
        // need to render a new dialog to show when the user clicks the edit icon on a specific column
        // this dialog will allow the user to change the column name and other properties
        // the dialog will have a text field for the column name, a checkbox for visible, and a checkbox for required
        // the dialog will have a save and cancel button
        // the dialog will have a delete button if the column is not required
        // the dialog will have a close button

        return (
            <Dialog
                open={editIconDialogOpen}
                onClose={handleEditColumnDialogClose}
            >
                <DialogTitle>Edit Column Properties</DialogTitle>
                <DialogContent>
                    <TextField
                        id="editColumnTitle"
                        label="Title"
                        className={classes.editColumnTextField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        defaultValue={selectedColumn?.title}
                        margin="normal"
                    />
                    <br />
                    <TextField
                        id="editColumnValue"
                        label="Field"
                        className={classes.editColumnTextField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        defaultValue={selectedColumn?.value}
                        margin="normal"
                    />
                    <br />
                    <TextField
                        id="editColumnType"
                        label="Data Type"
                        className={classes.editColumnTextField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        defaultValue={selectedColumn?.type}
                        margin="normal"
                    />
                    <br />
                    <TextField
                        id="editColumnSortField"
                        label="Sort Field"
                        className={classes.editColumnTextField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        defaultValue={selectedColumn?.sortField}
                        margin="normal"
                    />
                </DialogContent>
                <ThemeProvider theme={theme}>
                    <DialogActions>
                        <Button
                            onClick={handleEditColumnDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleEditColumnDialogSubmission}
                            color="primary"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </ThemeProvider>
            </Dialog>
        )
    }

    const handleDelete = async () => {
        if (
            !window.confirm(
                'Are you sure you want to delete this view? \nAny clients assigned to this view will be reassigned the default view.'
            )
        ) {
            return
        }
        setIsLoading(true)
        await deleteView(
            selectedView,
            handleDeleteSuccess,
            handleDeleteError,
            false
        )
    }

    const handleReset = async () => {
        if (
            !window.confirm(
                'Are you sure you want to reset your view? \nThis will revert your view to the default view.'
            )
        ) {
            return
        }
        setIsLoading(true)
        await deleteView(
            selectedView,
            handleDeleteSuccess,
            handleDeleteError,
            true
        )
    }

    const leftChecked = intersection(checked, hiddenColumns)
    const rightChecked = intersection(checked, visibleColumns)

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const numberOfChecked = items => intersection(checked, items).length

    const handleToggleAll = items => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items))
        } else {
            setChecked(union(checked, items))
        }
    }

    const handleCheckedRight = () => {
        setChangesMade(true)
        const leftCheckedUpdated = leftChecked.map(item => {
            item.visible = true
            return item
        })
        setVisibleColumns(leftCheckedUpdated.concat(visibleColumns))
        setHiddenColumns(not(hiddenColumns, leftChecked))
        setChecked(not(checked, leftChecked))
    }

    const handleCheckedLeft = () => {
        setChangesMade(true)
        const rightCheckedUpdated = rightChecked.map(item => {
            item.visible = false
            return item
        })

        setHiddenColumns(hiddenColumns.concat(rightCheckedUpdated))
        setVisibleColumns(not(visibleColumns, rightChecked))
        setChecked(not(checked, rightChecked))
    }

    const handleViewSelectChange = name => {
        if (changesMade) {
            if (!window.confirm('Cancel without saving changes?')) {
                return
            }
        }

        const selectedView = allViews.find(view => view.viewName === name)
        loadColumnHeaders(selectedView)
        setSelectedView(selectedView)
        setChangesMade(false)
    }

    const renderViewSelect = () => {
        return (
            adminUser && (
                <>
                    <InputLabel id="view-select-label">Select View</InputLabel>
                    <Select
                        labelId="select-view"
                        id="selectView"
                        value={selectedView?.viewName}
                        onChange={e => handleViewSelectChange(e.target.value)}
                    >
                        {allViews
                            ? allViews.map(function (view, i) {
                                  const isCurrentView =
                                      view.id === appliedViewId
                                  return (
                                      <MenuItem
                                          style={{
                                              color: isCurrentView
                                                  ? 'blue'
                                                  : 'black',
                                              weight: isCurrentView
                                                  ? 'bold'
                                                  : 'normal'
                                          }}
                                          key={i}
                                          value={view.viewName}
                                      >
                                          {view.viewName}
                                      </MenuItem>
                                  )
                              })
                            : null}
                    </Select>
                </>
            )
        )
    }

    const customList = (title, items) => (
        <Card>
            <CardHeader
                style={{ fontWeight: 'bold !important' }}
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={
                            numberOfChecked(items) === items?.length &&
                            items?.length !== 0
                        }
                        indeterminate={
                            numberOfChecked(items) !== items?.length &&
                            numberOfChecked(items) !== 0
                        }
                        disabled={items?.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected'
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${
                    items?.length
                } selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {provided => (
                        <List
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{
                                width: 300,
                                height: 600,
                                bgcolor: 'background.paper',
                                overflow: 'auto'
                            }}
                            dense
                            component="div"
                            role="list"
                        >
                            {items?.map((item, index) => {
                                const labelId = `transfer-list-all-item-${item.value}-label`

                                return (
                                    <Draggable
                                        key={item.value}
                                        draggableId={item.value}
                                        index={index}
                                    >
                                        {provided => (
                                            <div
                                                className="item-container"
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <ListItem
                                                    key={item.value}
                                                    role="listitem"
                                                    button
                                                    onClick={handleToggle(item)}
                                                >
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            checked={
                                                                checked.indexOf(
                                                                    item
                                                                ) !== -1
                                                            }
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{
                                                                'aria-labelledby':
                                                                    labelId
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        id={labelId}
                                                        primary={item.title}
                                                        secondary={item.value}
                                                    />
                                                    {superAdminUser && (
                                                        <ListItemSecondaryAction>
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="edit"
                                                                onClick={() => {
                                                                    setSelectedColumn(
                                                                        item
                                                                    )
                                                                    setEditIconDialogOpen(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    )}
                                                </ListItem>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </Card>
    )

    return (
        <>
            <IconButton
                style={{ float: 'left', zIndex: 99 }}
                color="inherit"
                size="small"
                onClick={handleCustomizeClickOpen}
                disableRipple
                disableFocusRipple
            >
                <EditIcon />
                Customize Views
            </IconButton>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle
                    style={{
                        textAlign: 'center',
                        color: props.fontColor,
                        backgroundColor: props.backgroundColor
                    }}
                >
                    {'Customize Columns: ' + props.viewName}
                </DialogTitle>
                <DialogContent>
                    {isLoading && <LinearProgress />}
                    {
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                {customList('Hidden Columns', hiddenColumns)}
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        Add &gt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt; Remove
                                    </Button>
                                    {superAdminUser && (
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setAddColumnDialog(true)
                                            }}
                                            aria-label="add column"
                                        >
                                            New +
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                {customList('Visible Columns', visibleColumns)}
                            </Grid>
                        </Grid>
                    }
                    <ThemeProvider theme={theme}>
                        <DialogActions>
                            {renderViewSelect()}
                            {superAdminUser && (
                                <Button
                                    sx={{ minWidth: '100px' }}
                                    onClick={updateViewMetaData}
                                    variant="outlined"
                                    disabled={isLoading}
                                >
                                    UPMTD
                                </Button>
                            )}
                            {adminUser && (
                                <Button
                                    sx={{ minWidth: '150px' }}
                                    onClick={handleDelete}
                                    variant="outlined"
                                    disabled={isLoading}
                                >
                                    Delete View
                                </Button>
                            )}
                            <Button
                                sx={{ minWidth: '100px' }}
                                onClick={handleClose}
                                variant="outlined"
                                disabled={isLoading}
                            >
                                Close
                            </Button>
                            {!adminUser && !superAdminUser && (
                                <Button
                                    sx={{ minWidth: '100px' }}
                                    onClick={handleReset}
                                    variant="outlined"
                                    disabled={
                                        isLoading || !selectedView?.userId
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                            <Button
                                onClick={handleSubmission}
                                color="primary"
                                variant="contained"
                                disabled={isLoading}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </ThemeProvider>
                    {isSuccess && (
                        <Alert severity="success" variant="filled">
                            {successMessage}
                        </Alert>
                    )}
                    {isError && (
                        <Alert
                            severity="error"
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setIsError(false)
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {errorMessage}
                        </Alert>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog open={promptForViewName} onClose={handleViewNameClose}>
                <DialogTitle>Enter New View Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="viewName"
                        label="View Name"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleViewNameClose}
                        color="primary"
                        disabled={creatingView}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleViewNameSave}
                        color="primary"
                        disabled={creatingView}
                    >
                        Save
                    </Button>
                </DialogActions>
                {isCreateSuccess && (
                    <Alert severity="success" variant="filled">
                        View Created!
                    </Alert>
                )}
                {isCreateViewError && (
                    <Alert
                        severity="error"
                        variant="filled"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setIsCreateViewError(false)
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {createViewErrorMessage}
                    </Alert>
                )}
            </Dialog>
            {renderColumnHeaderEditDialog()}
            {renderAddColumnDialog()}
        </>
    )
}
