import React from 'react'

const UnauthorizedMessage = ({ moduleName }) => {
    return (
        <div className="unauthorized-message">
            <h1>Sorry!</h1>
            <p>You don't have permission to view the {moduleName} module.</p>
        </div>
    )
}

export default UnauthorizedMessage
