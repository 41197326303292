import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import authService from './api-authorization/AuthorizeService'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import '../custom.css'

import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import * as pbi from 'powerbi-client'
import {
    MenuItem,
    Select,
    Input,
    InputLabel,
    FormControl,
    IconButton
} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { LinearProgress } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    reportTile: {
        minWidth: 275,
        cursor: 'pointer'
    }
})

export default function Report() {
    //static displayName = Report.name
    const classes = useStyles()
    const [reportsList, setReportlist] = useState([])
    const [selectedIndex, setIndexValue] = useState(0)
    const [reportId, setReportId] = useState('')
    const [datasetId, setDatasetId] = useState('')
    const [embedUrl, setEmbedUrl] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [colorThemeRGB, setcolorThemeRGB] = useState('')
    const [colorThemeTextRGB, setcolorThemeTextRGB] = useState('')
    const [colorThemePirmaryRGB, setcolorThemePirmaryRGB] = useState('')
    const [colorThemeSecondaryRGB, setcolorThemeSecondaryRGB] = useState('')
    const [colorThemeTertiaryRGB, setcolorThemeTertiaryRGB] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [reportName, setReportName] = useState('')
    const [isDownloading, setIsDownloading] = useState(false)

    const history = useHistory()

    useEffect(() => {
        getAppInfo()
    }, [])
    useEffect(() => {
        populateReportsData()
    }, [])

    const getAppInfo = async () => {

        setIsLoading(true)
        const token = await authService.getAccessToken()
        const response = await fetch('api/app/info', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            setcolorThemePirmaryRGB(data.colorThemePirmaryRGB)
            setcolorThemeSecondaryRGB(data.colorThemeSecondaryRGB)
            setcolorThemeTertiaryRGB(data.colorThemeTertiaryRGB)
            setcolorThemeRGB(data.colorThemeRGB)
            setcolorThemeTextRGB(data.colorThemeTextRGB)
        }

        setIsLoading(false)
    }

    const downloadReport = async (reportId, reportName) => {
        setIsDownloading(true)
        const token = await authService.getAccessToken()
       
        // get request to retrieve report file
        const response = await fetch('api/bireport/DownloadReport?reportId=' + reportId + '&datasetId=' + datasetId, {
            headers: !token ? {} : {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.ok) {
            // need to implement the download functionality
            const data = await response.blob()
            const filename = reportName + '.pdf'

            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        }
        setIsDownloading(false)

    }   
        


    //to get the reports list based on the login userid
    const populateReportsData = async () => {

        setIsLoading(true)

        const user = await authService.getUser()
        let userId = ''
        if (user) {
            userId = user.sub
        } else {
            userId = null
        }
        const token = await authService.getAccessToken()
        const response = await fetch('api/users/GetUserBIReports/' + userId, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            setReportlist(data)
            if (data.length > 0) {
                await populateReportData(
                    data[selectedIndex].groupId,
                    data[selectedIndex].reportId
                )
            }
        }

        setIsLoading(false)
    }

    const handleReportChange = async event => {
        setIsLoading(true)
        const index = event.target.value
        const report = reportsList.find(report => report.reportId === index)
        setIndexValue(index)

        await populateReportData(report.groupId, report.reportId)
        setIsLoading(false)
    }

    const goToSection = path => {
        history.push(path)
    }

    //to get the report(access_token, embed_url) fotr display the report
    const populateReportData = async (groupId, reportId) => {
        setIsLoading(true)
        const token = await authService.getAccessToken()
        const user = await authService.getUser()
        let userId = ''
        if (user) {
            userId = user.sub
        } else {
            userId = null
        }

        let data = {
            Id: reportId,
            GroupId: groupId,
            UserId: userId
        }
        await fetch('api/bireport/GetReportDetails', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
            })

            .then(d => {
                //const data = d.json()
                setReportId(d.reportId)
                setDatasetId(d.datasetId)
                setReportName(d.reportName)
                setEmbedUrl(d.embedUrl)
                setAccessToken(d.token)

                let reportContainer = document.getElementById('reportEmbedded')
                let powerbi = new pbi.service.Service(
                    pbi.factories.hpmFactory,
                    pbi.factories.wpmpFactory,
                    pbi.factories.routerFactory
                )
                powerbi.reset(reportContainer)

                let embedConfig = {
                    type: 'report',
                    id: d.reportId,
                    embedUrl: d.embedUrl,
                    accessToken: d.token,
                    tokenType: models.TokenType.Embed,
                    pageView: 'fitToWidth',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                        background: models.BackgroundType.Transparent
                    }
                }
                powerbi.embed(reportContainer, embedConfig)
            })
            .catch(error => {})

        setIsLoading(false)
    }

    return (
        <div class="dashboard-inner">
            <Grid
                container
                className={classes.root}
                spacing={3}
                direction="row"
            >
                <Grid item xs={12}>
                    <div
                        className="dashboard-card"
                        style={{ marginRight: '25px' }}
                    >
                        <Card>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <FormControl>
                                            <Typography
                                                variant="h6"
                                                component="h6"
                                                style={{
                                                    color: colorThemeRGB
                                                }}
                                            >
                                                Select Report
                                            </Typography>
                                            <Select
                                                label="Available Reports"
                                                name="reports"
                                                value={
                                                    reportsList &&
                                                    reportsList.length > 0
                                                        ? reportId
                                                        : 0
                                                }
                                                onChange={e =>
                                                    handleReportChange(e)
                                                }
                                            >
                                                {reportsList &&
                                                reportsList.length > 0 ? (
                                                    reportsList.map(report => (
                                                        <MenuItem
                                                            key={
                                                                report.reportId
                                                            }
                                                            value={
                                                                report.reportId
                                                            }
                                                        >
                                                            {report.reportName}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem key={0} value={0}>
                                                        {'No Reports Available'}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <br />
                                    </Grid>
                                    <Grid item xs={8} />
                                    <Grid item xs={1}>
                                        {
                                            // need a download button on far right of screen
                                        }
                                        <br />
                                        <br />
                                        <IconButton
                                            disabled={
                                                isDownloading || !reportId || isLoading
                                            }
                                            onClick={() =>
                                                downloadReport(
                                                    reportId,
                                                    reportName
                                                )
                                            }
                                        >
                                            Download &nbsp;
                                            <Icon
                                                style={{
                                                    color: colorThemeRGB
                                                }}
                                            >
                                                cloud_download
                                            </Icon>
                                        </IconButton>
                                        {
                                            isDownloading ? (
                                                <LinearProgress />
                                            ) : null
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent style={{ marginTop: '10px' }}>
                                <div>
                                    <div
                                        id="reportEmbedded"
                                        style={{ height: '80vh' }}
                                    >
                                        <PowerBIEmbed
                                            embedConfig={{
                                                type: 'report',
                                                id: reportId,
                                                embedUrl: embedUrl,
                                                accessToken: accessToken,
                                                tokenType:
                                                    models.TokenType.Embed,
                                                pageView: 'fitToWidth',
                                                settings: {
                                                    panes: {
                                                        filters: {
                                                            expanded: false,
                                                            visible: false
                                                        }
                                                    },
                                                    background:
                                                        models.BackgroundType
                                                            .Transparent
                                                }
                                            }}
                                            eventHandlers={
                                                new Map([
                                                    [
                                                        'loaded',
                                                        function () {
                                                            console.log(
                                                                'Report loaded'
                                                            )
                                                        }
                                                    ],
                                                    [
                                                        'rendered',
                                                        function () {
                                                            console.log(
                                                                'Report rendered'
                                                            )
                                                        }
                                                    ],
                                                    [
                                                        'error',
                                                        function (event) {
                                                            console.log(
                                                                event.detail
                                                            )
                                                        }
                                                    ]
                                                ])
                                            }
                                            cssClassName={'report-style-class'}
                                            getEmbeddedComponent={embeddedDash => {
                                                window.report = embeddedDash
                                            }}
                                        />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
