import React, { PureComponent } from 'react'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, CardContent } from '@material-ui/core'
import { Table, TableContainer, TableBody } from '@material-ui/core'
import { TableRow, TableCell, TableHead } from '@material-ui/core'
import { TextField, IconButton, Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Typography from '@material-ui/core/Typography'
import AlertMessage from './shared/Alert'

const CreateShipment = {
    pathname: `${ApplicationPaths.CreateShipment}`
}

const classes = makeStyles(theme => ({
    table: {
        minWidth: 650
    }
}))

const columns = [
    { id: 'idLine', label: 'Line #', minWidth: 80 },
    { id: 'weight', label: 'Weight' },
    { id: 'length', label: 'Length' },
    { id: 'width', label: 'Width' },
    { id: 'height', label: 'Height' }
]

export class PackageLineInfo extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            rows: [
                {
                    id: 1,
                    weight: '',
                    length: '',
                    width: '',
                    height: ''
                }
            ],
            alert: {
                open: false,
                success: false,
                message: ''
            }
        }
    }

    handleCloseAlert() {
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            alert.success = false
            alert.message = ''
            return { alert }
        })
    }

    handleChange(e, idx, name) {
        const { value } = e.target
        const rows = [...this.state.rows]
        rows[idx][name] = value
        this.setState({
            rows
        })
    }

    handleAddRow = () => {
        const item = {
            id: this.state.rows.length + 1,
            weight: '',
            length: '',
            width: '',
            height: ''
        }
        this.setState({
            rows: [...this.state.rows, item]
        })
    }

    handleRemoveSpecificRow = idx => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)

        rows.map(function (item, key) {
            var row = item
            row.id = key + 1
            return rows
        })

        this.setState({ rows })
    }

    renderAlert = () => {
        const alert = this.state.alert
        return alert.open ? (
            <div className="row mb-4">
                <AlertMessage
                    open={alert.open}
                    success={alert.success}
                    message={alert.message}
                    onClose={() => this.handleCloseAlert()}
                />
            </div>
        ) : null
    }

    render() {
        return (
            <div>
                <CardContent>
                    <form
                        className={classes.root}
                        onSubmit={this.handleSubmit}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container direction="column">
                            <Grid item>
                                <div>
                                    <div className="row">
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                >
                                                    Packing Line Info
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(column => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth:
                                                                    column.minWidth
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.rows.map(
                                                    (item, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell>
                                                                {idx + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    id="weight"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .rows[
                                                                            idx
                                                                        ]
                                                                            .weight ||
                                                                        ''
                                                                    }
                                                                    onChange={e =>
                                                                        this.handleChange(
                                                                            e,
                                                                            idx,
                                                                            'weight'
                                                                        )
                                                                    }
                                                                    className="form-control"
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <TextField
                                                                    id="length"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .rows[
                                                                            idx
                                                                        ]
                                                                            .length ||
                                                                        ''
                                                                    }
                                                                    onChange={e =>
                                                                        this.handleChange(
                                                                            e,
                                                                            idx,
                                                                            'length'
                                                                        )
                                                                    }
                                                                    className="form-control"
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <TextField
                                                                    id="width"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .rows[
                                                                            idx
                                                                        ]
                                                                            .width ||
                                                                        ''
                                                                    }
                                                                    onChange={e =>
                                                                        this.handleChange(
                                                                            e,
                                                                            idx,
                                                                            'width'
                                                                        )
                                                                    }
                                                                    className="form-control"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    id="height"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .rows[
                                                                            idx
                                                                        ]
                                                                            .height ||
                                                                        ''
                                                                    }
                                                                    onChange={e =>
                                                                        this.handleChange(
                                                                            e,
                                                                            idx,
                                                                            'height'
                                                                        )
                                                                    }
                                                                    className="form-control"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={this.handleRemoveSpecificRow(
                                                                        idx
                                                                    )}
                                                                    component="span"
                                                                >
                                                                    <RemoveCircleIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <IconButton
                                        color="primary"
                                        onClick={this.handleAddRow}
                                        component="span"
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                    {this.renderAlert()}
                                </div>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="flex-end"
                            >
                                <Grid item xs>
                                    <div className="w-100 mb-4">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href={CreateShipment.pathname}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className="w-100 mb-4">
                                        <Button
                                            type="submit"
                                            className={classes.submit}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save/Next
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </div>
        )
    }
}
