import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardTitle from './CardTitle'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const classes = makeStyles(theme => ({}))

export default function OrderDetailCard({ item, itemextra, shipmentdetails }) {
    const theme = useTheme()

    useEffect(() => {}, [])

    return (
        <div className="p-0 pl-3 data-data-info">
            <h5 class="text-Curious-blue my-2 font-14" checkOn={false}>
                Order Details
            </h5>

            <Grid container spacing={1} className={theme.bgColorSection}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={0}>
                        <Grid item xs={4} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    Weight
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        ((item.shipmentNumber !== null &&
                                            shipmentdetails?.weight) ||
                                            itemextra.weightAsString)) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    Volume
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        ((item.shipmentNumber !== null &&
                                            shipmentdetails?.volume) ||
                                            itemextra.volumeAsString)) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={4} className="sm-flex-100">
                            <div>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    Quantity
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {' '}
                                    {(itemextra !== null &&
                                        ((item.shipmentNumber &&
                                            shipmentdetails?.quantity) ||
                                            itemextra.quantity)) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={0}>
                        <Grid item xs={12}>
                            <div>
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    className="font-12 font-raleway-medium text-lviv-blue"
                                >
                                    Goods Description
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        ((item.shipmentNumber &&
                                            shipmentdetails?.goodsDescription) ||
                                            itemextra.goodsDescription)) ||
                                        '-'}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
