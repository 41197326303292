import React, { useState, useEffect } from 'react'
import authService from '../api-authorization/AuthorizeService'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, CardContent, Select, FormControl } from '@material-ui/core'
import { Table, TableContainer, TableBody } from '@material-ui/core'
import { TableRow, TableCell, TableHead, InputLabel } from '@material-ui/core'
import {
    TextField,
    IconButton,
    Button,
    Input,
    MenuItem
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Typography from '@material-ui/core/Typography'
import AlertMessage from '../shared/Alert'
import _ from 'lodash'
import { getDataMapping, setDataMapping } from '../../utils/data-mapping'
import { getCreatedOrder } from '../../api/api'

const classes = makeStyles(theme => ({
    table: {
        minWidth: 650
    }
}))

const emptyRow = {
    foo: ''
}

export default function CreateOrderTable(props) {
    const [order, setOrder] = useState([])
    const [key, setKey] = useState(['order.create'])
    const [commodities, setCommodities] = useState([])
    const [weightUnits, setWeightUnits] = useState([])
    const [lengthUnits, setLengthUnits] = useState([])
    const [pieceUnits, setPieceUnits] = useState([])
    const [noteUnits, setNoteUnits] = useState([])
    const [containerModes, setContainerModes] = useState([])

    const state = {
        commodities,
        weightUnits,
        lengthUnits,
        noteUnits,
        containerModes,
        pieceUnits
    }

    const [rows, setRows] = useState([])
    const [alert, setAlert] = useState([
        {
            open: false,
            success: false,
            message: ''
        }
    ])

    useEffect(() => {
        const dataTypes = getDataMapping()
        dataTypes !== null ? setDataTypes(dataTypes) : getTypesData()

        const orderId = sessionStorage.getItem(orderIdKey())
        if (orderId !== null && orderId !== undefined) {
            getCreatedOrder(orderId, data => {
                setOrder(data)
                setRows(data[props.orderKey])
            })
        } else {
            window.location.replace('/orders/create')
        }
    }, [])

    const updateOrderField = values => {
        setOrder({ ...order, ...values })
    }

    const orderIdKey = () => {
        return key + '.orderId'
    }

    const handleCloseAlert = () => {
        setAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleChange = (e, idx, name) => {
        const { value } = e.target
        updateValueInRow(idx, name, value)
    }

    const updateValueInRow = (idx, key, value) => {
        setRows(
            rows.map((item, index) =>
                index === idx ? { ...item, [key]: value } : item
            )
        )
    }

    const handleAddRow = () => {
        setRows([...rows, emptyRow])
    }

    const setDataTypes = data => {
        const values1 = data.filter(item => item.propertyType === 'COMMODITY')
        if (values1.length > 0) {
            setCommodities(values1.filter(item => item.isAvailable === true))
        }

        const values2 = data.filter(item => item.propertyType === 'WEIGHT_UOM')
        if (values2.length > 0) {
            setWeightUnits(values2.filter(item => item.isAvailable === true))
        }

        const values3 = data.filter(item => item.propertyType === 'LENGTH_UOM')
        if (values3.length > 0) {
            setLengthUnits(values3.filter(item => item.isAvailable === true))
        }

        const values4 = data.filter(
            item => item.propertyType === 'CONTAINER_MODE'
        )
        if (values4.length > 0) {
            setContainerModes(values4.filter(item => item.isAvailable === true))
        }

        const values5 = data.filter(item => item.propertyType === 'NOTE_TYPE')
        if (values5.length > 0) {
            setNoteUnits(values5.filter(item => item.isAvailable === true))
        }

        const values6 = data.filter(item => item.propertyType === 'PIECES_UOM')
        if (values6.length > 0) {
            setPieceUnits(values6.filter(item => item.isAvailable === true))
        }
    }

    const handleRemoveSpecificRow = idx => () => {
        setRows(rows.filter((r, index) => index !== idx))
    }

    const renderInputCell = (index, key) => {
        return (
            <TableCell>
                <TextField
                    id={key}
                    value={rows[index][key] || ''}
                    onChange={e => handleChange(e, index, key)}
                    className="form-control"
                />
            </TableCell>
        )
    }

    const renderSelectCell = (
        idx,
        key,
        label,
        data,
        keyValue = 'propertyKey',
        valueKey = 'value'
    ) => {
        return (
            <TableCell>
                <FormControl style={{ width: '100%' }}>
                    <Select
                        name={key}
                        label={label}
                        placeholder={label}
                        onChange={e => {
                            updateValueInRow(idx, key, e.target.value)
                        }}
                        value={rows[idx][key]}
                        input={<Input id={key} />}
                    >
                        {state[data].map(option => (
                            <MenuItem
                                key={option[keyValue]}
                                value={option[keyValue]}
                            >
                                {key !== 'noteType'
                                    ? `${option[keyValue]} - ${option[valueKey]}`
                                    : option[valueKey]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </TableCell>
        )
    }

    const getTypesData = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataTypes?dataTypesRequested=ALL',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            setDataMapping(JSON.stringify(data))
            setDataTypes(data)
        }
    }

    const renderAlert = () => {
        return alert.open ? (
            <div className="row mb-4">
                <AlertMessage
                    open={alert.open}
                    success={alert.success}
                    message={alert.message}
                    onClose={() => handleCloseAlert()}
                />
            </div>
        ) : null
    }

    const handleSubmit = e => {
        e.preventDefault()
        saveOrderData(_.omitBy(order, _.isNil))
    }

    const parseRowData = () => {
        const floats = props.columns
            .filter(column => column.type === 'number')
            .map(column => column.id)
        return rows.map(row => {
            for (var key in row) {
                const keyIn = floats.includes(key)
                row[key] = keyIn ? parseFloat(row[key]) : row[key]
            }
            return row
        })
    }

    const saveOrderData = async data => {
        const token = await authService.getAccessToken()
        data[props.orderKey] = parseRowData()
        await fetch(
            'api/createOrders/PutCreateOrder?sourceForm=' + props.sourceForm,
            {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then(response => {
            if (response.ok) {
                setAlert({
                    open: true,
                    success: true,
                    message: 'Order information is saved. Redirecting...'
                })

                setTimeout(() => {
                    window.location.replace(props.redirectURL)
                }, 3000)
            } else {
                setAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while saving order details.'
                })
            }
        })
    }

    const renderItem = (item, rowIndex) => {
        if (item.type === 'text' || item.type === 'number') {
            return renderInputCell(rowIndex, item.id)
        } else if (item.type === 'dropdown') {
            return renderSelectCell(rowIndex, item.id, item.label, item.dataKey)
        }

        return <div />
    }
    return (
        <div>
            <CardContent>
                <form
                    className={classes.root}
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container direction="column">
                        <Grid item>
                            <div>
                                <div className="row">
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography
                                                gutterBottom
                                                variant="h6"
                                            >
                                                {props.title || ''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={'line'}>
                                                    Line #
                                                </TableCell>
                                                {props.columns.map(
                                                    (column, index) => (
                                                        <TableCell
                                                            key={index}
                                                            align={column.align}
                                                            style={{
                                                                minWidth:
                                                                    column.minWidth
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows
                                                ? rows.map((item, idx) => (
                                                      <TableRow key={idx}>
                                                          <TableCell>
                                                              {idx + 1}
                                                          </TableCell>

                                                          {props.columns.map(
                                                              function (i) {
                                                                  return renderItem(
                                                                      i,
                                                                      idx
                                                                  )
                                                              }
                                                          )}

                                                          <TableCell>
                                                              <IconButton
                                                                  color="default"
                                                                  onClick={handleRemoveSpecificRow(
                                                                      idx
                                                                  )}
                                                                  component="span"
                                                              >
                                                                  <RemoveCircleIcon />
                                                              </IconButton>
                                                          </TableCell>
                                                      </TableRow>
                                                  ))
                                                : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <IconButton
                                    color="default"
                                    onClick={handleAddRow}
                                    component="span"
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <div class="separator"></div>
                        <div class="spacer mb-40px w-100"></div>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div className="w-100 mb-4 d-flex justify-content-end">
                                    {renderAlert()}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="w-100 mb-4 d-flex justify-content-end">
                                    {props.backURL !== null &&
                                        props.backURL !== undefined && (
                                            <Button
                                                className={`mr-4 ${classes.submit}`}
                                                onClick={() =>
                                                    window.location.replace(
                                                        props.backURL
                                                    )
                                                }
                                                variant="contained"
                                                color="primary"
                                            >
                                                Back
                                            </Button>
                                        )}
                                    {props.showSkip && (
                                        <Button
                                            className={`mr-4 ${classes.submit}`}
                                            onClick={() =>
                                                window.location.replace(
                                                    props.redirectURL
                                                )
                                            }
                                            variant="contained"
                                            color="primary"
                                        >
                                            Skip
                                        </Button>
                                    )}
                                    <Button
                                        type="submit"
                                        className={classes.submit}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {props.submitButtonText || 'Save/Next'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </div>
    )
}
