import React, { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import { checkValidPosition } from '../../utils/map'

const MapPolylines = ({ renderPolyline, uniqueRoutes, getLatLng }) => {
    const bounds = new window.google.maps.LatLngBounds()
    const daMap = useGoogleMap()

    useEffect(() => {
        uniqueRoutes.map(item => {
            if (
                item.geoLocationOrigin !== '' &&
                item.geoLocationDestination !== ''
            ) {
                const originCenter = getLatLng(item, 'geoLocationOrigin')
                const destinationCenter = getLatLng(
                    item,
                    'geoLocationDestination'
                )

                if (
                    !checkValidPosition(originCenter) ||
                    !checkValidPosition(destinationCenter)
                ) {
                    return false
                }

                bounds.extend(
                    new window.google.maps.LatLng(
                        originCenter.lat,
                        originCenter.lng
                    )
                )
                bounds.extend(
                    new window.google.maps.LatLng(
                        destinationCenter.lat,
                        destinationCenter.lng
                    )
                )
            }

            return true
        })

        if (bounds.isEmpty()) {
            bounds.extend(
                new window.google.maps.LatLng(42.2611071, -100.0855555)
            )
            daMap.fitBounds(bounds, 1)
            var listener = window.google.maps.event.addListener(
                daMap,
                'idle',
                function () {
                    if (daMap.getZoom() > 16) daMap.setZoom(3)
                    window.google.maps.event.removeListener(listener)
                }
            )
        } else {
            daMap.fitBounds(bounds, 1)
        }
    }, [uniqueRoutes])

    return (
        <>
            {uniqueRoutes.map(item => {
                const originCenter = getLatLng(item, 'geoLocationOrigin')
                const destinationCenter = getLatLng(
                    item,
                    'geoLocationDestination'
                )
                return renderPolyline(
                    item.shipmentNumber,
                    item,
                    null,
                    originCenter,
                    destinationCenter,
                    null,
                    null,
                    null
                )
            })}
        </>
    )
}

export default MapPolylines
