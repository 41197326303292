import * as React from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    Divider
} from '@material-ui/core'

export default class DialogConfirm extends React.Component {
    render() {
        if (!this.props.show) {
            return null
        }
        return (
            <Dialog
                open={this.props.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {' '}
                    {this.props.title}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.message}
                        {this.props.children}
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => this.props.callback(false)}
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={() => this.props.callback(true)}
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
DialogConfirm.propTypes = {
    callback: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
}
