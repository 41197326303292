import React, { useEffect, useState } from 'react'
import CreateShipmentTable from './CreateShipmentTable'
import ShipmentCreationProgress from './ShipmentCreationProgress'
import { isNotFclOrFtl, getShipmentKey } from '../../utils/shipment'
import { getCreatedShipment, getClientData } from '../../api/api'
import {
    getPreviousEnabledBookingStep,
    getNextEnabledBookingStep,
    hasAccessToStep
} from './CreateBookingFunctions'

const columns = [
    {
        id: 'containerTypeCode',
        label: 'Container Types',
        type: 'dropdown',
        dataKey: 'containerModes',
        required: true
    },
    {
        id: 'numberOfContainers',
        label: 'Number of Containers',
        type: 'number',
        required: true
    },
    {
        id: 'containerCommodityCode',
        label: 'Commodity',
        type: 'dropdown',
        dataKey: 'commodities'
    }
]

export default function Containers(props) {
    const [shipment, setShipment] = useState(null)
    const [client, setClient] = useState(null)
    const [previousBookingStep, setPreviousBookingStep] = useState(null)
    const [nextBookingStep, setNextBookingStep] = useState(null)

    useEffect(() => {
        const shipmentNumber = getShipmentKey()
        if (shipmentNumber !== null && shipmentNumber !== undefined) {
            getCreatedShipment(shipmentNumber, null, null, data => {
                setShipment(data)
            })
        } else {
            window.location.replace('/shipments/create')
        }
    }, [])

    useEffect(() => {
        if (!shipment) return
        getClientData(
            shipment.clientId,
            data => {
                setClient(data)
                const nextStep = getNextEnabledBookingStep(
                    3,
                    data.bookingSteps,
                    shipment.containerTypeCode
                )
                setNextBookingStep(nextStep)
                setPreviousBookingStep(
                    getPreviousEnabledBookingStep(3, data.bookingSteps)
                )
                if (
                    !hasAccessToStep(
                        3,
                        data.bookingSteps,
                        shipment.containerTypeCode
                    )
                ) {
                    window.location.replace(nextStep.href)
                }
            },
            () => {
                setClient({})
            }
        )
    }, [shipment])

    return (
        client &&
        shipment && (
            <div>
                <div className="mb-4 w-100">
                    <ShipmentCreationProgress
                        client={client}
                        index={3}
                        hideContainers={isNotFclOrFtl(
                            shipment.containerTypeCode
                        )}
                    />
                </div>
                <CreateShipmentTable
                    title="Containers"
                    columns={columns}
                    shipmentKey="containers"
                    backURL={previousBookingStep?.href}
                    redirectURL={nextBookingStep?.href}
                    submitButtonText="Save/Review"
                    sourceForm="bookingContainers"
                    showSkip={true}
                />
            </div>
        )
    )
}
