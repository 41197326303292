const dataTypesKey = 'shipment.create.dataTypes'

export const getDataMapping = () => {
    return JSON.parse(sessionStorage.getItem(dataTypesKey))
}

export const setDataMapping = data => {
    sessionStorage.setItem(dataTypesKey, data)
}

export const deleteDataMapping = data => {
    sessionStorage.removeItem(dataTypesKey)
}

export const getModuleNameByKey = key => {
    switch (key) {
        case 'ShipmentCreate':
            return 'Create Booking'
        case 'CreateOrder':
            return 'Create Order'
        case 'ShipmentSearch':
            return 'Shipments'
        case 'Customs':
            return 'Customs'
        case 'Accounting':
            return 'Accounting'
        case 'Booking':
            return 'Bookings'
        case 'Order':
            return 'Orders'
        case 'Container':
            return 'Containers'
        default:
            return ''
    }
}
