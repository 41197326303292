import React, { useEffect, useState } from 'react'
import CreateShipmentTable from './CreateShipmentTable'
import ShipmentCreationProgress from './ShipmentCreationProgress'
import { isNotFclOrFtl, getShipmentKey } from '../../utils/shipment'
import { getCreatedShipment, getClientData } from '../../api/api'
import {
    getNextEnabledBookingStep,
    hasAccessToStep
} from './CreateBookingFunctions'

const columns = [
    {
        id: 'noteTypeCode',
        label: 'Note Type',
        type: 'dropdown',
        dataKey: 'noteUnits',
        unique: true
    },
    { id: 'noteText', label: 'Text', type: 'text' }
]

export default function Notes() {
    const [shipment, setShipment] = useState(null)
    const [client, setClient] = useState(null)
    const [nextBookingStep, setNextBookingStep] = useState(null)

    useEffect(() => {
        const shipmentNumber = getShipmentKey()
        if (shipmentNumber !== null && shipmentNumber !== undefined) {
            getCreatedShipment(shipmentNumber, null, null, data => {
                setShipment(data)
            })
        } else {
            window.location.replace('/shipments/create')
        }
    }, [])

    useEffect(() => {
        if (!shipment) return
        getClientData(
            shipment.clientId,
            data => {
                setClient(data)
                const nextStep = getNextEnabledBookingStep(
                    1,
                    data.bookingSteps,
                    shipment.containerTypeCode
                )
                if (!hasAccessToStep(1, data.bookingSteps)) {
                    window.location.replace(nextStep.href)
                }
                setNextBookingStep(nextStep)
            },
            () => {
                setClient({})
            }
        )
    }, [shipment])

    return (
        client &&
        shipment && (
            <div>
                <div className="mb-4 w-100">
                    <ShipmentCreationProgress
                        client={client}
                        index={1}
                        hideContainers={isNotFclOrFtl(
                            shipment.containerTypeCode
                        )}
                    />
                </div>
                <CreateShipmentTable
                    title="Notes"
                    columns={columns}
                    shipmentKey="notes"
                    backURL="/shipments/create"
                    redirectURL={nextBookingStep?.href}
                    showSkip={true}
                    sourceForm="bookingNotes"
                />
            </div>
        )
    )
}
