import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import authService from '../api-authorization/AuthorizeService'
import { LinearProgress, MenuItem } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { downloadDocumentPDFForShipment } from '../../api/api'
import GetAppIcon from '@material-ui/icons/GetApp'
import { clientUserHasPermission } from '../../utils/permissions'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    docTypeSelect: {}
}))

export default function DocumentUploadSelectDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [doctype, setDocType] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [isError, setIsError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [isSelected, setIsSelected] = React.useState(false)
    const [docTypes, setDocTypes] = React.useState(null)
    const [isAdmin, setIsAdmin] = React.useState(false)
    const [canUpload, setCanUpload] = React.useState(false)

    useEffect(() => {
        const getIsAdmin = async () => {
            const admin = await authService.isAdmin()
            const superAdmin = await authService.isSuperAdmin()
            setIsAdmin(admin || superAdmin)
        }
        getIsAdmin()
    }, [])

    useEffect(() => {
        const getUploadPermission = async () => {
            const canUpload = await clientUserHasPermission(
                props.clientId,
                'uploaddocuments'
            )
            setCanUpload(canUpload)
        }
        getUploadPermission()
    }, [])

    const transactionNumber =
        props.transactiontype === 'shipment' ||
        props.transactiontype === 'booking' ||
        props.transactiontype === 'customs'
            ? props.transactionnumber
            : atob(
                  props.transactionnumber.substring(
                      0,
                      props.transactionnumber.indexOf('~')
                  )
              ) +
              '/' +
              props.transactionnumber.substring(
                  props.transactionnumber.indexOf('~') + 1,
                  props.transactionnumber.length
              )

    const getTransactionNumber = () => {
        if (props.transactiontype === 'order') {
            return (
                atob(
                    props.transactionnumber.substring(
                        0,
                        props.transactionnumber.indexOf('~')
                    )
                ) +
                '~' +
                props.transactionnumber.substring(
                    props.transactionnumber.indexOf('~') + 1,
                    props.transactionnumber.length
                ) +
                '~' +
                props.buyercode
            )
        } else {
            return props.transactionnumber
        }
    }

    const fetchDocTypes = React.useCallback(async () => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetCargowiseDocTypes?clientId=' +
                props.clientId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            setDocTypes(data)
        }
    }, [])

    useEffect(() => {
        fetchDocTypes()
    }, [fetchDocTypes])

    const getTransactionTypeByName = name => {
        switch (name) {
            case 'shipment':
                return 1
            case 'booking':
                return 2
            case 'customs':
                return 0
            case 'order':
                return 3
        }
    }

    const downloadAllDocuments = () => {
        var docData = {
            TransactionNumber: getTransactionNumber(),
            TransactionType: getTransactionTypeByName(props.transactiontype),
            DownloadAll: true
        }
        downloadDocumentPDFForShipment(
            docData,
            (blob, headers) => {
                var sanitize = require('sanitize-filename')
                const filename = sanitize(
                    headers
                        .get('Content-Disposition')
                        .split('filename=')[1]
                        .split(';')[0]
                )

                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
            },
            () => {}
        )
    }

    const handleChange = event => {
        setDocType(event.target.value || '')
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setIsSelected(false)
        setSelectedFile(null)
        setDocType('')
        setOpen(false)
    }

    const handleSuccess = () => {
        setIsSuccess(true)
        // reload page
        setInterval(function () {
            setIsSuccess(false)
            setOpen(false)
        }, 4000)
        window.location.reload()
    }

    const handleError = errorMessage => {
        setErrorMessage(errorMessage)
        setIsError(true)
    }

    const changeHandler = event => {
        const selectedFile = event.target.files[0]
        const fileSizeLimit = props.organization.cargowiseEDocSizeLimit
        if (selectedFile.size / (1024 * 1024) <= fileSizeLimit) {
            setIsSelected(true)
            setSelectedFile(selectedFile)
            setIsError(false)
        } else {
            setIsSelected(false)
            setIsError(true)
            setErrorMessage(
                `File size is too large. Please select a file smaller than ${fileSizeLimit} MB.`
            )
            setSelectedFile(null)
            event.target.value = ''
        }
    }

    const handleSubmission = async () => {
        if (selectedFile === null) {
            setIsError(true)
            setErrorMessage('No file selected!')
        } else if (doctype === '') {
            setIsError(true)
            setErrorMessage('No document type selected!')
        } else {
            setIsError(false)
            setIsSuccess(false)
            setErrorMessage('')
            setIsLoading(true)

            const formData = new FormData()
            formData.append('File', selectedFile)

            const token = await authService.getAccessToken()
            const header = {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*'
            }

            fetch(
                'api/DataManagement/UploadCargowiseDocument?' +
                    'doctype=' +
                    doctype +
                    '&filename=' +
                    btoa(encodeURIComponent(selectedFile.name)).replace(
                        '/',
                        '_'
                    ) +
                    '&transactiontype=' +
                    props.transactiontype +
                    '&transactionnumber=' +
                    props.transactionnumber +
                    '&buyercode=' +
                    props.buyercode +
                    '&clientId=' +
                    props.clientId +
                    '&transportmode=' +
                    props.transportmode,
                {
                    headers: header,
                    method: 'POST',
                    body: formData
                }
            )
                .then(response => {
                    if (response.ok) {
                        setIsLoading(false)
                        handleSuccess()
                    } else {
                        if (response.status === 401) {
                            setIsLoading(false)
                            handleError('Not authorized to upload documents.')
                        } else {
                            setIsLoading(false)
                            handleError('Document upload failed, please retry.')
                        }
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    console.log(error)
                    handleError('Document upload failed, please retry.')
                })
        }
    }

    return (
        <>
            {canUpload || isAdmin ? (
                <IconButton
                    color="inherit"
                    size="small"
                    onClick={handleClickOpen}
                >
                    <AddCircleIcon />
                    Add
                </IconButton>
            ) : null}
            <IconButton
                color="inherit"
                size="small"
                onClick={downloadAllDocuments}
            >
                <GetAppIcon />
                Download All
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Upload New Document |{' '}
                    {props.transactiontype.substring(0, 1).toUpperCase() +
                        props.transactiontype.substring(1)}{' '}
                    {transactionNumber}
                </DialogTitle>
                <DialogContent>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="document-upload-select-dialog">
                                Document Type
                            </InputLabel>
                            <Select
                                value={doctype}
                                onChange={handleChange}
                                className={classes.docTypeSelect}
                                input={
                                    <Input id="document-upload-select-dialog" />
                                }
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {docTypes !== null
                                    ? docTypes.map(type => (
                                          <MenuItem value={type.documentType}>
                                              {type.documentDescription}
                                          </MenuItem>
                                      ))
                                    : null}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                {isLoading && <LinearProgress />}
                <DialogActions>
                    <input type="file" name="file" onChange={changeHandler} />
                    {isSelected ? (
                        <div>
                            <p>Filename: {selectedFile.name}</p>
                            <p>Type: {selectedFile.type}</p>
                            <p>
                                Size:{' '}
                                {parseInt(Number(selectedFile.size) / 1000)}kb
                            </p>
                            <p>
                                Last Modified:{' '}
                                {selectedFile.lastModifiedDate.toLocaleDateString()}
                            </p>
                        </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )}
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmission} color="primary">
                        Submit
                    </Button>
                </DialogActions>
                {isSuccess && (
                    <Alert severity="success">
                        Document successfully uploaded!
                    </Alert>
                )}
                {isError && (
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setIsError(false)
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMessage}
                    </Alert>
                )}
            </Dialog>
        </>
    )
}
