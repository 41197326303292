import React, { PureComponent } from 'react'
import authService from './api-authorization/AuthorizeService'
import MUIDataTable from 'mui-datatables'
import { IconButton, Paper, Grid } from '@material-ui/core'
import { LinearProgress, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import DialogConfirm from './shared/DialogConfirm'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import UserImportDialog from './shared/UserImportDialog'
import { NavLink } from 'react-router-dom'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    }
})

const clientUserPath = { pathname: `${ApplicationPaths.Users}` }

class ClientUsers extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            users: [],
            user: {
                id: 0,
                email: '',
                clientId: props.match.params.clientId,
                notifyError: false,
                errMessage: ''
            },
            dialogOpen: false,
            alerts: {
                showSuccess: false,
                successMessage: ''
            },
            theme: props.theme,
            canImportUsers: false
        }
        this.showDeleteDialog = this.showDeleteDialog.bind(this)
        this.sendEmailConfirmEmail = this.sendEmailConfirmEmail.bind(this)
        this.populateClientUsersData = this.populateClientUsersData.bind(this)
    }

    async componentDidMount() {
        const user = this.state.user
        if (user.clientId) {
            this.populateClientUsersData(user.clientId)
        }
        const isSuperAdmin = await authService.isSuperAdmin()
        this.setState({ canImportUsers: isSuperAdmin })
    }

    dataToDisplay(dataset) {
        if (dataset) {
            var UsersArray = []
            for (let i = 0; i < dataset.length; i++) {
                let usersArray = []
                var id = dataset[i].id
                var firstName = dataset[i].firstName
                var lastName = dataset[i].lastName
                var email = dataset[i].email
                var psaorganizationid = dataset[i].psaOrganizationId
                var emailConfirmed = dataset[i].emailConfirmed ? 'Yes' : 'No'
                var lastLoginDateString = dataset[i].lastLoginAsString
                var lastLogin =
                    lastLoginDateString === ''
                        ? 'n/a'
                        : new Date(lastLoginDateString).toLocaleString()
                var lastActivityDateString = dataset[i].lastActivityAsString
                var lastActivity =
                    lastActivityDateString === ''
                        ? 'n/a'
                        : new Date(lastActivityDateString).toLocaleString()

                usersArray.push(id)
                usersArray.push(firstName)
                usersArray.push(lastName)
                usersArray.push(email)
                usersArray.push(emailConfirmed)
                usersArray.push(lastLogin)
                usersArray.push(lastActivity)
                usersArray.push(psaorganizationid)

                UsersArray.push(usersArray)
            }
            return UsersArray
        }
    }

    showDeleteDialog(_user) {
        this.setState(prevState => {
            let user = Object.assign({}, prevState.user)
            user.id = _user.id
            user.email = _user.email
            return { user }
        })
        this.setState({ dialogOpen: true })
    }

    async sendEmailConfirmEmail(userid) {
        this.setState({ isLoading: true })

        const token = await authService.getAccessToken()
        const options = {
            method: 'POST',
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
        fetch('api/users/SendUserEmailConfirmation/' + userid, options)
            .then(res => {
                this.setState({ isLoading: false })
                if (res.ok) {
                    this.setState(prevState => {
                        let alerts = Object.assign({}, prevState.alerts)
                        alerts.showSuccess = true
                        alerts.successMessage =
                            'Email confirmation message resent.'
                        return { alerts }
                    })
                } else {
                    this.setState(prevState => {
                        let user = Object.assign({}, prevState.user)
                        user.notifyError = true
                        user.id = userid
                        user.errMessage =
                            'Sorry, there was an error resending email confirmation message.'
                        return { user }
                    })
                }
            })
            .catch(err => {
                this.setState(prevState => {
                    let user = Object.assign({}, prevState.user)
                    user.notifyError = true
                    user.id = userid
                    user.errMessage =
                        'Sorry, there was an error resending email confirmation message.'
                    return { user }
                })
                console.log(err)
            })
    }

    handleActionsDialog = action => {
        if (action) {
            this.handleDelete()
        }
        this.setState({
            dialogOpen: !this.state.dialogOpen
        })
    }

    handleDelete = async event => {
        var user = this.state.user
        await this.deleteClientUser(user.clientId, user.id)
    }

    hideRowNotify() {
        this.setState(prevState => {
            let user = Object.assign({}, prevState.user)
            user.notifyError = false
            return { user }
        })
    }

    render() {
        const { user, canImportUsers } = this.state
        const { classes } = this.props
        const data = this.dataToDisplay(this.state.users)
        const options = {
            filter: false,
            viewColumns: false,
            print: false,
            download: false,
            selectableRowsOnClick: true,
            selectableRows: 'none',
            caseSensitive: false,
            rowsPerPageOptions: [10, 15, 25, 50, 100],
            filterType: 'dropdown',
            responsive: 'stacked',
            rowsPerPage: 10,
            searchOpen: false,
            customToolbar: () => {
                return (
                    <>
                        <NavLink
                            to={
                                clientUserPath.pathname +
                                '/' +
                                user.clientId +
                                '/new'
                            }
                        >
                            <Button
                                className="align-self-center flex-grow-1"
                                variant="contained"
                                color="primary"
                            >
                                + Add Client User
                            </Button>
                        </NavLink>
                        {canImportUsers ? (
                            <>
                                <UserImportDialog
                                    clientid={user.clientId}
                                    buttonTitle={'Import Users For Client'}
                                ></UserImportDialog>
                                <UserImportDialog
                                    clientid={null}
                                    buttonTitle={'Import Users'}
                                ></UserImportDialog>
                            </>
                        ) : null}
                    </>
                )
            }
        }

        const headCells = [
            {
                id: 'id',
                label: 'Id',
                options: { filter: false, sort: false, display: 'none' }
            },
            { id: 'firstName', label: 'First Name' },
            { id: 'lastName', label: 'Last Name' },
            {
                id: 'email',
                label: 'Email',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var id = tableMeta.rowData[0]
                        var email = tableMeta.rowData[3]
                        return (
                            <NavLink
                                className={classes.link}
                                to={
                                    clientUserPath.pathname +
                                    '/' +
                                    user.clientId +
                                    '/' +
                                    id
                                }
                            >
                                {email}
                            </NavLink>
                        )
                    }
                }
            },
            {
                id: 'emailConfirmed',
                label: 'Email Confirmed',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                {value !== 'Yes' ? (
                                    <>
                                        {value}{' '}
                                        <Tooltip
                                            title={
                                                <h6>
                                                    Resend Email Confirmation
                                                    Message
                                                </h6>
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disableElevation
                                                color="secondary"
                                                onClick={() =>
                                                    this.sendEmailConfirmEmail(
                                                        tableMeta.rowData[0]
                                                    )
                                                }
                                            >
                                                Resend
                                            </Button>
                                        </Tooltip>
                                    </>
                                ) : (
                                    value
                                )}
                            </>
                        )
                    }
                }
            },
            { id: 'lastLogin', label: 'Last Login' },
            { id: 'lastActivity', label: 'Last Activity' },
            {
                id: 'email',
                label: 'Email',
                options: { filter: false, sort: false, display: 'none' }
            },
            {
                id: 'psaOrganizationId',
                label: 'PSA Organization Id',
                options: { filter: false, sort: false, display: 'none' }
            },
            {
                id: 'actions',
                label: 'Actions',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var email = tableMeta.rowData[3]
                        var _user = {
                            id: tableMeta.rowData[0],
                            email: email
                        }

                        return (
                            <div>
                                <IconButton
                                    className="toggle-button"
                                    id="centered-toggle-button"
                                    onClick={() => this.showDeleteDialog(_user)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <DialogConfirm
                                    callback={this.handleActionsDialog.bind(
                                        this
                                    )}
                                    show={this.state.dialogOpen}
                                    title="Delete User"
                                    message={
                                        'Are you sure you wish to delete this user?'
                                    }
                                >
                                    <br />
                                    <span className="font-weight-bold">
                                        {this.state.user.email}
                                    </span>
                                </DialogConfirm>
                            </div>
                        )
                    }
                }
            },
            {
                id: 'result_action',
                label: '',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div>
                                {this.state.user.notifyError &&
                                this.state.user.id === tableMeta.rowData[0] ? (
                                    <Tooltip title={user.errMessage}>
                                        <IconButton
                                            color="secondary"
                                            className="toggle-button"
                                            id="centered-toggle-button"
                                            onClick={() => this.hideRowNotify()}
                                        >
                                            <ErrorOutlineIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </div>
                        )
                    }
                }
            }
        ]

        return this.state.isLoading ? (
            <LinearProgress color="primary" />
        ) : (
            <Grid container>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <MUIDataTable
                            columns={headCells}
                            options={options}
                            data={data}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    async populateClientUsersData(clientId) {
        this.setState({ isLoading: true })
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/users/GetUsersForClient/' + clientId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            this.setState({ users: data })
        }
        this.setState({ isLoading: false })
    }

    async deleteClientUser(clientId, userId) {
        this.setState({ isPosting: true })
        const token = await authService.getAccessToken()
        const options = {
            method: 'DELETE',
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
            'Content-Type': 'application/json'
        }

        fetch('api/users/DeleteUser/' + clientId + '/' + userId, options)
            .then(res => {
                if (res.ok) {
                    this.setState(prevState => {
                        let alerts = Object.assign({}, prevState.alerts)
                        alerts.showSuccess = true
                        alerts.successMessage = 'User was deleted.'
                        return { alerts }
                    })
                    this.setState(prevState => {
                        let user = Object.assign({}, prevState.user)
                        user = { id: 0, email: '', clientId: clientId }
                        return { user }
                    })
                    this.setState({ isPosting: false, clientId: clientId })

                    this.populateClientUsersData(clientId)

                    return Promise.resolve('User (' + userId + ') deleted.')
                } else {
                    this.setState(prevState => {
                        let user = Object.assign({}, prevState.user)
                        user.notifyError = true
                        user.errMessage =
                            'Sorry, there was an error while deleting this client.'
                        return { user }
                    })
                    this.setState({ isPosting: false })

                    return Promise.reject(
                        'An error occurred (' + res.status + ').'
                    )
                }
            })
            .catch(err => {
                this.setState(prevState => {
                    let user = Object.assign({}, prevState.user)
                    user.notifyError = true
                    user.errMessage =
                        'Sorry, there was an error while deleting this client.'
                    return { user }
                })

                this.setState({ isPosting: false })
                console.log(err)
            })
    }
}
export default withStyles(styles)(ClientUsers)
