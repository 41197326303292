import React, { PureComponent, forwardRef } from 'react'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import MUIDataTable from 'mui-datatables'
import { IconButton, Paper, Grid, CardContent } from '@material-ui/core'
import { LinearProgress, Typography, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'
import AddIcon from '@material-ui/icons/Add'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import DialogConfirm from './shared/DialogConfirm'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { withStyles } from '@material-ui/core/styles'
import ClientImportDialog from './shared/ClientImportDialog'
import { NavLink } from 'react-router-dom'
import MaterialTable from 'material-table'
import { ThemeProvider, createTheme } from '@mui/material'
import { retrieveView } from './shared/Views'
import Tooltip from '@material-ui/core/Tooltip'
import TableViewManagementDialog from './shared/TableViewManagementDialog'
import TableViewCreateDialog from './shared/TableViewCreateDialog'
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { getModuleNameByKey } from '../utils/data-mapping'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    h2: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    }
})
const clientsPath = { pathname: `${ApplicationPaths.Clients}` }
const usersPath = { pathname: `${ApplicationPaths.Users}` }

class Clients extends PureComponent {
    static displayName = Clients.name

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            clients: [],
            users: [],
            filteredUsers: [],
            selectedClients: [],
            client: {
                id: 0,
                customerId: '',
                notifyError: false,
                errMessage: ''
            },
            dialogOpen: false,
            showSuccessAlert: false,
            theme: props.theme,
            canImportClients: false,
            canPublishClientIds: false,
            organization: null,
            headerColumns: [],
            addUsersDialogOpen: false,
            loadingClientUsers: false,
            isAddUsersSuccess: false,
            isAddUsersError: false,
            addUsersResult: '',
            addingUsers: false,
            isAlertSuccess: false,
            showAlert: false,
            alertMessage: ''
        }
        this.showDialog = this.showDialog.bind(this)
        this.populateClientsData = this.populateClientsData.bind(this)
    }

    async componentDidMount() {
        await this.getHeaderColumns()
        await this.populateClientsData()
        await this.populateConfig()
        const isSuperAdmin = await authService.isSuperAdmin()
        const isAdmin = await authService.isAdmin()
        this.setState({ canImportClients: isSuperAdmin })
        this.setState({ canPublishClientIds: isAdmin || isSuperAdmin })
    }

    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({
            organization: data
        })
    }

    publishClientIdsToCargoWise = async clients => {
        this.setState({ isLoading: true })

        const token = await authService.getAccessToken()
        const clientIds = clients.map(client => client.id)
        const body = JSON.stringify(clientIds)

        const response = await fetch(
            'api/clients/PublishClientIdsToCargowise',
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json, text/plain, *',
                    'Content-Type': 'application/json'
                },
                body: body
            }
        )

        if (response.ok) {
            this.setState({
                isAlertSuccess: true,
                showAlert: true,
                alertMessage: 'Client Ids Published to CargoWise Successfully.',
                isLoading: false
            })
        } else {
            const responseText = await response.text()
            this.setState({
                isAlertSuccess: false,
                showAlert: true,
                alertMessage: responseText,
                isLoading: false
            })
        }
    }

    getHeaderColumns = async () => {
        const view = await retrieveView(null, 'ClientsGrid')
        if (view) {
            this.setState({ headerColumns: view.columnDefinitionsArray })
        }
    }

    showDialog(_client) {
        this.setState(prevState => {
            let client = Object.assign({}, prevState.client)
            client = _client
            return { client }
        })
        this.setState({ dialogOpen: true })
    }

    handleActionsDialog = action => {
        if (action) {
            this.handleDelete()
        }
        this.setState({
            dialogOpen: !this.state.dialogOpen
        })
    }

    handleDelete = async event => {
        var id = this.state.client.id
        this.deleteClient(id)
    }

    hideRowNotify() {
        this.setState(prevState => {
            let client = Object.assign({}, prevState.client)
            client.notifyError = false
            return { client }
        })
    }

    getUserEmailNameString(user) {
        if (user) {
            return (
                user.email + ' (' + user.firstName + ' ' + user.lastName + ')'
            )
        } else {
            return ''
        }
    }

    handleUserSelectChange = event => {
        const id = event.target.id
        const checked = event.target.checked
        this.setState(prevState => {
            let users = [...prevState.users]
            users.forEach(user => {
                if (user.id === id) {
                    user.checked = checked
                }
            })
            return { users }
        })
    }

    renderAlert() {
        const { showAlert, isAlertSuccess, alertMessage } = this.state

        if (showAlert) {
            return (
                <Alert
                    severity={isAlertSuccess ? 'success' : 'error'}
                    variant="filled"
                    onClose={() => this.setState({ showAlert: false })}
                >
                    {alertMessage}
                </Alert>
            )
        } else {
            return null
        }
    }

    render() {
        const {
            isLoading,
            clients,
            client,
            canImportClients,
            canPublishClientIds,
            organization,
            headerColumns
        } = this.state
        const { classes } = this.props
        const tableTheme = createTheme()

        const backgroundColor =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#ffffff'
        const textColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'

        let tableActions = [
            {
                icon: () => {
                    return (
                        <>
                            <NavLink to={clientsPath.pathname + '/new'}>
                                <Tooltip title="Add Client">
                                    <IconButton color="primary" size="medium">
                                        <AddIcon /> Add Client
                                    </IconButton>
                                </Tooltip>
                            </NavLink>
                        </>
                    )
                },
                isFreeAction: true
            },
            {
                // need an action to add users to selected clients
                icon: () => {
                    return (
                        <IconButton color="primary" size="medium">
                            <AddIcon /> Add Users
                        </IconButton>
                    )
                },
                onClick: (event, rowData) => {
                    this.setState({ selectedClients: rowData }, () => {
                        this.setState({
                            loadingClientUsers: true,
                            addUsersDialogOpen: true
                        })
                        this.populateOrgUsersData().then(() => {
                            this.setState({ loadingClientUsers: false })
                        })
                    })
                },
                isFreeAction: false,
                tooltip: 'Add Users to Selected Clients'
            },
            {
                icon: () => {
                    return (
                        <IconButton color="primary" size="medium">
                            <DeleteIcon /> Delete Client
                        </IconButton>
                    )
                },
                onClick: (event, rowData) => {
                    // currently can only remove one client at a time
                    if (rowData.length > 1) {
                        alert('Please select only one client to delete.')
                        return
                    }
                    const deleteClient = {
                        id: rowData[0].id,
                        customerId: rowData[0].customerId
                    }
                    this.showDialog(deleteClient)
                },
                isFreeAction: false,
                tooltip: 'Delete Client'
            }
        ]

        if (canImportClients) {
            tableActions.push({
                icon: () => {
                    return <ClientImportDialog />
                },
                isFreeAction: true
            })
        }

        if (canPublishClientIds) {
            tableActions.push({
                icon: () => {
                    return <PublishOutlinedIcon />
                },
                isFreeAction: false,
                onClick: (event, rowData) => {
                    this.publishClientIdsToCargoWise(rowData)
                },
                tooltip: 'Publish Client Ids to CargoWise'
            })
        }

        const tableColumnHeaders = headerColumns
            ? headerColumns
                  .filter(item => item.visible)
                  .map(headcell => {
                      return {
                          field: headcell.value,
                          title: headcell.title,
                          type: headcell.type,
                          sortField: headcell.sortField,
                          hidden: false,
                          cellStyle: {
                              whiteSpace: 'nowrap'
                          },
                          render: rowData => {
                              if (headcell.value === 'customerId') {
                                  var path =
                                      clientsPath.pathname + '/' + rowData.id
                                  return (
                                      <NavLink
                                          className={classes.link}
                                          to={path}
                                      >
                                          {rowData[headcell.value]}
                                      </NavLink>
                                  )
                              } else if (headcell.value === 'clientUserCount') {
                                  var clientId = rowData.id
                                  var userCount = rowData.clientUserCount
                                  return (
                                      <NavLink
                                          className={classes.link}
                                          to={
                                              usersPath.pathname +
                                              '/' +
                                              clientId
                                          }
                                      >
                                          {userCount}
                                      </NavLink>
                                  )
                              } else if (
                                  headcell.value === 'lastCrawlerResult'
                              ) {
                                  return rowData[headcell.value]
                                      ? new Date(
                                            rowData[headcell.value].substring(
                                                0,
                                                24
                                            )
                                        ).toLocaleString() +
                                            rowData[headcell.value].substring(
                                                24
                                            )
                                      : '-'
                              } else if (
                                  headcell.value === 'modulePermissionString'
                              ) {
                                  // map each module to a span with a colored background depending on the module
                                  return rowData.modulePermissionArray.map(
                                      (module, index) => {
                                          return (
                                              <span
                                                  key={index}
                                                  style={{
                                                      border: '1px solid #228ec2',
                                                      backgroundColor:
                                                          module.value === true
                                                              ? '#8DEB8F'
                                                              : null,
                                                      padding: '3px',
                                                      margin: '3px',
                                                      borderRadius: '5px'
                                                  }}
                                              >
                                                  {getModuleNameByKey(
                                                      module.propertyKey
                                                  )}
                                              </span>
                                          )
                                      }
                                  )
                              } else {
                                  return rowData[headcell.value]
                              }
                          }
                      }
                  })
            : []

        return (
            <CardContent className="p-0">
                {this.renderAlert()}
                <Paper className="paper page-cover-height-200px">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={0} className="w-100 px-3">
                                <div className="psa-shipment-table custom-table-styles w-100">
                                    <TableViewManagementDialog
                                        viewCode={'ClientsGrid'}
                                        viewName={'Clients'}
                                        fontColor={textColor}
                                        backgroundColor={backgroundColor}
                                        successCallback={this.getHeaderColumns}
                                    />
                                    <TableViewCreateDialog
                                        fontColor={textColor}
                                        backgroundColor={backgroundColor}
                                    />
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mb-4 w-100"
                                    >
                                        <Grid item xs={12}>
                                            <div class="first-row-filter-hidden">
                                                <ThemeProvider
                                                    theme={tableTheme}
                                                >
                                                    <MaterialTable
                                                        isLoading={
                                                            this.state.isLoading
                                                        }
                                                        title="Clients"
                                                        columns={
                                                            tableColumnHeaders
                                                        }
                                                        data={clients}
                                                        icons={{
                                                            SortArrow:
                                                                forwardRef(
                                                                    (
                                                                        props,
                                                                        ref
                                                                    ) => (
                                                                        <ArrowDownward
                                                                            {...props}
                                                                            ref={
                                                                                ref
                                                                            }
                                                                        />
                                                                    )
                                                                )
                                                        }}
                                                        actions={tableActions}
                                                        options={{
                                                            headerStyle: {
                                                                backgroundColor:
                                                                    backgroundColor,
                                                                color: textColor
                                                            },
                                                            filtering: true,
                                                            maxBodyHeight:
                                                                'calc(100vh - 100px)',
                                                            pageSizeOptions: [
                                                                25, 50, 75
                                                            ],
                                                            filterRowStyle: {
                                                                position:
                                                                    'sticky',
                                                                top: 49,
                                                                background:
                                                                    'white',
                                                                zIndex: 5 /* optionally */
                                                            },
                                                            sorting: true,
                                                            pageSize: 25,
                                                            showTitle: false,
                                                            search: true,
                                                            searchFieldAlignment:
                                                                'right',
                                                            columnsButton: false,
                                                            doubleHorizontalScroll: true,
                                                            draggable: false,
                                                            showFirstLastPageButtons: true,
                                                            toolbar: true,
                                                            padding: 'dense',
                                                            selection: true,
                                                            pagination: {
                                                                rowsPerPage:
                                                                    'Rows per page:',
                                                                displayRows:
                                                                    'off'
                                                            },
                                                            exportButton: {
                                                                csv: true
                                                            },
                                                            exportFileName:
                                                                'ExportClients_' +
                                                                Date.now(),
                                                            exportAllData: true,
                                                            tableLayout: 'auto'
                                                        }}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <DialogConfirm
                                callback={this.handleActionsDialog.bind(this)}
                                show={this.state.dialogOpen}
                                title="Delete Client"
                                message="Are you sure you want to delete this client and all of
                                                its users?"
                            >
                                <br />
                                <span className="font-weight-bold">
                                    {this.state.client.customerId}
                                </span>
                            </DialogConfirm>
                            {this.renderAddUsersToClientDialog()}
                        </Grid>
                    </Grid>
                </Paper>
            </CardContent>
        )
    }

    setUsersCheckedValue(isChecked) {
        this.setState(prevState => {
            let users = [...prevState.users]
            users.forEach(users => {
                users.checked = isChecked
            })
            return { users }
        })
    }

    setAllUsersUnchecked = () => {
        this.setUsersCheckedValue(false)
    }

    setAllUsersChecked = () => {
        this.setUsersCheckedValue(true)
    }

    async saveClientUserDetails() {
        const { selectedClients, users } = this.state
        const selectedUsers = users.filter(user => user.checked)

        if (
            !window.confirm(
                'Are you sure you want to add ' +
                    selectedUsers.length +
                    ' user(s) to ' +
                    selectedClients.length +
                    ' client(s)?'
            )
        ) {
            return
        }

        this.setState({ addingUsers: true })

        const token = await authService.getAccessToken()
        const data = []

        selectedClients.forEach(client => {
            selectedUsers.forEach(user => {
                data.push({
                    clientId: client.id,
                    userId: user.id
                })
            })
        })

        fetch('api/users/AddClientUsers', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, *',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    this.setAllUsersUnchecked()
                    this.setState({
                        isAddUsersSuccess: true,
                        addUsersResult:
                            'User(s) Added To Client(s) Successfully.',
                        addingUsers: false
                    })
                    this.populateClientsData()
                } else {
                    this.setState({
                        isAddUsersError: true,
                        addUsersResult:
                            'An Error Occurred Adding User(s) To Client(s) - Please Contact Support.',
                        addingUsers: false
                    })
                    console.log(response.text())
                }
            })
            .catch(error => {
                this.setState({
                    isAddUsersError: true,
                    addUsersResult:
                        'An Error Occurred Adding User(s) To Client(s) - Please Contact Support.',
                    addingUsers: false
                })
                console.log(error)
            })
    }

    renderAddUsersToClientDialog() {
        const {
            users,
            filteredUsers,
            selectedClients,
            loadingClientUsers,
            isAddUsersSuccess,
            isAddUsersError,
            addUsersResult,
            addingUsers
        } = this.state
        return (
            <Dialog
                open={this.state.addUsersDialogOpen}
                onClose={() => this.setState({ addUsersDialogOpen: false })}
            >
                <DialogTitle>Select User(s)</DialogTitle>
                {(loadingClientUsers || addingUsers) && <LinearProgress />}
                {!loadingClientUsers && !addingUsers && (
                    <DialogContent>
                        <DialogContentText>
                            {
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddCircleIcon />}
                                        onClick={this.setAllUsersChecked}
                                    >
                                        Select All
                                    </Button>
                                    &nbsp;
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<RemoveCircleIcon />}
                                        onClick={this.setAllUsersUnchecked}
                                    >
                                        Unselect All
                                    </Button>
                                </>
                            }
                        </DialogContentText>
                        <div>
                            <br />
                            <TextField
                                style={{ width: '80%' }}
                                onChange={e => {
                                    // need to filter the addresses based on the input
                                    const value = e.target.value
                                    const filteredUsers = users.filter(user => {
                                        return (
                                            user.email
                                                .toLowerCase()
                                                .includes(
                                                    value.toLowerCase()
                                                ) ||
                                            user.firstName
                                                .toLowerCase()
                                                .includes(
                                                    value.toLowerCase()
                                                ) ||
                                            user.lastName
                                                .toLowerCase()
                                                .includes(value.toLowerCase())
                                        )
                                    })
                                    this.setState({ filteredUsers })
                                }}
                                placeholder={'Enter user name or email..'}
                            />
                            <br />
                            {filteredUsers.map(user => (
                                <div
                                    style={{
                                        backgroundColor: user.disabled
                                            ? '#D4EDF9'
                                            : null
                                    }}
                                >
                                    <Checkbox
                                        key={user.id}
                                        id={user.id}
                                        value={user.id}
                                        checked={user.checked}
                                        disabled={user.disabled}
                                        onChange={this.handleUserSelectChange}
                                    />{' '}
                                    <label>
                                        {this.getUserEmailNameString(user)}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        color="primary"
                        disabled={loadingClientUsers || addingUsers}
                        startIcon={<CancelIcon />}
                        onClick={() => {
                            this.setState({ addUsersDialogOpen: false })
                            this.setAllUsersUnchecked()
                        }}
                    >
                        Close
                    </Button>
                    &nbsp;
                    <Button
                        color="primary"
                        disabled={loadingClientUsers || addingUsers}
                        startIcon={<AddCircleIcon />}
                        onClick={async () => {
                            await this.saveClientUserDetails()
                        }}
                    >
                        Add{' '}
                        {users.filter(user => user.checked).length > 1
                            ? 'Users'
                            : 'User'}{' '}
                        to{' '}
                        {selectedClients.length > 1
                            ? 'Selected Clients'
                            : 'Selected Client'}
                    </Button>
                </DialogActions>
                {isAddUsersSuccess && (
                    <Alert severity="success" variant="filled">
                        {addUsersResult}
                    </Alert>
                )}
                {isAddUsersError && (
                    <Alert
                        severity="error"
                        variant="filled"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    this.setState({ isAddUsersError: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {addUsersResult}
                    </Alert>
                )}
            </Dialog>
        )
    }

    async populateClientsData() {
        const token = await authService.getAccessToken()
        const response = await fetch('api/clients', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (response.ok) {
            const data = await response.json()
            // need to update some boolean fields to be displayed as YES/NO
            data.forEach(client => {
                client.isConsignee = client.isConsignee === true ? 'YES' : ''
                client.isConsignor = client.isConsignor === true ? 'YES' : ''
            })
            this.setState({ clients: data })
        }
        this.setState({ isLoading: false })
    }

    async populateOrgUsersData() {
        const token = await authService.getAccessToken()
        const clientUsersResponse = await fetch(
            'api/users/GetClientUsersForOrg',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (clientUsersResponse.ok) {
            const data = await clientUsersResponse.json()
            // need to add a checked property to each user
            data.forEach(user => {
                user.checked = false
                user.disabled = false
            })
            this.setState({
                users: data,
                filteredUsers: data
            })
        } else {
            // handle error
        }
    }

    async deleteClient(id) {
        this.setState({ isPosting: true })
        const token = await authService.getAccessToken()
        const options = {
            method: 'DELETE',
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
            'Content-Type': 'application/json'
        }

        fetch('api/clients?clientId=' + id, options)
            .then(res => {
                if (res.ok) {
                    this.setState({ showSuccessAlert: true })
                    this.setState({ isPosting: false })
                    this.populateClientsData()

                    return Promise.resolve('Client (' + id + ') deleted.')
                } else {
                    this.setState(prevState => {
                        let client = Object.assign({}, prevState.client)
                        client.notifyError = true
                        client.errMessage =
                            'Sorry, there was an error while deleting this client.'
                        return { client }
                    })
                    this.setState({ isPosting: false })
                    return Promise.reject(
                        'An error occurred (' + res.status + ').'
                    )
                }
            })
            .catch(err => {
                this.setState(prevState => {
                    let client = Object.assign({}, prevState.client)
                    client.notifyError = true
                    client.errMessage =
                        'Sorry, there was an error while deleting this client.'
                    return { client }
                })
                this.setState({ isPosting: false })
                console.log(err)
            })
    }
}

export default withStyles(styles)(Clients)
